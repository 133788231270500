import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataModalComponent } from '../components/data-modal/data-modal.component';
import { CustomDataModalComponent } from '../components/custom-data-modal/custom-data-modal.component';


@Injectable()
export class ComponentService {

  constructor(public dialog: MatDialog) { }
  dialogRef: any;
  data: any;
  isSubmited: boolean = false;

  openDialog(component, title?, rawData?, width?, height?) {
    return this.dialogRef = this.dialog.open(DataModalComponent, {
      width: width,
      height: height,
      data: { component: component, title: title, dataDetails: rawData || undefined },
      disableClose: true //can not close dialog on escape button
    });
  }

  openCustomeDialog(component, title, rawData?, width?, height?) {
    return this.dialogRef = this.dialog.open(CustomDataModalComponent, {
      width: width,
      height: height,
      data: { component: component, title: title, dataDetails: rawData || undefined },
      disableClose: true //can not close dialog on escape button
    });
  }

  closeDialog(cloaseData?) {
    this.dialogRef.close(cloaseData);
  }

  submited() {
    this.isSubmited = true;
  }


}
