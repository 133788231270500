import { SelectionModel } from '@angular/cdk/collections';
import { CurrencyPipe, DecimalPipe, PlatformLocation } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UnitCostWarnigModel } from '@procurant/sharedcomponent/shared-components.module';
import { constant, DialogBoxService, FEATURE_CODE, MODULE_CODE, PO_STATUS, PRICE_PER, SharedUtilService, StorageService, SUBSCRIBED_APPS, TranslateService, UNIT_CONVERSION, WEIGHT_UOM, ALERT_MSG_PARAM, ALERT_TYPE } from 'common-lib';
import { OneAuthService, OneNotifyService } from 'one-auth';
import { BehaviorSubject } from 'rxjs';
import { ComponentService } from '../../../shared-components/services/component.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss']
})
export class AddItemComponent implements OnInit {
  orderDetailsForm: FormGroup;
  modalData: any;

  organizationId: number = 0;
  errorMessageCount: number = 0;
  productCodeList = [];
  suggestioinProductCodeList = [];
  grandTotal: number = 0;
  totalQuantity: number = 0;
  totalLines: number = 0;
  totalColumnPrice: number = 0;
  totalColumnFinalPrice: number = 0;
  columnTotal: number = 0;
  columnTotalDisplay: any;
  successMessage: string;
  submitClicked: boolean = true;
  selection = new SelectionModel<any>(true, []);
  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  lineNumber: number;
  isFinalCost: boolean = false;
  isPriceZeroBR = false;
  isNewRequest = false;
  warehouseAssociation: boolean = false;
  additionalAttributes: "";
  POSTATUS = PO_STATUS;
  pallets: number = 0;
  modalTitle: any;

  displayColumns: string[] = ['Sr No', 'Product Code', 'Description', 'TieHigh', 'Quantity', 'Price', 'Total', 'Actions'];
  disableSubmit: boolean;
  public isProductCodeInputFocuse = -1;
  public isQtyInputFocuse = -1;
  public isUnitCostInputFocuse = -1;
  public isFinalCostInputFocuse = -1;
  constructor(public auth: OneAuthService, private _Activatedroute: ActivatedRoute, public notify: OneNotifyService, private fb: FormBuilder, private _router: Router, private sharedUtilService: SharedUtilService, private componentService: ComponentService, private orderService: OrderService,
    private currencyPipe: CurrencyPipe, private decimal: DecimalPipe, private sessionStorage: StorageService, private dialogBoxService: DialogBoxService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: any, private transalate: TranslateService, private platformLocation: PlatformLocation) {
    this.platformLocation.onPopState(() => this.componentService.closeDialog());
    this.createForm();
  }

  ngOnInit() {
    this.getAppModuleAccessForFinalCost().then(r => {
      const myFormValueChanges$ = this.orderDetailsForm.controls['poLines'].valueChanges;
      myFormValueChanges$.subscribe(poLines => this.updateGrandTotalPrice(poLines));
      this.loadOrderDetail();
    });

  }

  createForm() {
    return this.orderDetailsForm = this.fb.group({
      id: [''],
      poNumber: [''],
      buyerCompanyId: [],
      buyerCompanyName: [''],
      sellerCompanyId: [],
      sellerCompanyName: [''],
      supplierCode: [''],
      deliverToLocId: [],
      deliverToLocName: [''],
      shipFromLocId: [],
      shipFromLocName: [''],
      deliveryDate: [],
      shipDate: [],
      freightTerm: [''],
      totalQty: [],
      uom: ['Cases7A'],
      totalAmount: [0],
      totalLines: [],
      currency: ['USD'],
      action: [''],
      poAcknowledged: [false],
      status: [],
      pallets: [0],
      poLines: this.fb.array([])
    });
  }

  getAppModuleAccessForFinalCost(): Promise<any> {
    let p = new Promise((resolve, reject) => {
      let arrObj = [];
      arrObj.push(constant.getAppModuleAccessObj(SUBSCRIBED_APPS.BUYER, MODULE_CODE.ORDER_MANAGEMENT, FEATURE_CODE.FINAL_COST));
      arrObj.push(constant.getAppModuleAccessObj(SUBSCRIBED_APPS.BUYER, MODULE_CODE.ORDER_MANAGEMENT, FEATURE_CODE.VARIANCE));
      arrObj.push(constant.getAppModuleAccessObj(SUBSCRIBED_APPS.BUYER, MODULE_CODE.ORDER_MANAGEMENT, FEATURE_CODE.ISSUE_ORDER_WITH_NO_PRICE));
      arrObj.push(constant.getAppModuleAccessObj(SUBSCRIBED_APPS.BUYER, MODULE_CODE.ORDER_MANAGEMENT, "ADD_ADDTL_ATTR_TO_PROD_DESC"));
      let obj = {
        brRulesRequest: arrObj
      }
      this.orderService.getAppModuleAccess(obj).subscribe(resp => {
        let final = resp.find(t => t.featureCode == FEATURE_CODE.FINAL_COST);
        this.isFinalCost = final ? final.accessCode == 'GRANTED' : false;
        let addAtrr = resp.find(t => t.featureCode == "ADD_ADDTL_ATTR_TO_PROD_DESC");
        this.additionalAttributes = addAtrr && addAtrr.attributeDept[0] && addAtrr.attributeDept[0].attributeCode ? addAtrr.attributeDept[0].attributeCode : "";
        // let variance1 = resp.find(t => t.featureCode == FEATURE_CODE.VARIANCE);
        // this.appModuleAccess.variance1 = variance1 ? variance1.accessCode == 'GRANTED' : false;
        if (this.isFinalCost) {
          this.displayColumns = ['Sr No', 'Product Code', 'Description', 'TieHigh', 'Quantity', 'Price', 'Final Cost', 'Total', 'Actions'];
        }
        this.isPriceZeroBR = resp.length > 0 ? resp.filter(t => t.featureCode == FEATURE_CODE.ISSUE_ORDER_WITH_NO_PRICE).length > 0 ? true : false : false;
        resolve(true);
      }, err => {
        resolve(true);
      })
    });
    return p;
  }

  loadOrderDetail() {
    if (this.data.dataDetails) {
      this.isNewRequest = this.data.dataDetails[2];
      Object.keys(this.data.dataDetails[0].controls).forEach(name => {
        if (this.orderDetailsForm.controls[name]) {
          this.orderDetailsForm.controls[name].patchValue(this.data.dataDetails[0].controls[name].value, '');
        }
      });
      const control = <FormArray>this.orderDetailsForm.controls['poLines'];
      this.lineNumber = this.data.dataDetails[0].controls['poLines'].length;
      if (control.controls) {
        control.controls = [];
      }
      this.addPOLine();
    }
  }

  // Add Remove   POLine row into form
  private addPOLine(index?, event?) {
    const control = <FormArray>this.orderDetailsForm.controls['poLines'];
    if (!index && !event) {
      if ((index == control.controls.length - 1) || control.controls.length == 0) {
        control.push(this.getPoline());
        this.dataSource.next(control.controls);
      }
    }
    else if (event.relatedTarget != null) {
      if (event.relatedTarget.nodeName == "BUTTON") {
        if (event.relatedTarget.outerText == this.transalate.instant("BUTTON.ADD_ITEMS")) {
          this.submitOrder(this.orderDetailsForm.value);
        }
        else if (event.relatedTarget.outerText == this.transalate.instant("BUTTON.CANCEL")) {
          this.closeDialog();
        }

      } else if (event.relatedTarget.nodeName == "INPUT") {
        if ((index == control.controls.length - 1) || control.controls.length == 0) {
          control.push(this.getPoline());
          this.dataSource.next(control.controls);
        }
      }
    } else {
      if ((index == control.controls.length - 1) || control.controls.length == 0) {
        control.push(this.getPoline());
        this.dataSource.next(control.controls);
      }
    }
  }

  closeDialog() {
    this.componentService.closeDialog();
  }

  private removePOLine(i: number) {
    const removeControl = ((<FormArray>this.orderDetailsForm.controls["poLines"]).controls);
    const removeValue = ((<FormArray>this.orderDetailsForm.controls["poLines"]).value);
    if (removeControl.length == 1) {
    } else {
      const control = <FormArray>this.orderDetailsForm.controls['poLines'];
      this.updateGrandTotalPrice(removeValue);
      control.removeAt(i);
      this.dataSource.next(control.controls);
    }
    this.checkWarehouseAssociation();
  }

  private removeAllPoLines() {
    const control = <FormArray>this.orderDetailsForm.controls['poLines'];
    for (let index = control.length; index > 0; index--) {
      control.removeAt(index - 1);
      this.dataSource.next(control.controls);
    }
  }

  private getPoline() {
    const numberPatern = '^[0-9,]+$';
    const decimalPatern = '^[0-9]+(\.^|[^,][0-9]{1,4})?$';
    const specialcharPatern = '^[A-Za-z0-9\u4e00-\u9eff- ]+$';
    return this.fb.group({
      buyerCompanyId: [],
      lineNumber: [],
      sequence: [2],
      productId: [],
      oldProductId: [],
      sku: ['', [Validators.pattern(specialcharPatern)]],
      productDesc: [''],
      ti: [''],
      hi: [''],
      qty: [''],
      purchaseByUom: ['cases'],
      sellByUom: ['cases'],
      unitPrice: ['',],
      unitPriceAuto: [false],
      finalUnitCost: ['', Validators.compose([Validators.pattern(decimalPatern), Validators.max(9999.99)])],
      priceByUom: ['cases'],
      totalAmount: [{ value: '', disabled: false }],
      tFormated: [],
      currency: ['USD'],
      status: ['New'],
      landedCost: [123.5],
      IcByUom: ['case'],
      soNumber: [''],
      shipFromLocId: [],
      pickupDate: [],
      shipDate: [],
      comments: [''],
      netWeight: [{ value: '', disabled: false }],
      weightUom: [{ value: '', disabled: false }],
      isDuplicate: [false],
      warehouseAssociation: [false],
      priceWarning: [false],
      isSuggest: [false]
    });
  }

  private updateGrandTotalPrice(poLines: any) {
    const control = <FormArray>this.orderDetailsForm.controls['poLines'];
    // before recount total price need to be reset.
    this.totalQuantity = 0;
    this.grandTotal = 0;
    this.totalColumnPrice = 0;
    this.totalColumnFinalPrice = 0;
    this.columnTotal = 0;
    this.pallets = 0;
    this.totalLines = this.orderDetailsForm.controls["poLines"].value.length;
    for (let i in poLines) {
      //code added for netweight Calculation in grandtotal
      let weight = 1.0;
      if (poLines[i].priceByUom.toUpperCase() == PRICE_PER.WEIGHT.toUpperCase()) {
        if (poLines[i].weightUom.toUpperCase() == WEIGHT_UOM.KG.toUpperCase()) {
          weight = UNIT_CONVERSION.KG_TO_LB * poLines[i].netWeight;
        } else {
          weight = poLines[i].netWeight;
        }
      }

      let palletCount = 0;
      if (poLines[i].ti != null && poLines[i].hi != null &&
        poLines[i].ti != '' && poLines[i].hi != '' &&
        poLines[i].ti != 0 && poLines[i].hi != 0) {
        palletCount = poLines[i].ti * poLines[i].hi;
      }

      //control.at(+i).get('totalAmount').setValue(this.decimal.transform(poLines[i].unitPrice,'1.2-4'))
      this.totalQuantity += poLines[i].qty * 1;  //Quantity column wise...!
      let grandTotalPrice = (poLines[i].qty * poLines[i].unitPrice * weight);  //Grand total cal...!
      let total = (poLines[i].qty * poLines[i].unitPrice * weight);
      this.pallets += palletCount > 0 ? (poLines[i].qty / palletCount) : 0;
      // now format total price with angular currency pipe
      let lang: any = this.sharedUtilService.getCurrancy();
      //let grandTotalPriceFormatted = this.currencyPipe.transform(grandTotalPrice, lang, 'symbol-narrow', '1.2-4');
      let grandTotalPriceFormatted = this.currencyPipe.transform(grandTotalPrice, lang, 'symbol-narrow', '1.2-2');
      // update total sum field on po line and do not emit event myFormValueChanges$ in this case on poLine
      control.at(+i).get('tFormated').setValue(grandTotalPrice, { onlySelf: true, emitEvent: false });
      //  let total = this.decimal.transform(grandTotalPrice, '1.2-4')
      control.at(+i).get('totalAmount').setValue(total, { onlySelf: true, emitEvent: false });
      control.at(+i).get('shipFromLocId').setValue(this.orderDetailsForm.get('shipFromLocId').value, { onlySelf: true, emitEvent: false });
      control.at(+i).get('pickupDate').setValue(this.orderDetailsForm.get('shipDate').value, { onlySelf: true, emitEvent: false });
      let num = 1 + Number.parseInt(i);
      control.at(+i).get('lineNumber').setValue(num, { onlySelf: true, emitEvent: false });
      // update total price for all poLine
      this.grandTotal += grandTotalPrice; //Price column wise...!
      this.totalColumnPrice += poLines[i].unitPrice * 1;//Final Price column wise...!
      this.totalColumnFinalPrice += poLines[i].finalUnitCost * 1; //Total price column wise...!
      this.columnTotal += grandTotalPrice;
      this.checkSKU_QTY();
    }
    // this.columnTotalDisplay = this.currencyPipe.transform(this.columnTotal, this.sharedUtilService.getCurrancy(), 'symbol-narrow', '1.2-2');
    this.columnTotalDisplay = this.columnTotal;
  }

  checkSKU_QTY() {// Need to work when API will come
    const decimalPatern = '^[0-9]+(\.^|[^,][0-9]{1,4})?$';
    const numberPatern = '^[0-9,]+$';
    const poLines = this.orderDetailsForm.controls["poLines"].value;
    const control = <FormArray>this.orderDetailsForm.controls['poLines'];
    const specialcharPatern = '^[A-Za-z0-9\u4e00-\u9eff- ]+$';
    let j: number = 0;
    let invalid = false;
    for (let i in poLines) {
      // if (poLines[i].sku == '' || poLines[i].sku.trim() == '') {
      //   control.at(+i).get('sku').setErrors({ notValid: true })
      //   invalid = true;
      // }
      if (poLines.length == 1) {
        if (poLines[i].sku == '' || poLines[i].sku.trim() == '') {
          control.at(+i).get('sku').setValidators([Validators.required, Validators.pattern(specialcharPatern)]);
          invalid = true;
        } else {
          control.at(+i).get('sku').setValidators([Validators.pattern(specialcharPatern)]);
        }
      }
      if (poLines[i].sku != '' || poLines[i].sku.trim() != '') {
        // if (poLines[i].qty == '') {
        //   control.at(+i).get('qty').setErrors({ notValid: true })
        //   invalid = true;
        // } else if (poLines[i].qty <= 0) {
        //   control.at(+i).get('qty').setErrors({ isZero: true })
        //   invalid = true;
        // }
        // control.at(+i).get('qty').setValidators(Validators.compose([Validators.pattern(numberPatern), Validators.required, Validators.min(1), Validators.max(9999)]))
        // control.at(+i).get('qty').updateValueAndValidity();
        // control.at(+i).get('unitPrice').setValidators(this.isPriceZeroBR == false ? Validators.compose([Validators.pattern(decimalPatern), Validators.required, Validators.min(0.0001), Validators.max(9999.99)]) : this.orderDetailsForm.get('poAcknowledged').value == true && this.sharedUtilService.getStatus(this.orderDetailsForm.get('status').value) >= this.POSTATUS.Shipped.toString() ? Validators.compose([Validators.pattern(decimalPatern), Validators.required, Validators.min(0.0001), Validators.max(9999.99)]) : Validators.compose([Validators.pattern(decimalPatern), Validators.max(9999.99)]))
        // control.at(+i).get('unitPrice').updateValueAndValidity();
      }

      // if (poLines[i].unitPrice == '') {
      //   control.at(+i).get('unitPrice').setErrors({ notValid: true })
      //   invalid = true;
      // } else if (poLines[i].unitPrice <= 0) {
      //   control.at(+i).get('unitPrice').setErrors({ isZero: true })
      //   invalid = true;
      // }
      j++;
    }
    return invalid;
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  getProductsDetailsSelection(idx, code) {
    this.getProductsDetails(idx, code)
  }

  getProductsDetailsFocusout(event, idx, code) {
    if (event) {
      if (event.relatedTarget && event.relatedTarget.nodeName == 'INPUT') {
        this.getProductsDetails(idx, code)
      }
    }
    this.focusOutEvent();
  }

  getProductsDetails(idx, code) {
    const numberPatern = '^[0-9,]+$';
    const decimalPatern = '^[0-9]+(\.^|[^,][0-9]{1,4})?$';
    let noDataCheck = <FormArray>this.orderDetailsForm.controls['poLines'];
    if (noDataCheck.at(idx).get('isSuggest').value) {

    } else {
      if (this.suggestioinProductCodeList && this.suggestioinProductCodeList.length > 0) {
        let data = this.suggestioinProductCodeList.filter(item => (item.code == code));
        if (data && data.length > 0) {
          this.orderService.getProductDetailsService(code).subscribe(
            resp => {
              noDataCheck.at(idx).get('unitPrice').updateValueAndValidity();
              if (resp != null) {
                this.extractData(resp, idx, code).then(r => {
                  if (r) {
                    noDataCheck.at(idx).get('sku').setErrors(null);
                    //noDataCheck.at(idx).get('unitPrice').setValidators()
                    noDataCheck.at(+idx).get('qty').setValidators(Validators.compose([Validators.pattern(numberPatern), Validators.required, Validators.min(1), Validators.max(99999)]))
                    noDataCheck.at(+idx).get('qty').updateValueAndValidity();
                    noDataCheck.at(+idx).get('unitPrice').setValidators(this.isPriceZeroBR == false ?
                      Validators.compose([Validators.pattern(decimalPatern), Validators.required, Validators.min(0.0001), Validators.max(9999.99)]) :
                      this.orderDetailsForm.get('poAcknowledged').value == true && this.sharedUtilService.getStatus(this.orderDetailsForm.get('status').value) >= this.POSTATUS.Shipped.toString() ?
                        Validators.compose([Validators.pattern(decimalPatern), Validators.required, Validators.min(0.0001), Validators.max(9999.99)]) :
                        Validators.compose([Validators.pattern(decimalPatern), Validators.max(9999.99)]))
                    noDataCheck.at(+idx).get('unitPrice').updateValueAndValidity();
                    this.getOrderGuideProductPrice(idx, resp.id);
                  }
                });
                noDataCheck.at(idx).get('isSuggest').setValue(true);
                //noDataCheck.at(idx).get('productDesc').setValue("");
                //noDataCheck.at(idx).get('sku').setValue("");
              } else {
                noDataCheck.at(idx).get('productDesc').setValue("");
                noDataCheck.at(idx).get('ti').setValue("");
                noDataCheck.at(idx).get('hi').setValue("");
                //noDataCheck.at(idx).get('sku').setValue("");
                noDataCheck.at(idx).get('sku').setErrors({ 'invalidProductNumber': true });
                noDataCheck.at(idx).get('warehouseAssociation').setValue(false);
                this.checkWarehouseAssociation();
              }
              let temp = noDataCheck.value.filter(item => (item.productDesc != null && item.productDesc != ''));
              if (temp && temp.length > 0) {
                this.disableSubmit = false;
              } else {
                this.disableSubmit = true;
              }

            }, err => {
              //this.notify.show(err, 'error')
            });
        }
        else if (code) {
          noDataCheck.at(idx).get('productDesc').setValue("");
          noDataCheck.at(idx).get('ti').setValue("");
          noDataCheck.at(idx).get('hi').setValue("");
          //noDataCheck.at(idx).get('sku').setValue("");
          noDataCheck.at(idx).get('sku').setErrors({ 'invalidProductNumber': true });
          noDataCheck.at(idx).get('warehouseAssociation').setValue(false);
          this.checkWarehouseAssociation();
        }
      } else if (code) {
        noDataCheck.at(idx).get('productDesc').setValue("");
        noDataCheck.at(idx).get('ti').setValue("");
        noDataCheck.at(idx).get('hi').setValue("");
        //noDataCheck.at(idx).get('sku').setValue("");
        noDataCheck.at(idx).get('sku').setErrors({ 'invalidProductNumber': true });
        noDataCheck.at(idx).get('warehouseAssociation').setValue(false);
        this.checkWarehouseAssociation();
      }
    }


  }

  getOrderGuideProductPrice(idx, productId) {
    this.orderService.getOrderGuideProductPrice(this.orderDetailsForm.get('sellerCompanyId').value, productId).subscribe(resp => {
      let lineItem = <FormArray>this.orderDetailsForm.controls['poLines'];
      if (resp.minPrice != null) {
        if (lineItem.at(idx).get('oldProductId').value != lineItem.at(idx).get('productId').value) {
          lineItem.at(idx).get('unitPrice').setValue(resp.minPrice);
          lineItem.at(idx).get('unitPriceAuto').setValue(true);
        }
      } else {
        if (lineItem.at(idx).get('unitPriceAuto').value)
          lineItem.at(idx).get('unitPrice').setValue('');
      }
    })
  }

  markManualEdit(row) {
    row.get('priceWarning').setValue(row.get('unitPrice').value >= UnitCostWarnigModel.value ? true : false);
    row.get('unitPriceAuto').setValue(false);
  }

  extractData(resp, idx, code): Promise<any> {
    let p = new Promise((resolve, reject) => {
      let lineItem = <FormArray>this.orderDetailsForm.controls['poLines'];
      if (resp) {
        let orderPoLines = <FormArray>this.data.dataDetails[0].controls['poLines']
        let isExistingSamelist = lineItem.controls.filter(t => t.get('sku').value.toUpperCase().trim() == lineItem.at(idx).get('sku').value.toUpperCase().trim()).length
        let isExistinginPO = orderPoLines.controls.filter(t => t.get('sku').value.toUpperCase().trim() == lineItem.at(idx).get('sku').value.toUpperCase().trim() && t.get('status').value != 'Cancelled').length

        if (isExistingSamelist > 1) {
          let noDataCheck = <FormArray>this.orderDetailsForm.controls['poLines'];
          noDataCheck.at(idx).get('productDesc').setValue("");
          noDataCheck.at(idx).get('ti').setValue("");
          noDataCheck.at(idx).get('hi').setValue("");
          noDataCheck.at(idx).get('sku').setErrors({ 'isDuplicate': true });
          lineItem.at(idx).get('warehouseAssociation').setValue(false);
          this.checkWarehouseAssociation();

          //noDataCheck.at(idx).get('isDuplicate').updateValueAndValidity();
          this.suggestioinProductCodeList = [];
          resolve(false)
        }
        else if (isExistinginPO > 0) {
          let noDataCheck = <FormArray>this.orderDetailsForm.controls['poLines'];
          noDataCheck.at(idx).get('productDesc').setValue("");
          noDataCheck.at(idx).get('ti').setValue("");
          noDataCheck.at(idx).get('hi').setValue("");
          noDataCheck.at(idx).get('sku').setErrors({ 'isDuplicate': true });
          lineItem.at(idx).get('warehouseAssociation').setValue(false);
          this.checkWarehouseAssociation();
          //noDataCheck.at(idx).get('isDuplicate').updateValueAndValidity();
          this.suggestioinProductCodeList = [];
          resolve(false)
        }
        else if (lineItem.at(idx).get('sku').value.toUpperCase().trim() === resp.code.toUpperCase().trim()) {
          if (this.additionalAttributes != "") {
            let pd = this.getProdDescWithAdditionalAttributes(resp.code);
            lineItem.at(idx).get('productDesc').setValue(resp.description + pd);
          } else {
            lineItem.at(idx).get('productDesc').setValue(resp.description);
          }
          lineItem.at(idx).get('ti').setValue(resp.ti);
          lineItem.at(idx).get('hi').setValue(resp.hi);
          lineItem.at(idx).get('oldProductId').setValue(lineItem.at(idx).get('productId').value)
          lineItem.at(idx).get('productId').setValue(resp.id);
          lineItem.at(idx).get('warehouseAssociation').setValue(false);
          this.checkWarehouseAssociation();
          lineItem.at(idx).get('sku').setErrors({ 'isDuplicate': false });

          //code added for netweight Calculation in grandtotal
          lineItem.at(idx).get('priceByUom').setValue(resp.pricePer);
          lineItem.at(idx).get('netWeight').setValue(resp.netWeight);
          lineItem.at(idx).get('weightUom').setValue(resp.weightUom);
          resolve(true)

        } else {
          let noDataCheck = <FormArray>this.orderDetailsForm.controls['poLines'];
          noDataCheck.at(idx).get('productDesc').setValue("");
          noDataCheck.at(idx).get('ti').setValue("");
          noDataCheck.at(idx).get('hi').setValue("");
          noDataCheck.at(idx).get('sku').setErrors({ 'invalidProductNumber': true });
          lineItem.at(idx).get('warehouseAssociation').setValue(false);
          this.checkWarehouseAssociation();
          resolve(false)

        }
      }
      else {
        //lineItem.at(idx).get('sku').setErrors({ pattern: true })
        lineItem.at(idx).get('productDesc').setValue("");
        lineItem.at(idx).get('ti').setValue("");
        lineItem.at(idx).get('hi').setValue("");
        lineItem.at(idx).get('sku').setErrors({ 'invalidProductNumber': true });
        lineItem.at(idx).get('warehouseAssociation').setValue(false);
        this.checkWarehouseAssociation();
        resolve(false)

      }
    })
    return p;
  }

  suggestionProducCode(index, searchText) {
    let noDataCheck = <FormArray>this.orderDetailsForm.controls['poLines'];
    noDataCheck.at(index).get('isSuggest').setValue(false);
    if (searchText != null && searchText.length > 2) {
      this.errorMessageCount = 3;
      this.orderService.searchProductCode(searchText).subscribe(
        (res) => {
          let noDataCheck = <FormArray>this.orderDetailsForm.controls['poLines'];
          if (res != "") {
            this.suggestioinProductCodeList = res;
            if (this.additionalAttributes != "") {
              this.updateListWithProdDisplayDesc();
            }
            noDataCheck.at(index).get('warehouseAssociation').setValue(false);
            noDataCheck.at(index).updateValueAndValidity();
            this.checkWarehouseAssociation();
          } else {
            this.suggestioinProductCodeList = [];

            noDataCheck.at(index).get('productDesc').setValue("");
            noDataCheck.at(index).get('sku').setErrors(res.length > 0 ? null : { 'invalidProductNumber': true });
            noDataCheck.at(index).get('warehouseAssociation').setValue(false);
            this.checkWarehouseAssociation();
          }
        }, err => {
          //this.notify.show(err, 'error')
        })
    } else {
      let lineItem = <FormArray>this.orderDetailsForm.controls['poLines'];
      lineItem.at(index).get('productDesc').setValue("");
      lineItem.at(index).get('ti').setValue("");
      lineItem.at(index).get('hi').setValue("");
      lineItem.at(index).get('productId').reset();
      this.suggestioinProductCodeList = [];
    }

    let temp = noDataCheck.value.filter(item => (item.productDesc != null && item.productDesc != ''));
    if (temp && temp.length > 0) {
      this.disableSubmit = false;
    } else {
      this.disableSubmit = true;
    }
  }

  updateListWithProdDisplayDesc() {
    if (this.suggestioinProductCodeList) {
      this.suggestioinProductCodeList.forEach(i => {
        i.additionalAttributes = "";
        if (i.packDesc && this.additionalAttributes.includes("PACKSIZE"))
          i.additionalAttributes = i.additionalAttributes + " " + i.packDesc;
        if (i.packCount && this.additionalAttributes.includes("PACKCOUNT"))
          i.additionalAttributes = i.additionalAttributes + " " + i.packCount;
        if (i.packType && this.additionalAttributes.includes("PACKTYPE"))
          i.additionalAttributes = i.additionalAttributes + " " + i.packType;
      });
    }
  }

  getProdDescWithAdditionalAttributes(code): String {
    let selectedProduct = this.suggestioinProductCodeList.find(i => i.code == code);
    return selectedProduct.additionalAttributes;
  }

  unitCostWarning(): Promise<boolean> {
    let p = new Promise<boolean>((resolve, reject) => {
      const control = this.orderDetailsForm.controls["poLines"] as FormArray;
      let len = control.controls.filter(t => t.get('priceWarning').value == true).length;
      if (len > 0) {
        let dialogContent: ALERT_MSG_PARAM = {
          message: this.transalate.instant("DETAIL_PAGE.MESSAGE.PO_COST_WARNING"),
          btnOKText: this.transalate.instant("BUTTON.CONTINUE"),
          btnCancelText: this.transalate.instant("BUTTON.CANCEL"),
          hideCancelBtn: false,
          additionalMessage: this.transalate.instant("MESSAGE.WANT_TO_CONTINUE"),
          width: 550,
          height: 'auto',
          icon: ALERT_TYPE.WARNING
        };
        this.dialogBoxService.ProcurantConfirmDialog(dialogContent).then(res => {
          resolve(true);
        }).catch(e => {
          resolve(false)
        });
      } else {
        resolve(true);
      }
    });
    return p;
  }

  submitOrder(order?) { // POST JSON
    this.unitCostWarning().then(r => {
      if (r) {
        this.submitClicked = false;
        const poLines = this.orderDetailsForm.controls["poLines"].value;
        let j: number = 0;
        if (poLines.length > 1) {
          for (let line in poLines) {
            if (poLines[+line].sku == '' && poLines[+line].qty == '') {
              this.removePOLine(j);
            } else if (poLines[+line].sku == '' && poLines[+line].qty != '') {
              this.removePOLine(j);
            }
            j++;
          }
        }

        if (poLines.length >= 1) {
          for (let line in poLines) {
            this.lineNumber++;
            poLines[+line].shipDate = this.orderDetailsForm.get('shipDate').value;
            poLines[+line].buyerCompanyId = this.orderDetailsForm.get('buyerCompanyId').value;;
            poLines[+line].comments = [];
            poLines[+line].lineNumber = this.lineNumber;

            j++;
          }
        }

        if (!this.orderDetailsForm.invalid) {
          let totalLines = this.orderDetailsForm.controls["poLines"].value.length;

          if (!this.checkSKU_QTY()) {
            this.orderDetailsForm.get('totalAmount').setValue(this.grandTotal);
            this.orderDetailsForm.get('totalQty').setValue(this.totalQuantity);
            this.orderDetailsForm.get('totalLines').setValue(totalLines);
            this.orderDetailsForm.get('pallets').setValue(this.pallets);
            this.orderDetailsForm.value.comments = [];
            //if (this.submitClicked) {
            //this.submitClicked = false;
            if (this.isNewRequest) {
              this.createRequest().then(r => {
                if (r) {
                  this.callAPI();
                } else
                  this.submitClicked = true;
              })
            } else {
              this.callAPI();
            }

          }
        }

      }
    });
  }

  callAPI() {
    let addedLinesCount = this.orderDetailsForm.get('poLines').value.filter(line => line.sku != "").length; //(NM)PMB-6395 check polines count with empty productcode
    this.disableSubmit = true;
    this.orderService.postAddLine(this.isNewRequest ? this.requestData : this.orderDetailsForm.value, this.isNewRequest).subscribe(res => {
      this.componentService.isSubmited = true;
      this.submitClicked = true;
      this.disableSubmit = false;
      this.componentService.data = constant.replaceErrorMsgValue(this.transalate.instant("DETAIL_PAGE.MESSAGE.ADDLINE_SUCCESS"), addedLinesCount);
      this.closeDialog();
    }, err => {
      this.disableSubmit = false;
      //this.notify.show(this.sharedUtilService.handleError(err), 'error');
      if (err && err.error && err.error.errorCode == "WAREHOUSE_ASSOCIATION") {
        let orderNumer = JSON.parse(err.error.message);
        let lineItem = <FormArray>this.orderDetailsForm.controls['poLines'];
        orderNumer.catalogItem.forEach(catlogId => {
          let productIdIndex = lineItem.value.findIndex(x => x.productId == catlogId);
          if (productIdIndex != -1) {
            lineItem.at(productIdIndex).get('warehouseAssociation').setValue(true);
            lineItem.updateValueAndValidity();
            this.checkWarehouseAssociation();
          }
        });
        // warehouseAssociation
      } else {
        if (err.error) {
          if (err.error.errorCode = "DUPLICATE_PRODUCT_NOT_ALLOWED") {
            this.notify.show(err, 'error');
          } else {
            this.notify.show(err, 'error');
          }
        } else
          this.notify.show(this.sharedUtilService.handleError(err), 'error');
      }
      this.submitClicked = true;
    });
  }

  checkWarehouseAssociation() {
    let lineItem = <FormArray>this.orderDetailsForm.controls['poLines'];
    let sendChanges: string[] = []
    for (let data of lineItem.controls) {
      if (data.get('warehouseAssociation').value) {
        sendChanges.push('true')
      }
    }
    this.warehouseAssociation = sendChanges.includes('true') ? true : false;
  }

  requestData: any;
  createRequest(): Promise<any> {
    this.requestData = undefined;
    let poLines: any = [];
    const control = <FormArray>this.orderDetailsForm.controls["poLines"];
    let lineFieldsArr = ['qty', 'productId', 'unitPrice', 'finalUnitCost']
    let p = new Promise((resolve, reject) => {
      this.requestData = {
        "id": this.orderDetailsForm.get('id').value,
        "deliverToLocId": this.orderDetailsForm.get('deliverToLocId').value
      }
      let linereq: any
      for (let index = 0; index < control.length; index++) {
        linereq = {
          "poId": this.orderDetailsForm.get('id').value
        }
        lineFieldsArr.forEach(name => {
          const element = control.controls[index];
          linereq[name] = element.get(name).value
        });
        poLines.push(linereq);
      }
      if (poLines.length > 0)
        this.requestData['poLines'] = poLines
      resolve(true);
    })
    return p;
  }

  abc: boolean = false;
  lineRowLength: number = 1;

  moveHeaderCell(event, id, index) {

    this.lineRowLength = this.orderDetailsForm.controls["poLines"].value.length;
    if (event.key == 'F2') {
      this.abc = true;
    }
    if (event.key == 'Escape') {
      this.abc = false;
    }
    if (!this.abc) {
      if (event.key == "ArrowDown") {
        this.arrowDown(event, id, index);
      }
      if (event.key == "ArrowUp") {
        this.arrowUp(event, id, index);
      }
      if (event.key == "ArrowLeft") {
        this.arrowLeft(event, id, index);
      }
      if (event.key == "ArrowRight") {
        this.arrowRight(event, id, index);
      }
    }
  }


  arrowDown(event, id, index) {
    if (id == 'qty_addItem' && this.lineRowLength > index) {
      index++;
      let nextId = 'qty_addItem' + index;
      if (document.getElementById(nextId))
        document.getElementById(nextId).focus();
    } else if (id == 'unitPrice_addItem' && this.lineRowLength > index) {
      index++;
      let nextId = 'unitPrice_addItem' + index;
      if (document.getElementById(nextId))
        document.getElementById(nextId).focus();
    } else if (id == 'finalUnitCost_addItem' && this.lineRowLength > index) {
      index++;
      let nextId = 'finalUnitCost_addItem' + index;
      if (document.getElementById(nextId))
        document.getElementById(nextId).focus();
    }
  }

  arrowUp(event, id, index) {
    if (id == 'qty_addItem' && index > 0) {
      index--;
      let nextId = 'qty_addItem' + index;
      document.getElementById(nextId).focus();
    }
    if (id == 'unitPrice_addItem' && index > 0) {
      index--;
      let nextId = 'unitPrice_addItem' + index;
      document.getElementById(nextId).focus();
    }
    if (id == 'finalUnitCost_addItem' && index > 0) {
      index--;
      let nextId = 'finalUnitCost_addItem' + index;
      document.getElementById(nextId).focus();
    }
  }

  arrowLeft(event, id, index) {
    if (id == 'unitPrice_addItem') {
      let nextId = 'qty_addItem' + (index);
      document.getElementById(nextId).focus();
    }
    if (id == 'finalUnitCost_addItem') {
      let nextId = 'unitPrice_addItem' + index;
      document.getElementById(nextId).focus();
    }
  }

  arrowRight(event, id, index) {
    if (id == 'qty_addItem') {
      let nextId = 'unitPrice_addItem' + index;
      document.getElementById(nextId).focus();
    }
    if (id == 'unitPrice_addItem') {
      if (this.isFinalCost) {
        let nextId = 'finalUnitCost_addItem' + (index);
        if (document.getElementById(nextId))
          document.getElementById(nextId).focus();
      }
    }
  }

  public focusInEvent(code, index) {
    const propertyMap = {
      isProductCodeInputFocuse: 'isProductCodeInputFocuse',
      isQtyInputFocuse: 'isQtyInputFocuse',
      isUnitCostInputFocuse: 'isUnitCostInputFocuse',
      isFinalCostInputFocuse: 'isFinalCostInputFocuse',
    };

    const property = propertyMap[code];
    if (property !== undefined) {
      this[property] = index;
    }
  }

  public focusOutEvent() {
    this.isProductCodeInputFocuse = this.isQtyInputFocuse = this.isUnitCostInputFocuse = this.isFinalCostInputFocuse = -1;
  }

  public addItem() {
    const control = <FormArray>this.orderDetailsForm.controls['poLines'];
    control.push(this.getPoline());
  }

}
