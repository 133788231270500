<div class="overlay-header">
    <div mat-dialog-title><span>{{title | translate}}</span></div>
    <div mat-dialog-title>
        <a (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </a>
    </div>
</div>
<div *ngIf="!addChargesFlag">
    <charges-overlay-list [data]="data"></charges-overlay-list>
</div>
<div *ngIf="addChargesFlag">
    <add-charges-overlay [data]="data"></add-charges-overlay>
</div>