<div class="filter-card">
    <div class="label">{{label}}</div>
    <div class="check-box-section" [ngClass]="{'check-box-section-hover': isHover}" (mouseenter)="onMouseEnter()"
        (mouseleave)="onMouseLeave()">
        <div class="aggrigation-data" [ngClass]="{'checked-filter': data.isChecked}" *ngFor="let data of filterData">
            <div>
                <mat-checkbox [disabled]="data.count == 0" [checked]="data.isChecked"
                    (change)="selectedFilter(data, label)">
                    <div>
                        <span class="data-section">{{ data.name }}</span>
                    </div>
                </mat-checkbox>
            </div>
            <div class="aggrigation-data-count">
                <span class="count">{{ data.count }}</span>
            </div>
        </div>
    </div>
    <div class="view-more-section" *ngIf="filterList.length > 5">
        <span class="icon-part" (click)="toggleViewMore()">
            <i class="material-icons">{{ showAll ? 'keyboard_double_arrow_up' : 'keyboard_double_arrow_down' }}</i>
        </span>
    </div>
</div>