<custome-overlay-design>
  <div header><span>{{data.title | translate}}</span></div>
  <div close>
    <a (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <div class="custom-body" custom-body>
    <form [formGroup]="moveLineForm">
      <!-- <div fxLayout="row" fxFlexFill fxLayoutAlign="start top" class="mb-40">
        <div fxFlex="100" class="pageInfo withLightBg pd-20">
       
          <div fxLayout="row" fxLayoutAlign="start top" class="infoBlocks mb-10">
            <div class="readOnlyField rBorder">
              <div class="fieldGroup horizontalGroup">
                <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.ORDER_NO' | translate}}:</span>
                <span id="oldPoNumber_MoveLine" class="fieldValue">{{moveLineForm.controls['oldPoNumber'].value}}</span>
              </div>
              <div class="fieldGroup horizontalGroup">
                <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.ROUTING' | translate}}:</span>
                <span id="routing_MoveLine" class="fieldValue">{{moveLineForm.controls['routing'].value}}</span>
              </div>
            </div>
            <div class="readOnlyField mt-10">
              <div class="fieldGroup horizontalGroup">
                <span id="totalLines_MoveLine"
                  class="fieldValue mr-2">{{moveLineForm.controls['totalLines'].value}}</span>
                <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.LINE_SELECTED' | translate}}</span>
              </div>

              <div class="fieldGroup horizontalGroup">
                <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.SUPPLIER' | translate}}:</span>
                <span id="sellerCompanyName_MoveLine"
                  class="fieldValue">{{moveLineForm.controls['sellerCompanyName'].value}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start top" class="infoBlocks mb-10 pdt-20">
        <div class="readOnlyField rBorder">
          <div class="fieldGroup">
            <mat-radio-button id="newPO_MoveLine" value="1" checked="true" (change)="radioChange($event.value)">
              {{'DETAIL_PAGE.LABEL.NEW_ORDER' | translate}}</mat-radio-button>
          </div>
          <div class="fieldGroup">
            <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.ORDER_NO' | translate}}* :</span>
            <ng-container *ngIf="moveLineForm.controls['rangeStatus'].value=='USER_DEFINED'">
              <mat-form-field class="smallInput mr-10 withoutLabel">
                <input id="newPoNumber_MoveLine" matInput class="mBold" maxlength="64" [readonly]="!isNewOrder"
                  formControlName="newPoNumber" (change)="checkOrderNumber($event.target.value)"
                  (blur)="moveLineForm.controls['newPoNumber'].setValue(moveLineForm.controls['newPoNumber'].value.trim())">

              </mat-form-field>
              <mat-error
                *ngIf="moveLineForm.controls['newPoNumber'].touched && (moveLineForm.controls['newPoNumber'].hasError('required') || moveLineForm.controls['newPoNumber'].hasError('notValid')) && !moveLineForm.controls['newPoNumber'].hasError('poExists')">
                {{ 'DETAIL_PAGE.MESSAGE.ORDER_NO_ERROR' | translate }}
              </mat-error>
              <mat-error *ngIf="moveLineForm.controls['newPoNumber'].hasError('poExists')">
                {{ 'DETAIL_PAGE.MESSAGE.ORDER_NO_EXISTS' | translate }}
              </mat-error>
            </ng-container>
            <ng-container *ngIf="moveLineForm.controls['rangeStatus'].value=='INPROCESS'">
              <span id="loading_MoveLine" class="loading">{{ 'MESSAGE.LOADING' | translate }}</span>
            </ng-container>
            <ng-container *ngIf="moveLineForm.controls['rangeStatus'].value=='SYSTEM_GENERATED'">
              <span id="rangePoNumber_MoveLine">{{moveLineForm.controls['newPoNumber'].value}}</span>
            </ng-container>
            <ng-container *ngIf="moveLineForm.controls['rangeStatus'].value=='RANGE_EXHAUSTED'">
              <span class="range-error">{{ 'MESSAGE.RESET_OREDER_RANGE' | translate }}</span>
            </ng-container>
            <ng-container *ngIf="moveLineForm.controls['rangeStatus'].value=='SYSTEM_ERROR'">
              <span class="range-error">{{ 'MESSAGE.NO_OREDER_NUMBER' | translate }}</span>
            </ng-container>
            <ng-container *ngIf="moveLineForm.controls['rangeStatus'].value=='NO_RANGE_SYSTEM_ERROR'">
              <span class="range-error">{{ 'MESSAGE.NO_ORDER_RANGE_EXISTS' | translate }}</span>
            </ng-container>
          </div>
          <div class="fieldGroup mt-40">
            <div class="dateRange horizontalGroup">
              <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.SHIP_DATE' | translate}} :</span>
              <date-picker class="From w-180" [disabledPicker]="!isNewOrder" [parentForm]="moveLineForm"
                [field]="'newshipDate'" (dateChanged)="fromDateChanged($event)" [minDate]="minDate" [maxDate]="maxDate">
              </date-picker>
              <mat-error
                *ngIf="moveLineForm.controls['newshipDate'].touched && moveLineForm.controls['newshipDate'].hasError('required')">
                {{ 'DETAIL_PAGE.MESSAGE.SHIP_DATE_ERROR' | translate }}
              </mat-error>
              <mat-error
                *ngIf="moveLineForm.controls['newshipDate'].hasError('notValid')  && !moveLineForm.controls['newshipDate'].hasError('required')">
                {{ 'DETAIL_PAGE.MESSAGE.DATE_GREATER_ERROR' | translate }}
              </mat-error>
            </div>
          </div>
          <ng-container *ngIf="supplierCodes.length>0">
            <div class="fieldGroup">
              <span class="fieldLabel">{{'ORDER.LABEL.SUPPLIER_CODE'|translate}} :</span>
              <mat-form-field class="formContainer mt--18">
                <mat-select id="supplierCode_MoveLine" formControlName="supplierCode" [disabled]="!isNewOrder">
                  <mat-option value="">{{ 'TABLE_DISPLAY_COLUMNS.SELECT' | translate }}</mat-option>
                  <mat-option *ngFor="let supplierCode of supplierCodes" [value]="supplierCode">
                    {{supplierCode}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
        <div class="readOnlyField">
          <div class="fieldGroup">
            &nbsp;
          </div>
          <div class="fieldGroup">
            <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.ROUTING' | translate}}* :</span>
            <mat-form-field class="formContainer mt--18">
              <mat-select id="existingfreightTerm_MoveLine" formControlName="existingfreightTerm"
                [disabled]="!isNewOrder" disableOptionCentering>
                <mat-option value="">{{ 'TABLE_DISPLAY_COLUMNS.SELECT' | translate }}</mat-option>
                <mat-option value="FOB Carrier">FOB Carrier</mat-option>
                <mat-option value="Backhaul">Backhaul</mat-option>
                <mat-option value="Delivered">Delivered</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error
              *ngIf="moveLineForm.controls['existingfreightTerm'].touched && moveLineForm.controls['existingfreightTerm'].hasError('required')">
              {{ 'DETAIL_PAGE.MESSAGE.FREIGHT_TERM_ERROR' | translate }}
            </mat-error>

          </div>
          <div class="fieldGroup mt-20">
            <div class="dateRange horizontalGroup">
              <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.DELIVERY_DATE'|translate}}* :</span>
              <date-picker class="To w-180" [disabledPicker]="!isNewOrder" [parentForm]="moveLineForm"
                [field]="'newdeliveryDate'" (dateChanged)="toDateChanged($event)" [minDate]="minDelDate"
                [maxDate]="maxDate"></date-picker>
              <mat-error
                *ngIf="moveLineForm.controls['newdeliveryDate'].touched && moveLineForm.controls['newdeliveryDate'].hasError('required')">
                {{ 'DETAIL_PAGE.MESSAGE.DELIVERY_DATE_ERROR' | translate }}
              </mat-error>
              <mat-error
                *ngIf="moveLineForm.controls['newdeliveryDate'].hasError('notValid') && !moveLineForm.controls['newdeliveryDate'].hasError('required')">
                {{ 'DETAIL_PAGE.MESSAGE.DATE_GREATER_ERROR' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start top" class="infoBlocks mb-10  pdt-20">
        <div class="readOnlyField">
          <div class="fieldGroup">
            <mat-radio-button id="existing_MoveLine" value="2" (change)="radioChange($event.value)">
              {{'DETAIL_PAGE.LABEL.EXISTING_ORDER'|translate}}</mat-radio-button>
          </div>
          <div class="fieldGroup">
            <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.ORDER_NO'|translate}}* :</span>
            <mat-form-field class="formContainer mt--18">
              <mat-select id="existingPoNumber_MoveLine" formControlName="existingPoNumber" [disabled]="isNewOrder"
                (selectionChange)="setExistingPOValue($event.value)">
                <mat-option value="">{{ 'TABLE_DISPLAY_COLUMNS.SELECT' | translate }}</mat-option>
                <mat-option *ngFor="let po of exitingPO"
                  matTooltip="{{po.orderNumber}}|Del. Date:{{po.deliveryDate|pipes:'date'}}|Total Qty:{{po.totalQty}}"
                  [value]="po.orderNumber">{{po.orderNumber}}|Del. Date:{{po.deliveryDate|pipes:'date'}}|Total
                  Qty:{{po.totalQty}}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="!isNoExistingOrderPresent && moveLineForm.controls['existingPoNumber'].touched && (moveLineForm.controls['existingPoNumber'].hasError('required') || moveLineForm.controls['existingPoNumber'].hasError('notValid'))">
                {{ 'DETAIL_PAGE.MESSAGE.ORDER_NO_ERROR' | translate }}
              </mat-error>
              <mat-error *ngIf="isNoExistingOrderPresent && moveLineForm.controls['existingPoNumber'].touched">
                {{ 'MESSAGE.NO_EXISTING_ORDERS_FOUND' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div> -->
      <!--New Code -->
      <div class="head-section">
        <div class="head">
          <div class="lable-section first-row">
            <div class="lable-container">
              <span class="title">{{'DETAIL_PAGE.LABEL.ORDER_NO' | translate}}:</span>
              <span id="oldPoNumber_MoveLine" class="value">{{moveLineForm.controls['oldPoNumber'].value}}</span>

            </div>
            <div class="lable-container">
              <span class="title">{{'DETAIL_PAGE.LABEL.SUPPLIER' | translate}}:</span>
              <span id="sellerCompanyName_MoveLine"
                class="value">{{moveLineForm.controls['sellerCompanyName'].value}}</span>
            </div>
            <div class="lable-container">
              <span class="title">{{'DETAIL_PAGE.LABEL.ROUTING' | translate}}:</span>
              <span id="routing_MoveLine" class="value">{{moveLineForm.controls['routing'].value}}</span>
            </div>
          </div>
          <div class="lines-section">
            <div class="title">{{ 'LABEL.SELECTED_ITEMS' | translate }}:</div>
            <div class="list" kendoTooltip filter=".dance" [titleTemplate]="titleTemplate" [tooltipTemplate]="template"
              position="top">
              <mat-card *ngFor="let line of this.data.dataDetails[1]; let lineIndex = index;" class="dance"
                [attr.data-name]="line.value.sku" [attr.data-description]="line.value.productDesc">{{line.value.sku}}
                <span *ngIf="this.data.dataDetails[1].length > 1" class="remove-line-icon"
                  (click)="removeSelectedLine(line.value.id)"></span>
              </mat-card>
              <!-- kendo templated -->
              <ng-template #titleTemplate let-anchor>
                {{ anchor.nativeElement.getAttribute('data-name') }}
              </ng-template>
              <ng-template #template let-anchor>
                {{ anchor.nativeElement.getAttribute('data-description') }}
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div class="body-section">
        <div class="order-section first-row">
          <div class="radio-btn">
            <mat-radio-button id="newPO_MoveLine" value="1" checked="true" (change)="radioChange($event.value)">
              {{'ORDER.LABEL.CREATE_NEW_ORDER' | translate}}</mat-radio-button>
          </div>
          <div class="input-section">
            <div class="row row-bottom">
              <div>
                <!-- *ngIf="moveLineForm.controls['rangeStatus'].value=='USER_DEFINED'" -->
                <ng-container>
                  <mat-form-field appearance="outline">
                    <mat-label>{{'DETAIL_PAGE.LABEL.ORDER_NO' | translate}}</mat-label>
                    <input class="text-font" matInput id="newPoNumber_MoveLine"
                      placeholder="{{'DETAIL_PAGE.LABEL.ORDER_NO' | translate}}" maxlength="64"
                      formControlName="newPoNumber"
                      [readonly]="!isNewOrder || moveLineForm.controls['rangeStatus'].value!='USER_DEFINED' "
                      (change)="checkOrderNumber($event.target.value)"
                      (blur)="moveLineForm.controls['newPoNumber'].setValue(moveLineForm.controls['newPoNumber'].value.trim())">
                    <!-- <ng-container
                      *ngIf="(moveLineForm.controls['rangeStatus'].value!='RANGE_EXHAUSTED' && moveLineForm.controls['rangeStatus'].value!='SYSTEM_ERROR' && 
                      moveLineForm.controls['rangeStatus'].value!='NO_RANGE_SYSTEM_ERROR')"> -->
                      <mat-error
                        *ngIf="(moveLineForm.controls['newPoNumber'].touched && (moveLineForm.controls['newPoNumber'].hasError('required') || moveLineForm.controls['newPoNumber'].hasError('notValid')) && !moveLineForm.controls['newPoNumber'].hasError('poExists')) &&
                        (moveLineForm.controls['rangeStatus'].value!='RANGE_EXHAUSTED' && moveLineForm.controls['rangeStatus'].value!='SYSTEM_ERROR' && 
                      moveLineForm.controls['rangeStatus'].value!='NO_RANGE_SYSTEM_ERROR')">
                        {{ 'DETAIL_PAGE.MESSAGE.ORDER_NO_ERROR' | translate }}
                      </mat-error>
                      <mat-error *ngIf="moveLineForm.controls['newPoNumber'].hasError('poExists') && (moveLineForm.controls['rangeStatus'].value!='RANGE_EXHAUSTED' && moveLineForm.controls['rangeStatus'].value!='SYSTEM_ERROR' && 
                      moveLineForm.controls['rangeStatus'].value!='NO_RANGE_SYSTEM_ERROR')">
                        {{ 'DETAIL_PAGE.MESSAGE.ORDER_NO_EXISTS' | translate }}
                      </mat-error>
                    <!-- </ng-container> -->
                    <mat-hint *ngIf="moveLineForm.controls['rangeStatus'].value=='RANGE_EXHAUSTED'">
                      <span class="range-error">{{ 'MESSAGE.RESET_OREDER_RANGE' | translate }}</span>
                    </mat-hint>
                    <mat-hint *ngIf="moveLineForm.controls['rangeStatus'].value=='SYSTEM_ERROR'">
                      <span class="range-error">{{ 'MESSAGE.NO_OREDER_NUMBER' | translate }}</span>
                    </mat-hint>
                    <mat-hint *ngIf="moveLineForm.controls['rangeStatus'].value=='NO_RANGE_SYSTEM_ERROR'">
                      <span class="range-error">{{ 'MESSAGE.NO_ORDER_RANGE_EXISTS' | translate }}</span>
                    </mat-hint>
                  </mat-form-field>
                </ng-container>
                <!-- <ng-container *ngIf="moveLineForm.controls['rangeStatus'].value=='INPROCESS'">
                  <span id="loading_MoveLine" class="loading">{{ 'MESSAGE.LOADING' | translate }}</span>
                </ng-container> -->
                <!-- <ng-container *ngIf="moveLineForm.controls['rangeStatus'].value=='SYSTEM_GENERATED'">
                  <span id="rangePoNumber_MoveLine">{{moveLineForm.controls['newPoNumber'].value}}</span>
                </ng-container> -->
              </div>
              <div>
                <!-- <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.ROUTING' | translate}}* :</span> -->
                <mat-form-field appearance="outline">
                  <mat-label>{{'LABEL.ROUTING' | translate}}</mat-label>
                  <mat-select class="text-font" id="existingfreightTerm_MoveLine" formControlName="existingfreightTerm"
                    [disabled]="!isNewOrder" disableOptionCentering>
                    <mat-option value="">{{ 'TABLE_DISPLAY_COLUMNS.SELECT' | translate }}</mat-option>
                    <mat-option value="FOB Carrier">FOB Carrier</mat-option>
                    <mat-option value="Backhaul">Backhaul</mat-option>
                    <mat-option value="Delivered">Delivered</mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="moveLineForm.controls['existingfreightTerm'].touched && moveLineForm.controls['existingfreightTerm'].hasError('required')">
                    {{ 'DETAIL_PAGE.MESSAGE.FREIGHT_TERM_ERROR' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <ng-container *ngIf="supplierCodes.length>0">
                <div class="">
                  <mat-form-field appearance="outline">
                    <mat-label>{{'DETAIL_PAGE.LABEL.SUPPLIER_CODE' | translate}}</mat-label>
                    <mat-select class="text-font" id="supplierCode_MoveLine" formControlName="supplierCode"
                      [disabled]="!isNewOrder" placeholder="{{'DETAIL_PAGE.LABEL.SUPPLIER_CODE' | translate}}"
                      disableOptionCentering>
                      <mat-option value="">{{ 'TABLE_DISPLAY_COLUMNS.SELECT' | translate }}</mat-option>
                      <mat-option *ngFor="let supplierCode of supplierCodes" [value]="supplierCode">
                        {{supplierCode}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </ng-container>
            </div>
            <div class="row">
              <div class="date-section">
                <!-- <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.SHIP_DATE' | translate}} :</span> -->
                <custom-date-picker class="text-font" [disabledPicker]="!isNewOrder" [parentForm]="moveLineForm"
                  [field]="'newshipDate'" (dateChanged)="fromDateChanged($event)" [minDate]="minDate"
                  [maxDate]="maxDate" placeholder="{{'DETAIL_PAGE.LABEL.SHIP_DATE'|translate}}">
                </custom-date-picker>

                <mat-error
                  *ngIf="moveLineForm.controls['newshipDate'].touched && moveLineForm.controls['newshipDate'].hasError('required')">
                  {{ 'DETAIL_PAGE.MESSAGE.SHIP_DATE_ERROR' | translate }}
                </mat-error>
                <mat-error
                  *ngIf="moveLineForm.controls['newshipDate'].hasError('notValid')  && !moveLineForm.controls['newshipDate'].hasError('required')">
                  {{ 'DETAIL_PAGE.MESSAGE.DATE_GREATER_ERROR' | translate }}
                </mat-error>
              </div>
              <div class="date-section">
                <custom-date-picker class="text-font" [disabledPicker]="!isNewOrder" [parentForm]="moveLineForm"
                  [field]="'newdeliveryDate'" (dateChanged)="toDateChanged($event)" [minDate]="minDelDate"
                  [maxDate]="maxDate"
                  placeholder="{{'DETAIL_PAGE.LABEL.DELIVERY_DATE'|translate}}"></custom-date-picker>
                <mat-error
                  *ngIf="moveLineForm.controls['newdeliveryDate'].touched && moveLineForm.controls['newdeliveryDate'].hasError('required')">
                  {{ 'DETAIL_PAGE.MESSAGE.DELIVERY_DATE_ERROR' | translate }}
                </mat-error>
                <mat-error
                  *ngIf="moveLineForm.controls['newdeliveryDate'].hasError('notValid') && !moveLineForm.controls['newdeliveryDate'].hasError('required')">
                  {{ 'DETAIL_PAGE.MESSAGE.DATE_GREATER_ERROR' | translate }}
                </mat-error>
              </div>
            </div>
          </div>
        </div>
        <div class="order-section">
          <div class="radio-btn">
            <mat-radio-button id="existing_MoveLine" value="2" (change)="radioChange($event.value)">
              {{'DETAIL_PAGE.LABEL.EXISTING_ORDER'|translate}}</mat-radio-button>
          </div>
          <div class="input-section">
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label>{{'DETAIL_PAGE.LABEL.ORDER_NO' | translate}}</mat-label>
                <mat-select class="text-font" id="existingPoNumber_MoveLine" formControlName="existingPoNumber"
                  [disabled]="isNewOrder" (selectionChange)="setExistingPOValue($event.value)"
                  placeholder="{{'DETAIL_PAGE.LABEL.ORDER_NO' | translate}}" disableOptionCentering>
                  <mat-option value="">{{ 'TABLE_DISPLAY_COLUMNS.SELECT' | translate }}</mat-option>
                  <mat-option *ngFor="let po of exitingPO" position="right" kendoTooltip
                    title="{{po.orderNumber}}|Del. Date:{{po.deliveryDate|pipes:'date'}}|Total Qty:{{po.totalQty}}"
                    [value]="po.orderNumber">{{po.orderNumber}}|Del. Date:{{po.deliveryDate|pipes:'date'}}|Total
                    Qty:{{po.totalQty}}</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="!isNoExistingOrderPresent && moveLineForm.controls['existingPoNumber'].touched && (moveLineForm.controls['existingPoNumber'].hasError('required') || moveLineForm.controls['existingPoNumber'].hasError('notValid'))">
                  {{ 'DETAIL_PAGE.MESSAGE.ORDER_NO_ERROR' | translate }}
                </mat-error>
                <mat-error *ngIf="isNoExistingOrderPresent && moveLineForm.controls['existingPoNumber'].touched">
                  {{ 'MESSAGE.NO_EXISTING_ORDERS_FOUND' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <ng-container rightFooter>
    <div class="button-section">
      <button id="close_MoveLine" class="procurant-link-btn" mat-button (click)="closeDialog()">{{ 'BUTTON.CANCEL' |
        translate }}</button>
      <button id="submit_MoveLine" mat-flat-button color="primary" class="procurant-submit-btn"
        [disabled]="moveLineForm.invalid || !moveLineForm.dirty || submitFlag" (click)="submitOrder()">{{'BUTTON.SUBMIT'
        |translate}}
      </button>
    </div>
  </ng-container>
</custome-overlay-design>