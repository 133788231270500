import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ProcurantLoadingComponent } from '../components/procurant-loading/procurant-loading.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();

  private overlayRef: OverlayRef = undefined;

  constructor(private overlay: Overlay) { }

  public show(): void {
    this.loaderSubject.next(<LoaderState>{ show: true })
    // Hack avoiding `ExpressionChangedAfterItHasBeenCheckedError` error
    Promise.resolve(null).then(() => {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.overlay
          .position()
          .global()
          .centerHorizontally()
          .centerVertically(),
        hasBackdrop: true,
      });
      this.overlayRef.attach(new ComponentPortal(ProcurantLoadingComponent));
    });
  }

  public hide(): void {
    this.loaderSubject.next(<LoaderState>{ show: false });
    this.overlayRef.detach();
    this.overlayRef = undefined;
  }
}

export interface LoaderState {
  show: boolean;
}
