/***********************************************************************
 
* DESCRIPTION :
 
* Allow retailers the ability to upload store shipment information into Procurant using a web UI
 
* NOTES :
 
* AUTHOR :    Payal Mandavkar       CREATE DATE :    28 Octomber 2023
 
* CHANGES : Added new component
 
* REF NO          VERSION   DATE         WHO     DETAIL
 
* PMB-7564       2.6.0     06/04/2023   PM     Allow retailers the ability to upload store shipment information into Procurant using a web UI

***********************************************************************/
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'custome-overlay-design',
  templateUrl: './custome-overlay-design.component.html',
  styleUrls: ['./custome-overlay-design.component.scss']
})
export class CustomeOverlayDesignComponent implements OnInit {
  @Input() showAction = true;
  @Input() showNote = false;
  constructor() { }

  ngOnInit(): void {
  }
}
