import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLS } from "@procurant/env/config.url";
import { constant, FEATURE_CODE, HttpService, MODULE_CODE, SharedUtilService, SUB_STATUS, SUBSCRIBED_APPS } from 'common-lib';
import { TranslateService } from 'common-lib';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OneAuthService, OneNotifyService } from 'one-auth';
import { PopupRef } from '@progress/kendo-angular-popup';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  SUBSTATUS = SUB_STATUS;
  public headers: HttpHeaders;
  productLines: any[] = [];
  myViewId: any;
  myViewTittle: any;
  public popupRef: PopupRef;

  constructor(private transalate: TranslateService, private $http: HttpService, private http: HttpClient, private sharedUtilService: SharedUtilService, public notify: OneNotifyService, public auth: OneAuthService) {
    this.headers = new HttpHeaders();
    // Add Content-Type be default
    this.headers.set('Content-Type', 'application/json');
  }

  // getSuppliers() {
  //   return this.$http.baseUrlContext.url("/admin/api/org/buyer/supplierList").get()
  //     .pipe(map(res => this.extractData(res)));
  // }

  getCountryList(): Observable<any> {
    return this.http.get(URLS.GetCOOList).pipe(
      map((res) => {
        return res;
      }));
  }

  getSuppliers(): Observable<any> {
    // return this.$http.baseUrlContext.url("/admin/api/trading-partners/my-trading-partners/BUYER/SUPPLIER").get()
    return this.http.get<any>(URLS.GetOnlineOfflineSupplierListAPI).pipe(
      map((res) => {
        return res;
      }
      ));
  }
  getOnlineOfflineSuppliersByDepartments(departments?: string[]): Observable<any> {
    const params = departments && departments.length > 0 ?
      new HttpParams().set('departments', departments.join(',')) : new HttpParams();
    return this.http.get<any>(URLS.GetOnlineOfflineSupplierListAPI, { params });
  }

  getOnlineOfflineSuppliers(departments?: string[]): Observable<any> {
    return this.http.get<any>(URLS.GetOnlineOfflineSupplierListAPI).pipe(
      map((res) => {
        return res;
      }
      ));
  }

  getSupplierCodes(): Observable<any> {
    // return this.$http.baseUrlContext.url("/admin/api/org/buyer/supplier-codes/3").get()
    return this.http.get<any>(URLS.GetSupplierCodesAPI).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getSupplierCodesOfSupplier(param, isOffline): Observable<any> {
    // return this.$http.baseUrlContext.url("/admin/api/org/buyer/supplier-codes/"+param).get()
    let url = URLS.GetSupplierCodesOfSupplier + param + "/" + isOffline;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }
  


  getShipFromLocations(supplierId: any): Observable<any> {
    // return this.$http.baseUrlContext.url("/admin/api/org/businessUnits/" + supplierId + "/SHIP_FROM").get()
    let url = URLS.ShipsFromBusinessUnitAPI + supplierId + "/SHIP_FROM";
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getDeliveryLocations(): Observable<any> {
    // return this.$http.baseUrlContext.url("/admin/api/org/businessUnits/DELIVERY").get()
    return this.http.get<any>(URLS.ShipsToBusinessUnitAPI).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getCrossDockLocations(): Observable<any> {
    return this.http.get<any>(URLS.crossDockBusinessUnitAPI).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getCrossDockUnits(): Observable<any> {
    // return this.$http.baseUrlContext.url("/admin/api/org/businessUnits/DELIVERY").get()
    return this.http.get<any>(URLS.crossDockBusinessUnitAPI).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getDeliveryLocationsOnMyview(): Observable<any> {
    return this.http.get<any>(URLS.DeliveryToShortBusinessUnitAPI).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getBuyer(appCode, moduleCode, accessCode): Observable<any> {
    let url = URLS.GetBuyerListFromReport + appCode + '/' + moduleCode + '/' + accessCode;
    return this.http.get(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getShipFroms(): Observable<any> {
    return this.http.get<any>(URLS.GetShipFromNewAPI + "SUPPLIER/SHIP_FROM").pipe(
      map((res) => {
        return res;
      }
      ));
  }

  getStatus(): Observable<any> {
    let url = URLS.GetOrderStatus;
    return this.http.get(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getCommodities(): Observable<any> {
    let url = URLS.GetCommodities;
    return this.http.get(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getFilterList(appCode, moduleCode, category, subCategory): Observable<any> {
    let url = URLS.GetFilterListAPI + appCode + '/' + moduleCode + '/' + category + '/' + subCategory;
    return this.http.get(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  saveFilter(data) {
    return this.http.post(URLS.SaveFilterAPI, data, { headers: this.headers, responseType: 'text' })
  }

  saveFilterDetail(data): Observable<any> {
    return this.http.post(URLS.SaveFilterAPI, data, { headers: this.headers, observe: 'response', responseType: 'text' }).pipe(
      map((res) => {
        return res;
      }));
  }

  deleteFilter(id) {
    let url = URLS.DeleteFilterAPI + '?myViewId=' + id;
    return this.http.delete(url, { headers: this.headers, responseType: 'text' }).pipe(
      map(res => {
        return res;
      }));
  }

  searchProductCode(code: any): Observable<any> {
    // return this.$http.baseUrlContext.url("/catalog/api/catalog/search/suggest/" + code).get()
    // return this.$http.baseUrlContext.url("/catalog/api/catalog/search/suggest-code-desc-active/" + code).get()
    let url = URLS.SearchProductCodeAPI + code;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getProductDetailsService(code): Observable<any> {
    // return this.$http.baseUrlContext.url("/catalog/api/catalog/" + code).get()
    // return this.$http.baseUrlContext.url("/catalog/api/catalog/search-code-desc-active/" + code).get()
    let url = URLS.GetProductDetailsAPI + code;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  postApi(data): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/po/buyer/create/").post(data)
    return this.http.post<any>(URLS.POCreateAPI, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }



  createCopyPo(data, id): Observable<any[]> {
    let arr = []
    let url = URLS.DuplicatePOAPI + id + "/copy";
    data.forEach(element => {
      arr.push(
        this.http.post<any>(url, element, { headers: this.headers, observe: 'response' }).pipe(
          map((res) => {
            return res;
          }), catchError(error => of(error))
        )
      )
    });
    return forkJoin(arr);
  }

  checkValidationCopyPO(data): Observable<any[]> {
    let arr = []
    let url = URLS.CheckWarehouseAssociationCopyPO;
    data.forEach(element => {
      arr.push(
        this.http.post<any>(url, element, { headers: this.headers, observe: 'response' }).pipe(
          map((res) => {
            return res;
          }), catchError(error => of(error))
        )
      )
    });
    return forkJoin(arr);
  }


  initializeFormObject(fb) {
    let purchaseOrderForm = {
      poNumber: false,
      sellerCompanyName: false,
      supplierCode: false,
      shipFrom: false,
      fromDate: false,
      deliverTo: false,
      toDate: false,
      poLine: fb.array([])
      //productNo:false
    };
    return fb.group(purchaseOrderForm);
  }

  createPOLineFormGroup(fb) {
    let productsForm = {
      sku: false,
      productName: false,
      description: false,
    };
    return fb.group(productsForm);
  }

  //(HA)PMB-7682 Trace Screen : load more store shipment on scroll
  getOrders(requestData, isElasticSearch = false): Observable<any> {
    return this.http.post<any>(isElasticSearch ? URLS.getOrderListFromElasticSearch : URLS.GetOrderAPI, requestData, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  searchOrderNumber(orderNumber: any): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/buy/search/suggest/" + orderNumber).get()
    let url = URLS.SearchOrderSuggestAPI + orderNumber;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  searchOrder(orderNumber: any): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/buy/search/" + orderNumber).get()
    let url = URLS.SearchOrderNuberAPI + orderNumber;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }
  //(HA)PMB-7682 Trace Screen : load more store shipment on scroll
  // searchOrderByFilter(type: any, input: any, isElasticSearch = false): Observable<any> {
  //   // return this.$http.baseUrlContext.url("/order/buy/search/" + orderNumber).get()
  //   let url;
  //   if(isElasticSearch){
  //     url = URLS.SearchOrderByFiltersElasticAPI + type + '?input=' + input;
  //   }else{
  //      url = URLS.SearchOrderByFiltersAPI + type + '?input=' + input;
  //   }
    
    
  //   return this.http.get<any>(url).pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }

  //(HA)PMB-7682 Trace Screen : load more store shipment on scroll
  searchOrderByFilter(type: any, input: any, isElasticSearch = false): Observable<any> {
    let url = `${URLS.SearchOrderByFiltersAPI}${type}?input=${input}`;
    if (isElasticSearch) {
      url = `${URLS.SearchOrderByFiltersElasticAPI}${type}?input=${input}`;
    }
    return this.http.get<any>(url).pipe(
      map((res) => res)
    );
  }
  
  //#region For Order details Page

  updateOrder(data): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/buy/po/update").put(data)
    return this.http.put<any>(URLS.UpdateOrderAPI, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateOrderNew(data): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/buy/po/update").put(data)
    return this.http.put<any>(URLS.UpdateOrderNewAPI, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getOrderDetails(id): Observable<any> {
    //return this.httpService.get('./assets/OrderDetails.json');
    // var listOfStates = this.$http.baseUrlContext.url('/order/buy/po/' + id).get()
    let url = URLS.GetPOOrderDetails + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
    // return listOfStates;
  }

  getHeaderDetails(id): Observable<any> {
    //return this.httpService.get('./assets/OrderDetails.json');
    // var listOfStates = this.$http.baseUrlContext.url('/order/buy/po/' + id).get()
    let url = URLS.GetPOOrderHeaderDetails + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
    // return listOfStates;
  }

  getHeaderIndicators(id): Observable<any> {
    //return this.httpService.get('./assets/OrderDetails.json');
    // var listOfStates = this.$http.baseUrlContext.url('/order/buy/po/' + id).get()
    let url = URLS.GetPOHeaderIndicators + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
    // return listOfStates;
  }
  getLineSummary(id): Observable<any> {
    //return this.httpService.get('./assets/OrderDetails.json');
    // var listOfStates = this.$http.baseUrlContext.url('/order/buy/po/' + id).get()
    let url = URLS.GetPOLineSummary + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
    // return listOfStates;
  }


  getPOSummary(id): Observable<any> {
    //return this.httpService.get('./assets/OrderDetails.json');
    // var listOfStates = this.$http.baseUrlContext.url('/order/buy/po/' + id).get()
    let url = URLS.GetPOOrderHeaderDetails + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
    // return listOfStates;
  }

  getMetaData(featureCode): Observable<any> {
    //return this.httpService.get('./assets/OrderDetails.json');
    // var listOfStates = this.$http.baseUrlContext.url('/order/buy/po/' + id).get()
    let arr = []
    // arr.push(this.http.get(URLS.GetCOOList).pipe(
    //   map((res) => {
    //     return res;
    //   }))
    // )

    let req = {
      "appCode": "BUYER",
      "moduleCode": "ORDER_MANAGEMENT",
      "featureCode": featureCode,
      "accessCode": "GRANTED"
    }
    arr.push(this.http.post<any>(URLS.AppModuleBRAPI, req, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }))
    )
    return forkJoin(arr);
  }

  getLineDetails(id): Observable<any> {
    //return this.httpService.get('./assets/OrderDetails.json');
    // var listOfStates = this.$http.baseUrlContext.url('/order/buy/po/' + id).get()
    let url = URLS.GetPOOrderLineDetails + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
    // return listOfStates;
  }

  getLineDetailsPaging(req): Observable<any> {
    //return this.httpService.get('./assets/OrderDetails.json');
    // var listOfStates = this.$http.baseUrlContext.url('/order/buy/po/' + id).get()
    let url = URLS.GetPOOrderLineDetailsPaging;
    return this.http.post<any>(URLS.GetPOOrderLineDetailsPaging, req, { headers: this.headers })
      .pipe(map((res) => {
        return res;
      }));
    // return listOfStates;
  }

  getHeaderCharges(id): Observable<any> {
    //return this.httpService.get('./assets/OrderDetails.json');
    // var listOfStates = this.$http.baseUrlContext.url('/order/buy/po/' + id).get()
    let url = URLS.POHeaderCharges + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
    // return listOfStates;
  }
  getLineCharges(poId?, poLineId?): Observable<any> {
    //return this.httpService.get('./assets/OrderDetails.json');
    //var listOfStates = this.$http.baseUrlContext.url('/order/buy/po/' + id).get()
    let url = URLS.POLineCharges + poId + "/" + poLineId;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
    // return listOfStates;
  }



  checkParellelTransaction(id, versioning) {
    let url = URLS.CheckParellelTransactions + '/' + id + '/' + versioning;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
  }

  findByClaimId(id) {
    let url = URLS.ClaimDetailsAPI + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
  }

  getLoadData(id): Observable<any> {
    // return this.$http.baseUrlContext.url('/logistics/load/find-by-po/' + id).get()
    let url = URLS.GetLoadData + id;
    return this.http.get<any>(url, { headers: this.headers, observe: 'response' }).pipe(
      map((res) => {
        return res;
        // return this.extractData(res);
      }));
  }

  //#endregion

  getProduct(productId): Observable<any> {
    // return this.$http.baseUrlContext.url("/catalog/api/catalog/fetchCatalogItemPrimary/" + productId).get()

    let url = URLS.OrderDetailsAPI + productId;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }
      ));
  }

  /********************************* Move Line Calls Start **********************************/

  isPONumberExists(poNumber): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/po/exists-by-ponumber/" + poNumber).get()
    let url = URLS.PONumberExistsAPI + poNumber;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getExistingPOList(id): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/buy/move-line/exists-order-list/" + id).get()
    let url = URLS.GetExistingPOList + id;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  postMoveLine(data): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/buy/move-line/").post(data)
    return this.http.post<any>(URLS.PostMoveLine, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  postMoveLineNewAPI(data): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/buy/move-line/").post(data)
    return this.http.post<any>(URLS.PostMoveLineNewAPI, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  /********************************* Move Line API Call End **********************************/

  /********************************* Add Line Calls Start **********************************/
  postAddLine(data, isNew = false): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/buy/add-line/").post(data)
    let url;
    if (isNew) {
      url = URLS.AddLineAPINew
    } else {
      url = URLS.AddLineAPI;
    }
    return this.http.post<any>(url, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );

  }


  /********************************* Add Line Calls End **********************************/

  updateLineProduct(data): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/buy/po/line-product/update/").put(data)
    return this.http.put<any>(URLS.UpdateLineProduct, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  extractData(response) {
    return response.body;
  }


  /****************************** Change Supplier Start ***************************************/

  changeSupplier(data): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/po/buyer/create/").post(data)
    return this.http.post<any>(URLS.ChangeSupplier, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  /****************************** Change Supplier End ***************************************/

  getSubStatusIcon(subStatus: string, isIcon: boolean = true): string {
    let subStatusIcon: string;
    let subStatusTooltip: string;
    switch (subStatus) {
      case this.SUBSTATUS.BUYER_MODIFIED:
        subStatusIcon = 'B';
        subStatusTooltip = this.SUBSTATUS.BUYER_MODIFIED;
        break;
      case this.SUBSTATUS.BUYER_RECONCILED:
        subStatusIcon = 'B';
        subStatusTooltip = this.SUBSTATUS.BUYER_RECONCILED;
        break;
      case this.SUBSTATUS.SUPPLIER_MODIFIED:
        subStatusIcon = 'R';
        subStatusTooltip = this.SUBSTATUS.SUPPLIER_MODIFIED;
        break;
      case this.SUBSTATUS.SUPPLIER_RECONCILED:
        subStatusIcon = 'R';
        subStatusTooltip = this.SUBSTATUS.SUPPLIER_RECONCILED;
        break;
      case this.SUBSTATUS.BUYER_SUPPLIER_MODIFIED:
        subStatusIcon = 'R';
        subStatusTooltip = this.SUBSTATUS.BUYER_SUPPLIER_MODIFIED;
        break;
      case this.SUBSTATUS.BUYER_CANCELLED:
        subStatusIcon = 'B';
        subStatusTooltip = this.SUBSTATUS.BUYER_CANCELLED;
        break;
      case this.SUBSTATUS.SUPPLIER_CANCELLED:
        subStatusIcon = 'R';
        subStatusTooltip = this.SUBSTATUS.SUPPLIER_CANCELLED;
        break;
      default:
        subStatusIcon = ''
        subStatusTooltip = '';
        break;
    }

    if (isIcon)
      return subStatusIcon;
    else
      return subStatusTooltip;

  }

  /****************************** Change Supplier End ***************************************/

  /*********************************** ORDER HISTORY CALLS STARTS **************************************/

  getOrderHistory(request, orgId): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/sup/po/update").put(data)
    return this.http.post<any>(URLS.GetOrderHistory + orgId, request, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  getPOLineHistory(orgId, queryParam): Observable<any> {
    let url = URLS.GetPoLineHistory + orgId + queryParam;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }
  /*********************************** ORDER HISTORY CALLS ENDS **************************************/

  getPORange(param) {
    return this.http.post(URLS.PONumberGenerateAPI, param, { observe: 'response' })
  }
  PONumberRelease(param) {
    return this.http.post(URLS.PONumberReleaseAPI, param)
  }
  getFileObjectDocument(objId): Observable<any> {
    let url = URLS.downloadFile + objId;
    return this.http.get(url, { headers: this.headers, observe: 'response', responseType: 'blob' })
  }

  DeletePODocument(id) {
    let url = URLS.GetPoLineHistory + id;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }
  FetchDocument(id) {
    let url = URLS.GetPoLineHistory + id;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }


  /* Order Documents */
  uploadPODoc(id, data, levelOfUpload) {
    let headers = new HttpHeaders();
    // Add Content-Type be default
    headers.set('Content-Type', 'application/json');
    let url = ''
    if (levelOfUpload == "LINE_LEVEL") {
      url = URLS.POLineFileUpload + id
    } else {
      url = URLS.POFileUpload + id
    }
    return this.http.post(url, data, {
      reportProgress: true,
      observe: 'events',
      headers: this.headers,
      responseType: 'text'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };
        case HttpEventType.Response: {
          this.notify.show(this.transalate.instant('COMPANY.MESSAGE.DCO_UPLOAD_SUCCESS'));
          return event.body;
        }
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  FetchPODocument(id, levelOfUpload, poId?) {
    let url = ''
    if (levelOfUpload == "LINE_LEVEL") {
      url = URLS.FetchPOLineLevelFiles + id + "/" + poId
    } else {
      url = URLS.FetchPOLevelFiles + id
    }
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deletePODocument(id, sourceId?, sourceType?) {
    let url = URLS.DeletePODocument + id + '&sourceId=' + sourceId + '&sourceType=' + sourceType
    return this.http.delete(url, { headers: this.headers, responseType: 'text' })
  }

  deleteLineOrPO(reqData) {
    return this.http.request('DELETE', URLS.DeleteDraftPOLines, { headers: this.headers, body: reqData, responseType: 'text' }).pipe(
      map(res => {
        return res;
      }));
  }
  deleteDraftLineOrPO(poId, poLineId = null) {
    let url = '';
    if (poLineId != null)
      url = URLS.DeleteDraftPOLine + poLineId + "&poId=" + poId
    else
      url = URLS.DeleteDraftPO + poId
    return this.http.delete(url, { headers: this.headers, responseType: 'text' })
  }

  getFileObject(objId): Observable<any> {
    let url = URLS.GetPOFileObjectViewAPI + objId;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
        // return this.extractData(res);
      }));
    // return orgData;
  }

  deleteFromWatchList(...para) {
    let url = URLS.DeleteFromWatchList + 'userId=' + para[0] + '&poId=' + para[1];
    return this.http.delete(url, { headers: this.headers, responseType: 'text' })
  }

  addToWatchList(reqData) {
    let url = URLS.AddToWatchList;
    return this.http.post(url, reqData, { headers: this.headers, responseType: 'text' })
  }


  getDraftDetails(id): Observable<any> {
    let url = URLS.GetPODraftDetails + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
    // return listOfStates;
  }

  CreateDraftApi(data): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/po/buyer/create/").post(data)
    return this.http.post<any>(URLS.PODraftCreateAPI, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  getOrderGuides(): Observable<any> {
    return this.http.get<any>(URLS.GetOrderGuideListAPI).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getOrderGuideListByBuyerId(id) {
    let url = URLS.getOrderGuidesURL + id;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getProductListByTemplateId(id): Observable<any> {
    return this.http.get<any>(URLS.GetProductListByTemplateId + id).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getInvoiceDetails(id): Observable<any> {
    let url = URLS.GetInvoiceFormPoId + id;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }));
  }

  paymenttermDetailsById(id): Observable<any> {
    return this.http.get<any>(URLS.PaymenttermDetailsById + id).pipe(
      map((res) => {
        return res;
      }
      ));
  }

  getAppModuleAccess(data): Observable<any> {
    return this.http.post<any>(URLS.AppModuleAccessAPI, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  balanceItem(data): Observable<any> {
    return this.http.post<any>(URLS.BalanceItem, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  setIndicatorAsViwed(reqData) {
    let url = URLS.setIndicatorAsViwedAPI;
    return this.http.post(url, reqData, { headers: this.headers, responseType: 'text' })
  }

  getOrganizationBR(featureCode) {
    let req = {
      "appCode": "BUYER",
      "moduleCode": "ORDER_MANAGEMENT",
      "featureCode": featureCode,//"ISSUE_ORDER_WITH_NO_PRICE",
      "accessCode": "GRANTED"
    }
    return this.http.post<any>(URLS.AppModuleBRAPI, req, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  getAllUsers(id) {
    return this.http.get<any>(URLS.getAllUsers + id, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  getTraceInfo(orderId): Observable<any> {
    return this.http.get<any>(URLS.GetTraceInfo + orderId).pipe(
      map((res) => {
        return res;
      }));
  }

  getLastShipFromLocation(data): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/buy/add-line/").post(data)
    return this.http.post<any>(URLS.GetLastShipFromLocationAPI, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getBRRule(moduleCode, featureCode): Observable<any> {
    let req = {
      "appCode": "BUYER",
      "moduleCode": moduleCode,
      "featureCode": featureCode,
      "accessCode": "GRANTED"
    }
    return this.http.post<any>(URLS.AppModuleBRAPI, req, { headers: this.headers })
      .pipe(map((res) => {
        return res;
      }));
  }

  checkWarehouseAssociation(data): Observable<any> {
    return this.http.get<any>(URLS.CheckWarehouseAssociation + data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getOrgDetails(orgId) {
    let url = URLS.GetOrgsDetails + "?ids=" + [orgId];
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }));
  }

  getOrgsListDetails(orgIds: number[] = []) {
    let url = URLS.GetOrgListDetails + "?ids=" + orgIds
    return this.http.get<any>(url, { headers: this.headers })
      .pipe(map((res) => {
        return res;
      }));
  }

  getCountryCodeList(): Observable<any> {
    return this.http.get(URLS.GetCountryListAPI)
  }

  getSearchedProductDetails(data): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/po/buyer/create/").post(data)
    return this.http.post<any>(URLS.getSearchedProductDetails, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  getOrderDefaultBySupplierAndDelivery(supplierId, deliveryToId): Observable<any> {
    let url = URLS.GetOrderDefaultBySupplierAndDeliveryAPI + supplierId + "/" + deliveryToId;
    return this.http.get(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getOrderDefaultListBySupplier(supplierId): Observable<any> {
    let url = URLS.GetOrderDefaultListAPI + "/" + supplierId;
    return this.http.get(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  RetrieveItemInspectionScore(id): Observable<any> {
    let url = URLS.RetrieveItemInspectionScore;
    let data = {
      "poLineId": id
    }
    return this.http.post(url, data, { headers: this.headers, observe: 'response' })
      .pipe(map((res: any) => {
        if (res.status == 200) {
          return res.body;
        } else {
          return [];
        }
      }));
  }

  GetInspectedOrderLines(id): Observable<any> {
    let url = URLS.GetInspectedOrderLines + id;
    return this.http.get(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  fetchRejectedItem(id): Observable<any> {
    let url = URLS.FetchRejectedItem;
    let data = {
      "poLineId": id
    }
    return this.http.post(url, data, { headers: this.headers, observe: 'response' })
      .pipe(map((res: any) => {
        if (res.status == 200) {
          return res.body;
        } else {
          return [];
        }
      }));
  }

  //(RS)PMB-6641, PMB-PMB-6829 fetch-tolerances api updated
  // fetchTolerances(code): Observable<any> {
  //   let url = URLS.FetchTolerances + code;
  //   return this.http.get<any>(url)
  //     .pipe(map((res) => {
  //       return res;
  //     }));
  // }

  //(RS)PMB-6641, PMB-PMB-6829 fetch-tolerances api updated
  fetchTolerances(req): Observable<any> {
    let url = URLS.FetchTolerances;
    return this.http.post(url, req, { headers: this.headers, observe: 'response' })
      .pipe(map((res: any) => {
        if (res.status == 200) {
          return res.body;
        } else {
          return [];
        }
      }));
  }
  fetchDefects(code): Observable<any> {
    let url = URLS.FetchDefects + code;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
  }

  fetchLots(id): Observable<any> {
    let url = URLS.GetLots + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
  }

  getproductDetailsByIds(req): Observable<any> {
    return this.http.post<any>(URLS.GetProductDetails, req, { headers: this.headers })
      .pipe(map((res) => {
        return res;
      }));
  }
  cancelledPOMarkedAsViewed(requestData): Observable<any> {
    return this.http.post(URLS.CancelledPOMarkedAsViewedAPI, requestData, { headers: this.headers, responseType: 'text' })
  }

  getPreferredBusinessUnitsDeliveryList(userId): Observable<any> {
    return this.http.get<any>(URLS.getPreferredBusinessUnitsDeliveryAPI + '/' + userId).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getOrderGuideProductPrice(supplierId, productId): Observable<any> {
    return this.http.get<any>(URLS.GetOrderGuideProductPrice + supplierId + '/' + productId).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getSplitPo(poId): Observable<any> {
    return this.http.put<any>(URLS.getSplitPo + '/' + poId, { headers: this.headers });
  }

  public getSpliLinetPo(poId): Observable<any> {
    return this.http.put<any>(URLS.getSplitLinePo + '/' + poId, { headers: this.headers });
  }

  //(OL) PMB-6351
  fetchLoadRejection(req): Observable<any> {
    let url = URLS.FetchLoadInspection;
    return this.http.post(url, req, { headers: this.headers, observe: 'response' })
      .pipe(map((res: any) => {
        if (res.status == 200) {
          return res.body;
        } else {
          return [];
        }
      }));
  }

  getUserCommodities(): Observable<any> {
    let url = URLS.GetCommoditiesAPI;
    return this.http.get(url).pipe(
      map((res) => {
        return res;
      })
    );
  }



  rejectionReasons(): Observable<any> {
    let url = URLS.FetchLoadRejectionReasons;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
  }

  getDemandHeaderDetails(data): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/po/buyer/create/").post(data)
    return this.http.post<any>(URLS.GetDemandHeaderDetails, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  getLatestComment(id): Observable<any> {
    return this.http.get<any>(URLS.GetLatestCommentAPI + '/true/PO/' + id).pipe(
      map((res) => {
        return res;
      }
      ));
  }

  //(PM)PMB-8258 On Quick PO screen provide option (a new button) to pull pricing from Price sheets (request for pricing)
  getMarketPriceForQuickPo(req): Observable<any> {
    return this.http.post<any>(URLS.MarketPriceForQuickPo, req, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }



  // common logic of Order Object Prepartion for order creation

  filteredDepartments = [];
  isEnabledDemandPlanDepartBR: boolean;
  getAppDemandModuleAccessBusinessRules(): Observable<string[]> {
    const arrObj = [constant.getAppModuleAccessObj(SUBSCRIBED_APPS.BUYER, MODULE_CODE.DEMAND, FEATURE_CODE.DEMAND_PLANNING_SUPPLIERS_BY_DEPT)];
    const obj = { brRulesRequest: arrObj };

    return this.getAppModuleAccess(obj).pipe(
      switchMap(resp => {
        const isApprovedDemandPlanDeptBR = resp.find(t => t.featureCode === FEATURE_CODE.DEMAND_PLANNING_SUPPLIERS_BY_DEPT);
        this.isEnabledDemandPlanDepartBR = isApprovedDemandPlanDeptBR?.accessCode === 'GRANTED';
        const selectedDepartmentsByBR = this.extractApprovedDepartments(resp);
        if (selectedDepartmentsByBR.length) {
          return this.getLoggedInUserDepartmentsList(selectedDepartmentsByBR).pipe(
            map(() => this.filteredDepartments)
          );
        } else {
          return of([]);
        }
      })
    );
  }

  private extractApprovedDepartments(resp): string[] {
    const selectedDepartmentsByBR: string[] = [];
    const demandPlanModuleBR = resp.find(code => code.featureCode === FEATURE_CODE.DEMAND_PLANNING_SUPPLIERS_BY_DEPT);
    demandPlanModuleBR?.attributeDept?.forEach(attributeDept => {
      if (attributeDept.departmentCode && attributeDept.accessCode === 'GRANTED') {
        selectedDepartmentsByBR.push(attributeDept.departmentCode);
      }
    });
    return selectedDepartmentsByBR;
  }

  private getLoggedInUserDepartmentsList(selectedDepartmentsByBR: string[]): Observable<void> {
    return this.getLoggedInUserDepartments(this.auth.user.info.id).pipe(
      map(resp => {
        if (resp) {
          this.filteredDepartments = selectedDepartmentsByBR.filter(dept => resp.map(dept => dept.domainvalueCode).includes(dept));
        }
      })
    );
  }
  
  getLoggedInUserDepartments(id): Observable<any> {
    let url = URLS.GetUserDepartment + id;
    return this.http.get<any>(url);
  }

  getSupplierCatalogProduct(data): Observable<any> {
    return this.http.post<any>(URLS.GetSupplierCatalogProductAPI, data, { headers: this.headers});
  }
  getOrderRestrictionType(data): Observable<any> {
    return this.http.post<any>(URLS.GetOrderRestrictionTypeAPI, data, { headers: this.headers});
  }

  getCatalogHeaderInfo(data): Observable<any> {
    return this.http.post<any>(URLS.GetSupplierCatalogHeaderInfoAPI, data, { headers: this.headers});
  }
}