/***********************************************************************
* DESCRIPTION :
* Quick Order Search in Buyer and Supplier/Vendor Applications
* NOTES :
* AUTHOR :  Hitesh Ahire       CREATE DATE :    26 March 2024
* CHANGES : Added new component
* REF NO          VERSION   DATE         WHO             DETAIL
* PMB-8552        2.6.0     26/03/2024   Hitesh Ahire    Quick Order Search in Buyer and Supplier/Vendor Applications
************************************************************************/

import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PopoverAnchorDirective, PopoverComponent } from '@progress/kendo-angular-tooltip';
import { AppToolbarService } from '../../services/app-toolbar.service';
import { environment } from '@procurant/env/environment';
import { debounceTime, filter, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs'; // Import 'of' operator for catchError
import { PopupComponent } from '@progress/kendo-angular-popup';
import { AppService } from '@procurant/app/app.service';
import { OneAuthService, OneNotifyService } from 'one-auth';
import { FEATURE_CODE, MODULE_CODE, SUBSCRIBED_APPS, StorageService } from 'common-lib';
import { error } from 'console';

@Component({
    selector: 'global-serach',
    templateUrl: './global-serach.component.html',
    styleUrls: ['./global-serach.component.scss']
})
export class GlobalSerachComponent implements OnInit {
    globalSearchFormControl: FormControl = new FormControl();

    // Use ViewChild to get a reference to the Kendo Popover Anchor directive
    @ViewChild('anchor') anchor: PopoverAnchorDirective;

    @ViewChild('myPopover') myPopover: PopupComponent;
    @ViewChild('listSectionDiv') listSectionDiv: ElementRef;

    public responseData: any;
    public noDataFound = false;
    public filterLists = {};
    public showAll = {};
    public facetsKeys = [];
    // public orderKeys = [];
    // public filterKeys = ['substatus', 'sellercompany', 'freightterm', 'buyerowner'];
    isPopoverOpen: boolean = false;
    public crossDockFacility = false;
    public query = {
        "postatus": [],
        "freightterm": [],
        "supplier": [],
        "buyerowner": [],

        "pageNumber": 0,
        "pageSize": 20,

        "value": "",
    };
    @ViewChild('myPopover') elementToResize!: ElementRef;
    calculatedHeight: number = 0;
    calculatedWidth: number = 0;

    public statusList = [];
    public supplierList = [];
    public freightTermList = []
    public buyerUSerList = [];
    public statusSelectedList = [];
    public supplierSelectedList = [];
    public freightTermSelectedList = []
    public buyerUSerSelectedList = [];

    // todo :  need to remove
   
    isLoader = false;

    constructor(private appToolbarService: AppToolbarService, private appService: AppService, private elementRef: ElementRef, public notify: OneNotifyService, private sessionStorage: StorageService) { }

    ngOnInit(): void {
        this.resetSelectedList();
        this.query.pageNumber = 0;
        this.updateQueryAndFetchData();
    }
    ngAfterViewInit(): void {
        this.calculateSize();
        // Listen to window resize event to recalculate size
        window.addEventListener('resize', this.calculateSize.bind(this));
        this.getBusinessRules();
    }

    // Listen to the escape key press event
    @HostListener('window:keyup.esc') onEscKeyUp() {
        this.onClosePopUp();
    }

    // Close the popover when clicking outside of it and the main section
    @HostListener('document:click', ['$event'])
    onClick(event: MouseEvent) {
        // const clickedInsidePopover = this.elementRef.nativeElement.contains(event.target);
        const clickedInsideMainSection = (event.target as HTMLElement).closest('.global-main-section');
        const clickedInsideGlobalSearchContainer = (event.target as HTMLElement).closest('.global-search-container');
        const clickedInsideKendoBody = (event.target as HTMLElement).closest('.k-popover-body');

        if (!clickedInsideGlobalSearchContainer && !clickedInsideMainSection && !clickedInsideKendoBody) {
            // Clicked outside the popover and the main section, close it
            this.onClosePopUp();
        }
    }

    private getBusinessRules() {
        let request = {
            brRulesRequest: [
                {
                    appCode: SUBSCRIBED_APPS.BUYER,
                    moduleCode: MODULE_CODE.ORDER_MANAGEMENT,
                    featureCode: FEATURE_CODE.ALLOW_BUYERS_CREATE_CROSS_DOCK_ORDERS
                }
            ]
        }

        this.appToolbarService.getAppModuleAccess(request).subscribe({
            next: (response) => {
                this.crossDockFacility = response.some(t => t.featureCode == FEATURE_CODE.ALLOW_BUYERS_CREATE_CROSS_DOCK_ORDERS);
            },
            error: (error) => {
                this.notify.show("No service available.", 'error');
            }
        });
    }

    calculateSize(): void {
        // const element = this.elementToResize.nativeElement;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        this.calculatedWidth = 0.98 * screenWidth;
        this.calculatedHeight = 0.88 * screenHeight;
    }




    public orderList = [];
    public updateQueryAndFetchData() {
        this.globalSearchFormControl.valueChanges.pipe(
            debounceTime(1000),
            filter(selectedItems => this.istypeHeadTextValid(selectedItems.trim())),
            switchMap((val) => this.appToolbarService.getGlobalSerachResult(this.query, val)))
            .subscribe({
                next: res => {
                    if (res) {
                        this.orderList = [];
                        this.responseData = res;
                        this.orderList = this.responseData?.content; // pass order list response to list object 
                        //set filter data
                        // this.statusList = this.responseData?.facets?.substatus;
                        this.setStatusList(this.responseData?.facets?.substatus);
                        this.supplierList = this.responseData?.facets?.sellercompany;
                        // this.freightTermList = this.responseData?.facets?.freightterm;
                        this.buyerUSerList = this.responseData?.facets?.buyerowner;
                        this.setFreightTermList(this.responseData?.facets?.freightterm)

                        // this.facetsKeys = Object.keys(this.responseData?.facets);
                        this.appService.makeBgDisabled = true;
                        this.isLoader = false;
                        this.anchor.show();
                    } else {
                        if (this.globalSearchFormControl.value.trim().length >= 4) {
                            this.appService.makeBgDisabled = true;
                            this.isLoader = false;
                            this.anchor.show();
                        }
                        this.responseData = [];
                        // this.appService.makeBgDiable = false;
                    }
                },
                error: error => {
                    this.notify.show("No service available.", 'error')
                    console.error("Error in search results subscription:", error);
                    this.isPopoverOpen = false;
                    this.appService.makeBgDisabled = false;
                    this.isLoader = false;
                    this.responseData = []
                }
            });
    }

    public onSelectedValues(data) {
        this.isLoader = true;
        switch (data.propertyName) {
            case 'Order Status':
                this.query.postatus = data.filters;
                break;
            case 'Freight Term':
                this.query.freightterm = data.filters;
                break;
            case 'Buyer User':
                this.query.buyerowner = data.filters;
                break;
            case 'Suppliers':
                this.query.supplier = data.filters;
                break;
            // case 'Invoice Status':
            //     this.query.invoicestatus = data.filters;
            //     break;

            // case 'Buyer Company':
            //     this.query.buyercompany = data.filters;
            //     break;

        }
        this.query.pageNumber = 0;
        this.appToolbarService.getGlobalSerachResult(this.query, this.globalSearchFormControl.value.trim()).subscribe({
            next: res => {
                if (res) {
                    this.orderList = [];
                    this.responseData.content = res.content;
                    this.responseData.totalRecords = res.totalRecords;
                    this.orderList = [...this.orderList, ...this.responseData.content];
                    this.scrollToTop();
                    this.updateFilterData(data.propertyName, res);
                    this.isLoader = false;

                } else {
                    this.orderList = [];
                    this.responseData.content = [];
                    this.responseData.totalRecords = 0;
                    this.isLoader = false;
                }
            },
            error: error => {
                this.notify.show("No service available.", 'error');
            }
        });
    }

    private setStatusList(res) {
        this.statusList = [
            { name: "Acknowledged", count: 0 },
            { name: "Buyer Cancelled", count: 0 },
            { name: "Buyer Modified", count: 0 },
            { name: "Buyer Reconciled", count: 0 },
            { name: "Buyer Supplier Modified", count: 0 },
            { name: "Draft", count: 0 },
            { name: "New", count: 0 },
            { name: "Received", count: 0 },
            { name: "Received - Adjusted", count: 0 },
            { name: "Received Exception", count: 0 },
            { name: "Received Reconciled", count: 0 },
            { name: "Shipped", count: 0 },
            { name: "Shipped (via EDI Receipt Feed)", count: 0 },
            { name: "Shipped Modified", count: 0 },
            { name: "Supplier Cancelled", count: 0 },
            { name: "Supplier Modified", count: 0 },
            { name: "Supplier Reconciled", count: 0 }
        ];        

        // Iterate over the res list
        for (let item of res) {
            // Find the corresponding entry in freightTermList and update count
            let index = this.statusList.findIndex(term => term.name === item.name);
            if (index !== -1) {
                this.statusList[index].count = item.count;
            }
        }
    }


    private setFreightTermList(res) {
        this.freightTermList = [
            { name: "FOB Carrier", count: 0 },
            { name: "Backhaul", count: 0 },
            { name: "Delivered", count: 0 }
        ];

        // Iterate over the res list
        for (let item of res) {
            // Find the corresponding entry in freightTermList and update count
            let index = this.freightTermList.findIndex(term => term.name === item.name);
            if (index !== -1) {
                this.freightTermList[index].count = item.count;
            }
        }
    }

    // filter section 
    public getFacetData(key: string): any[] {
        return this.responseData?.facets[key] || [];
    }

    //update filters base on click
    private updateFilterData(filterName, res) {
        //status 
        // if (this.query.postatus.length > 0) {
        if (filterName != "Order Status" || this.query.postatus.length <= 0) {
            this.setStatusList(res?.facets?.substatus);
            this.statusSelectedList = [];
            if (this.query.postatus.length > 0) {
                this.statusSelectedList = res?.facets?.substatus;
            }
        }
        // else {
        //     this.statusSelectedList = [];
        // }

        //supplier

        // if (this.query.supplier.length > 0) {
        if (filterName != 'Suppliers' || this.query.supplier.length <= 0) {
            this.supplierList = res?.facets?.sellercompany;
            this.supplierSelectedList = [];
            if (this.query.supplier.length > 0) {
                this.supplierSelectedList = res?.facets?.sellercompany;
            }
        }
        // else {
        //     this.supplierSelectedList = [];
        // }

        //buyer user

        // if (this.query.buyerowner.length > 0) {
        if (filterName != 'Buyer User' || this.query.buyerowner.length <= 0) {
            this.buyerUSerList = res?.facets?.buyerowner;
            this.buyerUSerSelectedList = [];
            if (this.query.buyerowner.length > 0) {
                this.buyerUSerSelectedList = res?.facets?.buyerowner;
            }
        }
        // else {
        //     this.buyerUSerSelectedList = [];
        // }

        //freight term

        // if (this.query.freightterm.length > 0) {
        if (filterName != 'Freight Term' || this.query.freightterm.length <= 0) {
            this.setFreightTermList(res?.facets?.freightterm);
            this.freightTermSelectedList = [];
            if (this.query.freightterm.length > 0) {
                this.freightTermSelectedList = res?.facets?.freightterm;
            }
        }
        // else {
        //     this.freightTermSelectedList = [];
        // }
    }

    //scroll paggination
    public loadMoreOrders() {
        // if (this.orderList.length < this.totalRecords) {
        this.query.pageNumber += 1;
        this.getOrderListOnly();
        // }
    }
    //on filter click fetch order list
    private getOrderListOnly() {
        this.appToolbarService.getGlobalSerachResult(this.query, this.globalSearchFormControl.value.trim()).subscribe({
            next: res => {
                this.responseData.content = res.content;
                this.responseData.totalRecords = res.totalRecords;
                this.orderList = [...this.orderList, ...this.responseData.content];


                // this.orderKeys = Object.keys(this.responseData?.content);
                // this.orderKeys.forEach(key => {
                //     let data = this.getOrderData(key);
                //     // Modify the data by adding a new property
                //     let isShowProduct = this.getSearchByProduct(key);
                //     data.forEach(item => {
                //         item.isShowProduct = isShowProduct; // Add a new property to each item in data
                //     });
                //     this.orderList.push(...data)
                // });

            }
        });
    }

    // Placeholder method, implement istypeHeadTextValid according to your requirements
    istypeHeadTextValid(selectedItems: any): boolean {
        this.checkInputRest();
        let minNumber = 3;
        this.resetAllFilters();
        if (selectedItems != null && selectedItems.length > minNumber) {
            let txt = selectedItems.trim();
            if (txt != '') {
                this.isLoader = true;
                this.query.pageNumber = 0;
                this.scrollToTop();
                return true;
            }
            else {
                this.responseData = [];
                return false;
            }
        } else {
            this.responseData = [];
            return false;
        }
    }

    private resetAllFilters() {
        this.query.postatus = [];
        this.query.freightterm = [];
        this.query.supplier = [];
        this.query.buyerowner = [];
        this.resetSelectedList();
    }
    
    private resetSelectedList() {
        this.statusSelectedList = this.supplierSelectedList = this.freightTermSelectedList = this.buyerUSerSelectedList = [];
    }

    public onGlobalSerachQueryChange() {
        if (this.globalSearchFormControl.value.trim().length > 3) {
            this.anchor.show();
            this.resetAllFilters();
            this.updateQueryAndFetchData();
        } else {
            this.anchor.hide();
        }
    }

    //global search event catch
    onClosePopUp() {
        this.globalSearchFormControl.patchValue('');
        this.query.pageNumber = 0;
        this.responseData = [];
        this.appService.makeBgDisabled = false;
        this.resetSelectedList();
        this.anchor.hide()
    }

    //po status filter key
    private getAllPoStatusList() {
        const poFilterList = [
            "Acknowledged",
            "Buyer Cancelled",
            "Buyer Modified",
            "Supplier Modified",
            "Buyer Reconciled",
            "Buyer Supplier Modified",
            // "Partial Shipped",
            // "Partially Shipped - Supplier Mod",
            // "Partially Shipped - Buyer Mod",
            // Partially Shipped - Supplier/Buyer Mod
            // Partially Received
            "Draft",
            "New",
            "Received",
            "Received - Adjusted",
            "Received Exception",
            "Received Reconciled",
            "Shipped",
            "Supplier Cancelled",
            "Shipped Modified",
            "Shipped (via EDI Receipt Feed)",
            "Supplier Reconciled"
        ];

        let respList = this.responseData?.facets['postatus'] || [];

        // Update counts for existing names or add new names with count 0
        poFilterList.forEach(name => {
            const existingItem = respList.find(item => item.name === name);
            if (!existingItem) {
                respList.push({ name, count: 0 });
            }
        });

        return respList;
    }


    public getOrderData(key: string): any[] {
        return this.responseData?.content[key] || [];
    }

    public getSearchByProduct(searchList: any) {
        let elementsToCheck = ['buyerproduct.code', 'buyerproduct.description', 'buyerproduct.code.keyword', 'buyerproduct.description.keyword'];
        searchList = searchList.slice(1, -1);
        // Split the string by comma and remove any extra whitespace
        let resultList = searchList.split(',').map(item => item.trim());
        return elementsToCheck.some(item => resultList.includes(item));
    }

    // Function to scroll to top
    public scrollToTop() {
        if (this.listSectionDiv && this.listSectionDiv.nativeElement) {
            this.listSectionDiv.nativeElement.scrollTop = 0;
        }
    }

    public clearInputValue() {
        this.resetSelectedList();
        this.globalSearchFormControl.patchValue('');
    }

    // open order details in new tab
    openNewTab(order) {
        if (order?.postatus == "Draft") {
            const url = ['order/order-list/create', order?.id].join('/');
            window.open(url, '_blank');
        } else {
            const url = ['order/order-list/details', order?.id].join('/');
            this.sessionStorage.remove('loadToOrderDetails'); // (JJ) PMB-8445 Allow buyers to edit POs from Load Details screen
            window.open(url, '_blank');
        }
    }

    public checkInputRest() {
        if (this.globalSearchFormControl.value.trim().length < 4) {
            this.anchor.hide();
            this.appService.makeBgDisabled = false;
        }
    }

    public onHidden(){
        if(document.querySelector(".k-popover") && this.elementToResize){
            document.querySelector(".k-popover").classList.remove("global-search-k-popover")
        }
    }
    public onShown(){
        if(document.querySelector(".k-popover") && this.elementToResize){
            document.querySelector(".k-popover").classList.add("global-search-k-popover")
        }
    }
}
