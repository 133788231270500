import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentInfobarComponent } from './components/content-infobar/content-infobar.component';
import { HeaderToolbarComponent } from './components/header-toolbar/header-toolbar.component';
import { SideToolbarComponent } from './components/side-toolbar/side-toolbar.component';
import { RouterModule } from '@angular/router';
import { MaterialUIModule } from '../material-ui/material-ui.module';
import { OneToolbarModule } from 'one-auth';
import { SharedModule } from '@procurant/sharedcomponent/shared-components.module';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { GlobalSerachComponent } from './components/global-serach/global-serach.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    ContentInfobarComponent,
    HeaderToolbarComponent,
    SideToolbarComponent,
    GlobalSerachComponent,
  ],
  imports: [
    CommonModule,
    MaterialUIModule,
    OneToolbarModule,
    RouterModule,
    SharedModule,
    TooltipsModule,
    InfiniteScrollModule
  ],
  exports: [
    HeaderToolbarComponent,
    SideToolbarComponent,
    ContentInfobarComponent
  ]
})
export class AppToolbarsModule { }
