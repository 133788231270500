import { Component, Input, OnInit, TemplateRef } from '@angular/core';
/**
 * This component will be used to show custom popover
 * 
 * This component will be rendered using OverlayModule of angular material
 * This component will be rendered by directive on an Overlay
 * 
 * CONSUMER will not be using this component directly
 * This component will be hosted in an overlay by popoverRenderer directive
 * This component exposes two properties. These two properties will be filled by PopoverRenderer directive
 * 1. text - This is a simple string which is to be shown in the popover; This will be injected in the PopoverRenderer directive
 *    by the consumer
 * 2. contentTemplate - This gives finer control on the content to be shwon in the popover
 * 
 */
@Component({
  selector: 'procurant-popover',
  templateUrl: './procurant-popover.component.html',
  styleUrls: ['./procurant-popover.component.scss']
})
export class ProcurantPopoverComponent implements OnInit {

   /**
   * This is simple text which is to be shown in the tooltip
   */
   @Input() text: string;

   /**
     * This provides finer control on the content to be visible on popover
     * This template will be injected in popoverRenderer directive in the consumer template
     * <ng-template #template>
     *  content.....
     * </ng-template>
     */
    @Input() contentTemplate: TemplateRef<any>;
  
    constructor() { }
  
    ngOnInit() {
    }
  
  }
