import { Component, ViewChild, TemplateRef, OnInit, EventEmitter, Output } from "@angular/core";
import { NotificationService, NotificationRef  } from "@progress/kendo-angular-notification";
import { TranslateService, constant } from "common-lib";
import { EventsOutsideAngularDirective } from "@progress/kendo-angular-common";
import { isString } from "is-what";
import { Router } from "@angular/router";

@Component({
  selector: 'app-push-notifications',
  templateUrl: './push-notifications.component.html',
  styleUrls: ['./push-notifications.component.scss']
})
export class PushNotificationsComponent implements OnInit {
  errors = [];
  title: string = 'Error';
  hideAfter = 5000;
  btnOK: any;
  type:any;
  @Output() refreshPage: EventEmitter<any> = new EventEmitter();

  @ViewChild("template", { read: TemplateRef })
  public notificationTemplate: TemplateRef<any>;
  notification: NotificationRef;

  constructor(private notificationService: NotificationService, private translate: TranslateService, private router: Router) { }

  public show(err, duration = 1000, type='error', btnOK?): void {
    if (err != null || err != undefined) {
      this.btnOK = btnOK;
      this.type = type;
      this.errors = [];
      if (err.error != null || err.error != undefined) {
        if (err.error.errorCode != null || err.error.errorCode != undefined) {
          if (err.error.errorCode == "VALIDATION_ERROR") {
            err.error.subErrors.forEach(element => {
              let errorMsg = this.translate.instant('ERRORS.' + element.errorCode);
              isString(errorMsg) ? this.errors.push(errorMsg) : this.errors.push(element.message)
            });
          } else {
            let errorMsg = this.translate.instant('ERRORS.' + err.error.errorCode)
            isString(errorMsg) ? this.errors.push(errorMsg) : this.errors.push(err.error.message)
          }
        } else {
          this.errors.push(this.translate.instant('ERRORS.COMMON_MESSAGE'));
        }
      } else {
        isString(err) ? this.errors.push(err) : this.errors.push(this.translate.instant('ERRORS.COMMON_MESSAGE'));
      }
    } else {
      this.errors.push(this.translate.instant('ERRORS.COMMON_MESSAGE'));
    }
    this.notification = this.notificationService.show({
      content: this.notificationTemplate,
      position: { horizontal: "right", vertical: "top" },
      animation: { type: "fade", duration: duration },
      closable: true,
      hideAfter: this.hideAfter,
      width: 350,
      type: { style: this.type, icon: false }
    });
  }

  refresh(){
    this.refreshPage.emit()
    this.notification.hide();
  }

  ngOnInit() {
  }

}
