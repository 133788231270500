import { Injectable, ViewChild, ViewContainerRef, ComponentRef, ComponentFactoryResolver, Directive } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpService } from 'common-lib';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class AppService {
  public flagReminder: boolean = false;
  data: any;
  @ViewChild('target', { read: ViewContainerRef }) vcRef: ViewContainerRef;

  // (HA)PMB-7682 Trace Screen UI Improvement
  traceSubject = new Subject();
  componentRef: ComponentRef<any>;
  public headers: HttpHeaders;
  public hideSideNav: boolean = false;   //(PM)-PMB-8462 Allow Buyers to edit/Update Load information from Order screen
  
  public makeBgDisabled: boolean = false;   // (HA)PMB-7682 Quick Order Search in Buyer and Supplier/Vendor Applications

  constructor(private $http: HttpService, private http: HttpClient, private resolver: ComponentFactoryResolver) {
    this.headers = new HttpHeaders();
    this.headers.set('Content-Type', 'application/json');
  }
  createComponent() {
    const factory = this.resolver.resolveComponentFactory(this.data.component);
    this.componentRef = this.vcRef.createComponent(factory);
    let instance = this.componentRef.instance;
    instance.modalData = this.data.dataDetails;
  }
  getMethod(param) {
    this.http.get(param).pipe(
      map((res) => {
        // this.createComponent()
        return res;
      })
    );
  }
  putMethod(...param) {
    this.http.put(param[0], param[1], { headers: this.headers,observe:'response'}).pipe(
      map((res) => {
        // this.createComponent()
        return res;
      })
    );
  }
  postMethod(...param) {
    this.http.post(param[0], param[1], { headers: this.headers,observe:'response'}).pipe(
      map((res) => {
        // this.createComponent()
        return res;
      })
    );
  }
  patchMethod(...param) {
    this.http.patch(param[0], param[1], { headers: this.headers,observe:'response' }).pipe(
      map((res) => {
        // this.createComponent()
        return res;
      })
    );
  }
}
