/***********************************************************************
* DESCRIPTION :
* Quick Order Search in Buyer and Supplier/Vendor Applications
* NOTES :
* AUTHOR :    Hitesh Ahire       CREATE DATE :    26 March 2024
* CHANGES : Added new component
* REF NO          VERSION   DATE         WHO             DETAIL
* PMB-8552        2.6.0     26/03/2024   Hitesh Ahire    Quick Order Search in Buyer and Supplier/Vendor Applications
************************************************************************/

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'filter-card',
  templateUrl: './filter-card.component.html',
  styleUrls: ['./filter-card.component.scss']
})
export class FilterCardComponent implements OnInit, OnChanges {

  @Input() filterData;
  @Input() selectedList = [];

  public filterList: any[] = []; //
  @Input() label;
  @Output() selectedValues: EventEmitter<any> = new EventEmitter<any>();
  public showAll: boolean = false;
  public checkedValues: any[] = []; // Array to store checked values

  public isHover: boolean = false;
  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterData) {
      this.filterList = []; // Clear the existing filterList and selected values
      this.checkedValues = [];
      this.filterData.forEach(data => {
        if (this.selectedList.length == 0) {
          data.isChecked = false;
        } else {
          data.isChecked = this.selectedList.some(item => item.name == data.name);
          if (data.isChecked) {
            this.checkedValues.push(data);
          }
        }
        this.filterList.push(data);
      });
      this.filterData = this.filterData.slice(0, 5);
    }

    //reset flag values
    this.showAll = false;
    // this.isHover = false;
  }

  public selectedFilter(data: any, propertyName: string) {
    // Update the checked state of the checkbox
    data.isChecked = !data.isChecked;
    // If checked, add the value to checkedValues array, else remove it
    if (data.isChecked) {
      this.checkedValues.push(data); // You can push any specific value you need
    } else {
      const index = this.checkedValues.findIndex(item => item === data);
      if (index !== -1) {
        this.checkedValues.splice(index, 1);
      }
    }
    this.selectedValues.emit({
      propertyName: propertyName,
      filters: this.checkedValues.map(data => data.name)
    });
  }


  toggleViewMore(): void {
    this.showAll = !this.showAll;
    this.getViewMoreData();
  }

  getViewMoreData() {
    // If showAll is true, display all filterData; otherwise, display only the first 5 items
    this.filterData = this.showAll ? this.filterList : this.filterList.slice(0, 5);
  }

  onMouseEnter() {
    if (this.filterList.length > 10)
      this.isHover = true;
  }

  onMouseLeave() {
    this.isHover = false;
  }
}

