<div class="overlay-header">
    <div mat-dialog-title>
        <ng-content select="[header]">
        </ng-content>
    </div>
    <div mat-dialog-title>
        <ng-content select="[close]">
        </ng-content>
    </div>
</div>
<!-- <div class="overlay-body">
    <ng-content select="[custom-body]">
    </ng-content>
</div> -->
<div class="overlay-body">
    <div class="dialog-content">
        <div mat-dialog-content>
            <ng-content select="[custom-body]">
            </ng-content>
        </div>

    </div>

</div>
<div *ngIf="showAction" mat-dialog-actions class="overlay-footer">
    <div [ngClass]="{'left-section': showNote}" class="left-setion-no-bg">
        <ng-content select="[leftFooter]">
        </ng-content>
    </div>
    <div class="right-section">
        <ng-content select="[rightFooter]">
        </ng-content>
    </div>
</div>