<content-top-toolbar [hidden]="hidden"  [isNewBreadcrumb]="isNewBreadcrumb">
  <div breadcrumb>
    <div class="pageInfo">
      <!-- <div style="width: 100%;padding: 0px;" *ngIf="!isNewBreadcrumb">{{ pageTitle }}</div> -->
      <div class="left-section">
        <div class="baselineAlignment">
          <h2 class="pageTitle" *ngIf="!isNewBreadcrumb">{{this.pageTitle}}</h2>
        </div>
        <ol id="breadcrumbs-one" class="breadcrumb">
          <!-- <li>
            <a class="url-simple-text" routerLink="/dashboard">Dashboard</a>
          </li> -->
          <li *ngFor="let breadcrumb of breadcrumbs; let i = index;" [ngClass]="(breadcrumbs.lenght==i)?'active':''">
            <!-- <a [hidden]="i == (breadcrumbs.length - 1)">{{breadcrumb.name}}</a> -->
            <a class="link" (click)="seeClickedurl(breadcrumb.url)">
              {{breadcrumb.name | translate}}
            </a>
          </li>
        </ol>
      </div>
      <div>
        <ng-content select="[filters]"></ng-content>
      </div>
    </div>

  </div>

</content-top-toolbar>