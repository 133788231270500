import { Injectable } from "@angular/core";
import { OneAuthService } from "one-auth";
import { Router } from "@angular/router";


@Injectable({
    providedIn: 'root'
})
export class InitLoaderService {
    constructor(private auth: OneAuthService, private route: Router) {

    }
    load() {
        this.auth.LoadInitInfo();
    }
}