import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from '@procurant/env/environment';
import { LoadingService } from '@procurant/sharedcomponent/services/loading.service';
import { GridModule } from '@progress/kendo-angular-grid';
import { CommonLibModule, TRANSLATION_PROVIDERS } from 'common-lib';
// import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { FileSaverModule } from 'ngx-filesaver';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { OneAuthModule, OneAuthService, OneNotifyService } from 'one-auth';
import { AppComponent } from './app.component';
import { CoreRoutingModule } from './core-routing.module';
import { DragDirective } from './dragDrop.directive';
import { AppToolbarsModule } from './modules/app-toolbars/app-toolbars.module';
import { DevLoginModule } from './modules/dev-login/dev-login.module';
import { MaterialUIModule } from './modules/material-ui/material-ui.module';
import { AddItemComponent } from './modules/purchase-order/components/add-item-overlay/add-item.component';
import { CallChargesOverlayComponent } from './modules/purchase-order/components/charges-overlay/call-charges-overlay.component';
import { MoveLineOverlayComponent } from './modules/purchase-order/components/move-line-overlay/move-line-overlay.component';
import { ProductEditComponent } from './modules/purchase-order/components/product-edit-overlay/product-edit.component';
import { AuthGuard } from './modules/shared-components/services/auth.guard';
import { SharedModule } from './modules/shared-components/shared-components.module';
import { HttpRequestInterceptor } from './services/http.interceptor';
import { InitLoaderService } from './services/initloader.service';
import { CanDeactivateGuard, RedirectGuard } from './services/redirect-guard';
import { StorageModule } from '@ngx-pwa/local-storage';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';

@NgModule({
    declarations: [
        AppComponent,
        ProductEditComponent,
        MoveLineOverlayComponent,
        CallChargesOverlayComponent,
        AddItemComponent,
        DragDirective,
    ],
    imports: [
        BrowserModule,
        MatNativeDateModule,
        CommonLibModule.forRoot(environment),
        BrowserAnimationsModule,
        HttpClientModule,
        SharedModule,
        InfiniteScrollModule,
        // Ng4LoadingSpinnerModule.forRoot(),
        MaterialUIModule,
        CoreRoutingModule,
        AppToolbarsModule,
        RouterModule,
        GridModule,
        InputsModule,
        ButtonsModule,
        DevLoginModule,
        FileSaverModule,
        OneAuthModule.forRoot(environment),
        StorageModule.forRoot({ IDBNoWrap: false }),
        TooltipsModule
    ],
    exports: [FileSaverModule],
    providers: [CurrencyPipe, DecimalPipe, AuthGuard, TRANSLATION_PROVIDERS,
        // Location,
        OneAuthService,
        OneNotifyService,
        InitLoaderService,
        HttpRequestInterceptor,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true
            }
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        },
        RedirectGuard,
        LoadingService,
        CanDeactivateGuard
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }