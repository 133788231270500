import { Directive, Input, TemplateRef, ElementRef, OnInit, HostListener, ComponentRef, OnDestroy, ViewContainerRef } from '@angular/core';
import { Overlay, ConnectionPositionPair, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal'
import { ProcurantTooltipComponent } from '../components/procurant-tooltip/procurant-tooltip.component';
import { ProcurantPopoverComponent } from '../components/procurant-popover/procurant-popover.component';
import { Subject, takeUntil } from 'rxjs';
import { MatTooltip } from '@angular/material/tooltip';
import { NgControl } from "@angular/forms";

@Directive({
  selector: '[procurantToolTip]'
})
export class ToolTipRendererDirective {

  /**
  * This will be used to show tooltip or not
  * This can be used to show the tooltip conditionally
  */
  @Input() showToolTip: boolean = true;

  //If this is specified then specified text will be showin in the tooltip
  @Input(`procurantToolTip`) text: string;

  //If this is specified then specified template will be rendered in the tooltip
  @Input() contentTemplate: TemplateRef<any>;

  private _overlayRef: OverlayRef;

  @Input() dataContent: any = null;
 

  @Input() placement: string = "below";

  constructor(private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef) { }

  /**
   * Init life cycle event handler
   */
  ngOnInit() {

    if (!this.showToolTip) {
      return;
    }

    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions([{
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetY: 5,
      }]);

    this._overlayRef = this._overlay.create({ positionStrategy });

  }

  /**
   * This method will be called whenever mouse enters in the Host element
   * i.e. where this directive is applied
   * This method will show the tooltip by instantiating the McToolTipComponent and attaching to the overlay
   */
  @HostListener('mouseenter')
  show() {

    //attach the component if it has not already attached to the overlay
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<ProcurantTooltipComponent> = this._overlayRef.attach(new ComponentPortal(ProcurantTooltipComponent));
      tooltipRef.instance.text = this.text;
      tooltipRef.instance.contentTemplate = this.contentTemplate;
      tooltipRef.instance.dataContent = this.dataContent;
      tooltipRef.instance.placement = this.placement;
    }
  }

  /**
   * This method will be called when mouse goes out of the host element
   * i.e. where this directive is applied
   * This method will close the tooltip by detaching the overlay from the view
   */
  @HostListener('mouseleave')
  hide() {
    this.closeToolTip();
  }

  /**
   * Destroy lifecycle event handler
   * This method will make sure to close the tooltip
   * It will be needed in case when app is navigating to different page
   * and user is still seeing the tooltip; In that case we do not want to hang around the
   * tooltip after the page [on which tooltip visible] is destroyed
   */
  ngOnDestroy() {
    this.closeToolTip();
  }

  /**
   * This method will close the tooltip by detaching the component from the overlay
   */
  private closeToolTip() {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }

  
}

@Directive({
  selector: '[popoverTrigger]'
})
export class PopoverRendererDirective {

  @Input()
  popoverTrigger!: TemplateRef<object>;

  @Input()
  closeOnClickOutside: boolean = false;

  private unsubscribe = new Subject<void>();
  private overlayRef!: OverlayRef;


    constructor(
      private elementRef: ElementRef,
      private overlay: Overlay,
      private vcr: ViewContainerRef,
      private _elementRef: ElementRef,
      private _overlayPositionBuilder: OverlayPositionBuilder,
    ) {}

  /**
   * Init life cycle event handler
   */
  ngOnInit() {

    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions([{
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetY: 5,
      }]);

    //this.overlay = this.overlay.create({ positionStrategy });
    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: ""
    });

    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        if (this.closeOnClickOutside) {
          this.detachOverlay();
        }
      });

  }

  ngOnDestroy(): void {
    this.detachOverlay();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  @HostListener("click") clickou() {
    this.attachOverlay();
  }

  private attachOverlay(): void {
    if (!this.overlayRef.hasAttached()) {
      const periodSelectorPortal = new TemplatePortal(
        this.popoverTrigger,
        this.vcr
      );

      this.overlayRef.attach(periodSelectorPortal);
    }
  }

  private detachOverlay(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }  
}


@Directive({
  selector: "[matTooltip][appTooltipIfTruncated]"
})
export class TooltipIfTruncatedDirective implements OnInit {
  constructor(private matTooltip: MatTooltip, private elementRef: ElementRef<HTMLElement>) {}

  public ngOnInit(): void {
    // Wait for DOM update
    setTimeout(() => {
      console.log("tooltip dir", this.elementRef.nativeElement);
      const element = this.elementRef.nativeElement;
      console.log(
        "tooltip dir. scroll: ",
        element.scrollWidth,
        "\t client: ",
        element.clientWidth,
        "result: ",
        element.scrollWidth <= element.clientWidth
      );
      this.matTooltip.disabled = element.scrollWidth <= element.clientWidth;
    });
  }
}

@Directive({
  selector: "[appYearSelector]"
})
export class YearSelectorDirective {
  @HostListener("blur", ["$event"]) onBlur() {
    this.ngControl.control.setValue(new Date());
    // this.cdr.detectChanges();
  }

  constructor(private ngControl: NgControl) {}
}