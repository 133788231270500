import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChargesService } from '../../../services/charges.service';
import { ChargesOverlayListComponent } from '../charges-overlay-list/charges-overlay-list.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PO_MODFLAG, constant, SUBSCRIBED_APPS, MODULE_CODE, FEATURE_CODE } from 'common-lib';
import { OneNotifyService } from 'one-auth';

@Component({
  selector: 'edit-charges-overlay',
  templateUrl: './edit-charges-overlay.component.html',
  styleUrls: ['./edit-charges-overlay.component.scss']
})
export class EditChargesOverlayComponent implements OnInit {


  @Input() order: any;
  chargePRORATE_BY: any[];
  chargeAPPLY_BY: any[];
  @Input() data: any;;
  isShowProrateToLine = true;
  @Input() parent: FormGroup;
  @Input() index: any;
  editForm: FormGroup;
  orderData: any;
  page: any;
  isStatic: boolean = false;
  //(PM)PMB-7631 Add ability to default charge apply by and prorate by settings for a buying company
  applyAs: string
  appModuleAccess: any = {
    applyBy: false,
    prorateBy: false
  };
  permissions: boolean = true;
  isHideAddCharges: boolean;
  isPermissionFetched: boolean = false;
  applyByCode = 'FLAT';

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData, private fb: FormBuilder, private cdr: ChangeDetectorRef, private service: ChargesService, private chargesOverlayList: ChargesOverlayListComponent, private notify: OneNotifyService) { }

  ngOnInit(): void {
    this.isShowProrateToLine = this.data[1];
    this.orderData = this.parent.get('editChargesArray')['controls'][this.index];
    const decimalPatern = '^[0-9]+(\.[0-9]{1,4})?$';
    let name = this.orderData.value.chargeName + ' | ' + this.orderData.value.chargeTypeCode + ((this.orderData.value.freight == true) ? ' - Freight' : '');
    this.editForm = this.fb.group({
      id: [this.orderData.value.id],
      poId: [this.orderData.value.poId],
      poLineId: [this.orderData.value.poLineId],
      chargeId: [this.orderData.value.chargeId],
      chargeName: [this.orderData.value.chargeName],
      applyOnModuleCode: [this.orderData.value.applyOnModuleCode],
      displayToAppCode: [this.orderData.value.displayToAppCode],
      companyName: [this.orderData.value.companyName],
      displayToSupplier: [this.orderData.value.displayToSupplier],
      name: [name, Validators.compose([Validators.required])],
      chargeAmt: [this.orderData.value.chargeAmt, Validators.compose([Validators.pattern(decimalPatern), Validators.max(99999.9999), Validators.min(0), Validators.required])],
      chargeTypeId: [this.orderData.value.chargeTypeId],
      chargeTypeCode: [this.orderData.value.chargeTypeCode],
      chargeCode: [this.orderData.value.chargeCode],
      applyByCode: [this.orderData.value.applyByCode, Validators.compose([Validators.required])],
      inclProdCost: [this.orderData.value.inclProdCost],
      prorateById: [this.orderData.value.prorateById],
      prorateByCode: [this.orderData.value.prorateByCode, this.isShowProrateToLine ? Validators.compose([Validators.required]) : null],
      addedBy: [this.orderData.value.addedBy],
      freight: [this.orderData.value.freight]
    });
    this.getChargeSetting();
  }

  // getChargeSetting() {
  //   this.service.getChargeSetting("APPLY_BY").subscribe(resp => {
  //     this.chargeAPPLY_BY = resp;
  //   })

  //   this.service.getChargeSetting("PRORATE_BY").subscribe(resp => {
  //     this.chargePRORATE_BY = resp;
  //   })
  // }

  getChargeSetting() {
    this.service.getChargeSetting("APPLY_BY").subscribe(resp => {
      this.chargeAPPLY_BY = resp;
      this.service.getChargeSetting("PRORATE_BY").subscribe(resp1 => {
        this.chargePRORATE_BY = resp1;
        if (this.data[3] == 'BUYER') {
          this.permissions = this.data[4];
          this.getAppModuleAccess();
        }
        else {
          this.isHideAddCharges = this.data[4];
          //(OL) PMB-7713 issue fix for setting defaukt to FLAT
          this.isPermissionFetched = true;
        }
      })
    })
  }

  changeValue() {
    this.chargesOverlayList.editFlag = false;
    this.chargesOverlayList.changeValue(this.editForm.value, this.index);
  }

  //(PM)PMB-7631 Add ability to default charge apply by and prorate by settings for a buying company
  getAppModuleAccess() {
    let arrObj = [];
    arrObj.push(constant.getAppModuleAccessObj(SUBSCRIBED_APPS.BUYER, MODULE_CODE.ORDER_MANAGEMENT, FEATURE_CODE.DEFAULT_CHARGE_TO_APPLY_AS_PRORATE_BY));
    let obj = {
      brRulesRequest: arrObj
    }
    this.service.getAppModuleAccess(obj).subscribe(resp => {

      let applyBy = resp.find(t => t.featureCode == FEATURE_CODE.DEFAULT_CHARGE_TO_APPLY_AS_PRORATE_BY);
      this.appModuleAccess.applyBy = applyBy ? applyBy.accessCode == 'GRANTED' : false;
      this.appModuleAccess.prorateBy = applyBy ? applyBy.accessCode == 'GRANTED' : false;
      // const controlEdit = <FormArray>this.addchargesForm.controls['addChargesArray'];
      // const controlEdit1 = <FormGroup>controlEdit.controls[0]
      // const controlEdit1 = <FormGroup>this.editForm
      // if (this.appModuleAccess.applyBy) {
      //   this.applyAs = null;
      //   //controlEdit1.controls['applyByCode'].setValue(applyBy.attributeDept[0].departmentCode, '');
      //   let appy = applyBy.attributeDept.find(t => t.attributeCode == "APPLY_AS");
      //   if (appy) {
      //     controlEdit1.controls['applyByCode'].setValue(appy.departmentCode);
      //     this.applyByCode = appy.departmentCode;
      //   }
      //   this.chargeAPPLY_BY = this.chargeAPPLY_BY.filter(t => t.code == controlEdit1.controls['applyByCode'].value);
      // } else {
      //   //(OL) PMB-7713 issue fix for setting defaukt to FLAT
      //   //controlEdit1.controls['applyByCode'].setValue(this.applyByCode);
      // }
      // if (this.appModuleAccess.prorateBy) {
      //   let pro = applyBy.attributeDept.find(t => t.attributeCode == "PRORATE_BY");
      //   if (pro) {
      //     controlEdit1.controls['prorateByCode'].setValue(pro.departmentCode);
      //   }
      // } 
      this.isPermissionFetched = true;
    }, err => {
      this.notify.show(err, 'error');
      this.isPermissionFetched = true;
    })
  }

}
