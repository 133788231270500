import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'procurant-charges-overlay-container',
  templateUrl: './procurant-charges-overlay-container.component.html',
  styleUrls: ['./procurant-charges-overlay-container.component.scss']
})
export class ProcurantChargesOverlayContainerComponent implements OnInit {

  @Input() data: any;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  isEditChargeExist = 0;
  addChargesFlag: any = false;
  title: any;


  constructor(public dialogRef: MatDialogRef<ProcurantChargesOverlayContainerComponent>, @Inject(MAT_DIALOG_DATA) public dialogData, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.data[0][0].chargeName)
      this.isEditChargeExist = this.data[0].length;
    this.title = "CHARGE.LABEL.CHARGES";
  }

  changeHeader() {
    if (this.addChargesFlag)
      this.title = "CHARGE.LABEL.CHARGES";
    else
      this.title = "CHARGE.LABEL.CHARGE_LIST";
    this.cdr.detectChanges();
  }

  closeDialog(isSubmitted = false) {
    this.closeEvent.emit(isSubmitted);
  }

  submit() {
    this.submitEvent.emit(true);
  }

}
