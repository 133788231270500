<custome-overlay-design [showNote]="true">
  <div header><span>{{'Add Line Item(s)' | translate}}</span></div>
  <div close>
    <a (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <div class="add-line-section" custom-body>
    <div class="addLineSection">
      <div class="head-title">
        <div class="addLineHeader">
          <div class="text">
            <span class="label">{{'DETAIL_PAGE.LABEL.ORDER_NO' | translate}}:</span>
            <span class="value vf-ellips"
              id="poNumber_addItem">{{orderDetailsForm.controls['poNumber'].value}}</span><!-- Add tooltip -->
          </div>
          <div class="text">
            <span class="lable routing-label">{{'DETAIL_PAGE.LABEL.ROUTING' | translate}}:</span>
            <span class="value" id="freightTerm_addItem">{{orderDetailsForm.controls['freightTerm'].value}}</span>
          </div>
        </div>

        <div class="addLineHeader">
          <div class="text">
            <span class="label">{{'DETAIL_PAGE.LABEL.DELIVER_TO'|translate}}:</span>
            <span class="value"
              id="deliverToLocName_addItem">{{orderDetailsForm.controls['deliverToLocName'].value}}</span>
          </div>
          <div class="text">
            <span class="lable supplier-lable">{{'DETAIL_PAGE.LABEL.SUPPLIER' | translate}}:</span>
            <span class="value"
              id="sellerCompanyName_addItem">{{orderDetailsForm.controls['sellerCompanyName'].value}}</span>
          </div>
        </div>
      </div>
      <form [formGroup]="orderDetailsForm" class="table-body">
        <div class="add-item">
          <button mat-button (click)="addItem()">+ Add Item</button>
        </div>
        <!-- <table formArrayName="poLines">
          <thead>
            <tr>
              <th><span>Sr No.</span></th>
              <th><span>Product Code</span></th>
              <th><span>Description</span></th>
              <th><span class="tie-high">Tie*High</span></th>
              <th><span>Qty</span></th>
              <th><span>Unit Cost</span></th>
              <th *ngIf="isFinalCost"><span>Final Cost</span></th>
              <th><span>Total Cost</span></th>
              <th *ngIf="orderDetailsForm.get('poLines')['controls'].length > 1"><span></span></th>
            </tr>
          </thead>
          <tbody>
            <tr [formGroupName]="index"
              *ngFor="let row of orderDetailsForm.get('poLines')['controls']; let index = index">
              <td><span>{{1+index}}</span></td>
              <td><span [ngClass]="{'on-focuse-input': isProductCodeInputFocuse == index }" class="product-code">
                  <mat-form-field appearance="outline">
                    <input id="sku_addItem{{index}}" matInput
                      placeholder="{{'DETAIL_PAGE.LABEL.PRODUCT_CODE'|translate}}" maxlength="64" formControlName='sku'
                      [matAutocomplete]="auto"
                      (focusout)="getProductsDetailsFocusout($event,index,row.controls['sku'].value)"
                      (input)="suggestionProducCode(index,row.controls['sku'].value)"
                      (focus)="onFocusEvent($event, index)">

                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                      (optionSelected)="getProductsDetailsSelection(index,row.controls['sku'].value)">
                      <ng-container *ngIf="suggestioinProductCodeList && suggestioinProductCodeList.length > 0">
                        <mat-option *ngFor="let code of suggestioinProductCodeList" [value]="code.code">
                          <span>{{ code.code }}</span>
                          <small> | {{code.description}} {{code.additionalAttributes}}</small>
                        </mat-option>
                      </ng-container>
                    </mat-autocomplete>
                    <mat-error
                      *ngIf="row.controls.sku.touched && row.controls.sku.invalid && !row.controls['sku'].hasError('pattern') && !row.controls['sku'].hasError('invalidProductNumber')  && !row.controls['sku'].hasError('isDuplicate')">
                      {{ 'DETAIL_PAGE.MESSAGE.SKU_ERROR' | translate }}
                    </mat-error>

                    <mat-error
                      *ngIf="row.controls['sku'].hasError('pattern') && row.controls.sku.touched && !row.controls['sku'].hasError('invalidProductNumber')  && !row.controls['sku'].hasError('isDuplicate')">
                      {{ 'DETAIL_PAGE.MESSAGE.SKU_ERROR_VALID' | translate }}
                    </mat-error>
                    <mat-error
                      *ngIf="row.controls['sku'].hasError('invalidProductNumber') && !row.controls['sku'].hasError('isDuplicate')">
                      {{ 'DETAIL_PAGE.MESSAGE.SKU_ERROR_VALID' | translate }}
                    </mat-error>
                    <mat-error *ngIf="row.controls['sku'].hasError('isDuplicate')">
                      {{ 'MESSAGE.PRODUCT_REPEATED' | translate }}
                    </mat-error>
                    <mat-error id="WarehouseAssociationError{{index}}"
                      *ngIf="row.controls['warehouseAssociation'].value">
                      {{ 'MESSAGE.PRODUCT_ASSOCIATED_ERROR' | translate }}
                    </mat-error>
                  </mat-form-field>
                </span>
              </td>
              <td class="description"> <span> <input id="productDesc_addItem{{index}}" matInput
                    matTooltip="{{row.controls.productDesc.value}}" readonly formControlName="productDesc"></span>
              </td>
              <td><span
                  *ngIf="row.controls.ti.value && row.controls.ti.value != 0 && row.controls.hi.value && row.controls.hi.value != 0">
                  {{row.controls.ti.value*row.controls.hi.value}}({{row.controls.ti.value}}*{{row.controls.hi.value}})</span>
              </td>
              <td class="qty"><span>
                  <samp *ngIf="row.controls['qty'].value.length" class="greyCol doller-padding">{{
                    'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}</samp>
                  <mat-form-field appearance="outline" class="values testRight">
                    <input id="qty_addItem{{index}}" matInput placeholder="{{'Qty'|translate}}" maxlength="6"
                      formControlName="qty" (keydown)="moveHeaderCell($event,'qty_addItem',index)"
                      (keydown.Tab)="addPOLine(index,$event)" autocomplete="off" digitOnly>
                    <mat-error
                      *ngIf="row.controls.qty.touched && row.controls.qty.invalid && !row.controls['qty'].hasError('pattern') && !row.controls['qty'].hasError('isZero')">
                      {{ 'DETAIL_PAGE.MESSAGE.QUANTITY_ERROR' | translate }}
                    </mat-error>
                    <mat-error *ngIf="row.controls.qty.touched && row.controls['qty'].hasError('pattern')">
                      {{ 'DETAIL_PAGE.MESSAGE.QUANTITY_ERROR_VALID' | translate }}
                    </mat-error>
                    <mat-error *ngIf="row.controls.qty.touched && row.controls['qty'].hasError('isZero')">
                      {{ 'DETAIL_PAGE.MESSAGE.QUANTITY_ZERO_ERROR' | translate }}
                    </mat-error>
                  </mat-form-field>
                </span></td>
              <td class="cost"><span>
                  <samp *ngIf="row.controls['unitPrice'].value.length" class="greyCol doller-padding">{{
                    'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}</samp>
                  <mat-form-field appearance="outline" class="values testRight">
                    <input id="unitPrice_addItem{{index}}" matInput maxlength="10" (input)="markManualEdit(row)"
                      placeholder="Unit Cost" (keydown)="moveHeaderCell($event,'unitPrice_addItem',index)"
                      formControlName="unitPrice" autocomplete="off" step="2" twodecimalPlace>
                    <mat-error class="table-mat-error"
                      *ngIf="row.controls.unitPrice.touched && row.controls.unitPrice.invalid && !row.controls['unitPrice'].hasError('pattern') && !row.controls['unitPrice'].hasError('min') && !row.controls['unitPrice'].hasError('max')">
                      {{ 'DETAIL_PAGE.MESSAGE.PRICE_ERROR' | translate }}
                    </mat-error>
                    <mat-error class="table-mat-error"
                      *ngIf="row.controls.unitPrice.touched && row.controls['unitPrice'].hasError('pattern') ">
                      {{ 'DETAIL_PAGE.MESSAGE.PRICE_TWO_DECIMAL' | translate }}
                    </mat-error>
                    <mat-error class="table-mat-error"
                      *ngIf="row.controls.unitPrice.touched && row.controls['unitPrice'].hasError('min')">
                      {{ 'DETAIL_PAGE.MESSAGE.PRICE_ZERO_ERROR' | translate }}
                    </mat-error>
                    <mat-error class="table-mat-error"
                      *ngIf="row.controls.unitPrice.touched && row.controls['unitPrice'].hasError('max')">
                      {{ 'DETAIL_PAGE.MESSAGE.PRICE_BETWEEN_ERROR' | translate }}
                    </mat-error>
                  </mat-form-field>
                </span></td>
              <td class="cost" *ngIf="isFinalCost"><span>
                  <samp *ngIf="row.controls['finalUnitCost'].value.length" class="greyCol doller-padding">{{
                    'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}</samp>
                  <mat-form-field appearance="outline" class="values testRight">
                    <input id="finalUnitCost_addItem{{index}}" matInput placeholder="Final Cost" maxlength="7"
                      formControlName="finalUnitCost" (keydown)="moveHeaderCell($event,'finalUnitCost_addItem',index)"
                      twodecimalPlace>
                    <mat-error
                      *ngIf="row.controls['finalUnitCost'].touched && row.controls['finalUnitCost'].hasError('pattern')">
                      {{ 'MESSAGE.FINAL_COST_TWO_DECLIMAL_ERROR' | translate }}
                    </mat-error>
                    <mat-error
                      *ngIf="row.controls['finalUnitCost'].touched && row.controls['finalUnitCost'].hasError('max')">
                      {{ 'MESSAGE.FINAL_COST_MAX_ERROR' | translate }}
                    </mat-error>
                  </mat-form-field>
                </span></td>
              <td class="">
                <span>
                  <input class="testRight greyCol" id="tFormated_additem{{index}}" matInput readonly
                    formControlName="tFormated" twodecimalPlace>
                </span>
              </td>
              <td [hidden]="orderDetailsForm.controls.poLines.controls.length <= 1">
                <button id="removePo_addItem{{index}}" mat-button (click)="removePOLine(index)" class="btn-icon">
                  <div class="icon-container">
                    <div class="icon-image">
                    </div>
                  </div>
                </button>

              </td>
            </tr>
          </tbody>
          <tr class="table-bottom">
            <td></td>
            <td>{{ 'DETAIL_PAGE.LABEL.TOTAL' | translate }}</td>
            <td class="pallet-line">
              <div><small class="greyCol"> {{'DETAIL_PAGE.LABEL.PALLETS' | translate}} :</small>{{pallets |
                number:'1.0-4'}}</div>
              <div><small class="greyCol">{{ 'LABEL.LINES' | translate }} : </small> {{ totalLines}} </div>
            </td>
            <td></td>
            <td id="totalQuantity_additem" class="testRight leftRightPadding"> {{ totalQuantity}} </td>

            <td id="totalPrice_additem" class="testRight leftRightPadding"> <small
                class="greyCol">{{'TABLE_DISPLAY_COLUMNS.SYMBOL' |
                pipes:'currancySymbol'}}</small>{{ totalColumnPrice
              |
              pipes:'twoDecimal'}}
            </td>
            <td *ngIf="isFinalCost" id="totalFinalUnitCost_additem" class="testRight leftRightPadding"><small
                class="greyCol">{{
                'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}</small>{{ totalColumnFinalPrice |
              pipes:'twoDecimal'}}
            </td>
            <td id="columnTotal_additem" class="testRight leftRightPadding"><small class="greyCol">{{
                'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}</small>{{ columnTotalDisplay |
              pipes:'twoDecimal'}}

            <td *ngIf="orderDetailsForm.get('poLines')['controls'].length > 1"></td>
          </tr>
        </table> -->
        <div class="table-scroll-add-line">
          <table formArrayName="poLines">
            <thead>
              <tr>
                <th width="6%"><span>{{ 'DETAIL_PAGE.LABEL.SR_NO' | translate }}</span></th>
                <th width="15%"><span>{{ 'DETAIL_PAGE.LABEL.PRODUCT_CODE' | translate }}</span></th>
                <th colspan="2" width="30%"><span>{{ 'DETAIL_PAGE.LABEL.DESCRIPTION' | translate }}</span></th>
                <th width="5%"><span>{{ 'LABEL.TIE_HIGH' | translate }}</span></th>
                <th width="10%"><span>{{ 'LABEL.QUANTITY' | translate }}</span></th>
                <th width="12%"><span>{{ 'LABEL.UNTI_COST' | translate }}</span></th>
                <th width="12%" *ngIf="isFinalCost"><span>{{ 'DETAIL_PAGE.LABEL.FINAL_COST' | translate }}</span></th>
                <th width="12%"><span>{{ 'DETAIL_PAGE.LABEL.TOTAL_COST' | translate }}</span></th>
                <th width="2%" *ngIf="orderDetailsForm.get('poLines')['controls'].length > 1"><span></span></th>

              </tr>
            </thead>
            <tbody class="scrollTable">
              <tr [formGroupName]="index"
                *ngFor="let row of orderDetailsForm.get('poLines')['controls']; let index = index">
                <td><span class="textLeft">{{1+index}}</span></td>
                <td [ngClass]="{'on-focuse-input': isProductCodeInputFocuse == index }">
                  <span class="focuseInput textLeft" [ngClass]="{'product-code': isProductCodeInputFocuse != index}">
                    <span class="field-err-section">
                      <span>
                        <mat-form-field appearance="outline">
                          <input id="sku_addItem{{index}}" matInput
                            placeholder="{{'DETAIL_PAGE.LABEL.PRODUCT_CODE'|translate}}" maxlength="64"
                            formControlName='sku' [matAutocomplete]="auto"
                            (focusout)="getProductsDetailsFocusout($event,index,row.controls['sku'].value)"
                            (input)="suggestionProducCode(index,row.controls['sku'].value)"
                            (focus)="focusInEvent('isProductCodeInputFocuse', index)">

                          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                            (optionSelected)="getProductsDetailsSelection(index,row.controls['sku'].value)">
                            <ng-container *ngIf="suggestioinProductCodeList && suggestioinProductCodeList.length > 0">
                              <mat-option *ngFor="let code of suggestioinProductCodeList" [value]="code.code">
                                <span>{{ code.code }}</span>
                                <small> | {{code.description}} {{code.additionalAttributes}}</small>
                              </mat-option>
                            </ng-container>
                          </mat-autocomplete>
                        </mat-form-field>
                      </span>
                      <span class="error-text">
                        <mat-error
                          *ngIf="row.controls.sku.touched && row.controls.sku.invalid && !row.controls['sku'].hasError('pattern') && !row.controls['sku'].hasError('invalidProductNumber')  && !row.controls['sku'].hasError('isDuplicate')">
                          {{ 'DETAIL_PAGE.MESSAGE.SKU_ERROR' | translate }}
                        </mat-error>

                        <mat-error
                          *ngIf="row.controls['sku'].hasError('pattern') && row.controls.sku.touched && !row.controls['sku'].hasError('invalidProductNumber')  && !row.controls['sku'].hasError('isDuplicate')">
                          {{ 'DETAIL_PAGE.MESSAGE.SKU_ERROR_VALID' | translate }}
                        </mat-error>
                        <mat-error
                          *ngIf="row.controls['sku'].hasError('invalidProductNumber') && !row.controls['sku'].hasError('isDuplicate')">
                          {{ 'DETAIL_PAGE.MESSAGE.SKU_ERROR_VALID' | translate }}
                        </mat-error>
                        <mat-error *ngIf="row.controls['sku'].hasError('isDuplicate')">
                          {{ 'MESSAGE.PRODUCT_REPEATED' | translate }}
                        </mat-error>
                        <mat-error id="WarehouseAssociationError{{index}}"
                          *ngIf="row.controls['warehouseAssociation'].value">
                          {{ 'MESSAGE.PRODUCT_ASSOCIATED_ERROR' | translate }}
                        </mat-error>
                      </span>
                    </span>
                  </span>
                </td>
                <td colspan="2" class="description">
                  <span>
                    <input id="productDesc_addItem{{index}}" matInput position="bottom" kendoTooltip
                      title="{{row.controls.productDesc.value}}" readonly formControlName="productDesc">
                  </span>
                </td>
                <td>
                  <span id="productTiHigh_addItem{{index}}"
                    *ngIf="row.controls.ti.value && row.controls.ti.value != 0 && row.controls.hi.value && row.controls.hi.value != 0">
                    {{row.controls.ti.value*row.controls.hi.value}}({{row.controls.ti.value}}*{{row.controls.hi.value}})</span>
                </td>
                <td [ngClass]="{'on-focuse-input': isQtyInputFocuse == index }">
                  <span class="field-err-section number-field cost-field">
                    <div class="number-field">
                      <mat-form-field appearance="outline" class="number">
                        <input id="qty_addItem{{index}}" matInput placeholder="{{'LABEL.QUANTITY'|translate}}"
                          maxlength="6" formControlName="qty" (keydown)="moveHeaderCell($event,'qty_addItem',index)"
                          (keydown.Tab)="addPOLine(index,$event)" autocomplete="off"
                          (focus)="focusInEvent('isQtyInputFocuse', index)" (focusout)="focusOutEvent()"
                          mask="separator.0" thousandSeparator=",">
                      </mat-form-field>
                    </div>
                    <mat-error class="error-text text-right"
                      *ngIf="row.controls.qty.touched && row.controls.qty.invalid && !row.controls['qty'].hasError('pattern') && !row.controls['qty'].hasError('isZero')">
                      {{ 'DETAIL_PAGE.MESSAGE.QUANTITY_ERROR' | translate }}
                    </mat-error>
                    <mat-error class="error-text text-right"
                      *ngIf="row.controls.qty.touched && row.controls['qty'].hasError('pattern')">
                      {{ 'DETAIL_PAGE.MESSAGE.QUANTITY_ERROR_VALID' | translate }}
                    </mat-error>
                    <mat-error class="error-text text-right"
                      *ngIf="row.controls.qty.touched && row.controls['qty'].hasError('isZero')">
                      {{ 'DETAIL_PAGE.MESSAGE.QUANTITY_ZERO_ERROR' | translate }}
                    </mat-error>

                  </span>
                </td>
                <td [ngClass]="{'on-focuse-input': isUnitCostInputFocuse == index }">
                  <span class="field-err-section number-field cost-field">
                    <div class="number-field">
                      <mat-form-field appearance="outline" class="number">
                        <samp matPrefix *ngIf="row.controls.priceWarning.value" class="warning-icon"
                          procurantToolTip="{{'DETAIL_PAGE.MESSAGE.LINE_COST_WARNING' | translate}}">!</samp>
                        <input [ngClass]="{'warning-input-text': row.controls.priceWarning.value}"
                          id="unitPrice_addItem{{index}}" matInput maxlength="9" (input)="markManualEdit(row)"
                          placeholder="Unit Cost" (keydown)="moveHeaderCell($event,'unitPrice_addItem',index)"
                          formControlName="unitPrice" autocomplete="off"
                          (focus)="focusInEvent('isUnitCostInputFocuse', index)" (focusout)="focusOutEvent()"
                          prefix="{{ 'TABLE_DISPLAY_COLUMNS.SYMBOL'|  pipes:'currancySymbol'}}" mask="separator.2"
                          thousandSeparator="," separatorLimit="9999" decimalMarker=".">
                      </mat-form-field>
                    </div>

                    <mat-error class="error-text text-right"
                      *ngIf="row.controls.unitPrice.touched && row.controls.unitPrice.invalid && !row.controls['unitPrice'].hasError('pattern') && !row.controls['unitPrice'].hasError('min') && !row.controls['unitPrice'].hasError('max')">
                      {{ 'DETAIL_PAGE.MESSAGE.PRICE_ERROR' | translate }}
                    </mat-error>
                    <mat-error class="error-text text-right"
                      *ngIf="row.controls.unitPrice.touched && row.controls['unitPrice'].hasError('pattern') ">
                      {{ 'DETAIL_PAGE.MESSAGE.PRICE_TWO_DECIMAL' | translate }}
                    </mat-error>
                    <mat-error class="error-text text-right"
                      *ngIf="row.controls.unitPrice.touched && row.controls['unitPrice'].hasError('min')">
                      {{ 'DETAIL_PAGE.MESSAGE.PRICE_ZERO_ERROR' | translate }}
                    </mat-error>
                    <mat-error class="error-text text-right"
                      *ngIf="row.controls.unitPrice.touched && row.controls['unitPrice'].hasError('max')">
                      {{ 'DETAIL_PAGE.MESSAGE.PRICE_BETWEEN_ERROR' | translate }}
                    </mat-error>


                  </span>
                </td>
                <td *ngIf="isFinalCost" [ngClass]="{'on-focuse-input': isFinalCostInputFocuse == index }">
                  <span class="field-err-section number-field cost-field">
                    <div class="number-field">
                      <mat-form-field appearance="outline" class="number">
                        <input id="finalUnitCost_addItem{{index}}" matInput placeholder="Final Cost" maxlength="9"
                          formControlName="finalUnitCost"
                          (keydown)="moveHeaderCell($event,'finalUnitCost_addItem',index)"
                          (focus)="focusInEvent('isFinalCostInputFocuse', index)" (focusout)="focusOutEvent()" step="2"
                          autocomplete="off" prefix="{{ 'TABLE_DISPLAY_COLUMNS.SYMBOL'|  pipes:'currancySymbol'}}"
                          mask="separator.2" separatorLimit="9999" thousandSeparator="," decimalMarker=".">
                      </mat-form-field>
                    </div>
                    <mat-error class="error-text text-right"
                      *ngIf="row.controls['finalUnitCost'].touched && row.controls['finalUnitCost'].hasError('pattern')">
                      {{ 'MESSAGE.FINAL_COST_TWO_DECLIMAL_ERROR' | translate }}
                    </mat-error>
                    <mat-error class="error-text text-right"
                      *ngIf="row.controls['finalUnitCost'].touched && row.controls['finalUnitCost'].hasError('max')">
                      {{ 'MESSAGE.FINAL_COST_MAX_ERROR' | translate }}
                    </mat-error>
                  </span>
                </td>
                <td>
                  <span class="total-cost">
                    <!-- <input id="tFormated_additem{{index}}" matInput readonly formControlName="tFormated"
                      twodecimalPlace> -->
                    <small class="symbol">{{'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}</small>
                    <span id="add_item_totalColumnFinalPrice">{{ row.controls['tFormated'].value |
                      pipes:'twoDecimal'}}</span>

                  </span>
                </td>
                <td [hidden]="orderDetailsForm.controls.poLines.controls.length <= 1">
                  <button id="removePo_addItem{{index}}" mat-button (click)="removePOLine(index)" class="btn-icon">
                    <div class="icon-container">
                      <div class="icon-image">
                      </div>
                    </div>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="table-bottom">
                <td></td>
                <td class="footerText">
                  <span>{{ 'DETAIL_PAGE.LABEL.TOTAL' | translate }}</span>
                </td>
                <td class="footerText">
                  <span>
                    <small class="symbol"> {{'DETAIL_PAGE.LABEL.PALLETS' | translate}} :</small>&nbsp;
                    <span id="add_item_pallets">{{pallets | number:'1.0-4'}}</span>
                  </span>
                </td>
                <td class="footerText">
                  <span>
                    <small class="symbol">{{ 'LABEL.LINES' | translate }} : </small>&nbsp;
                    <span id="add_item_totalLines">{{ totalLines}}</span>
                  </span>
                </td>
                <td></td>
                <td class="leftRightPadding">
                  <span id="totalQuantity_additem">{{ totalQuantity}}</span>
                </td>
                <td id="totalPrice_additem" class="leftRightPadding">
                  <span>
                    <small class="symbol">{{'TABLE_DISPLAY_COLUMNS.SYMBOL' |pipes:'currancySymbol'}}</small>
                    <span id="add_item_totalColumnPrice">{{ totalColumnPrice | pipes:'twoDecimal'}}</span>
                  </span>
                </td>
                <td *ngIf="isFinalCost" id="totalFinalUnitCost_additem" class="leftRightPadding">
                  <span>
                    <small class="symbol">{{'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}</small>
                    <span id="add_item_totalColumnFinalPrice">{{ totalColumnFinalPrice | pipes:'twoDecimal'}}</span>
                  </span>
                </td>
                <td class="leftRightPadding">
                  <span>
                    <small class="symbol">{{'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}</small>
                    <span id="columnTotal_additem">{{ columnTotalDisplay |pipes:'twoDecimal'}}</span>
                  </span>
                <td *ngIf="orderDetailsForm.get('poLines')['controls'].length > 1"></td>
              </tr>
            </tfoot>
          </table>
        </div>

      </form>
    </div>
  </div>
  <ng-container leftFooter>
    <div class="note" [tooltipTemplate]="noteTemplate" filter=".note" position="right" kendoTooltip>
      <span class="info-icon"></span>
      <span class="text"> {{'DETAIL_PAGE.LABEL.NOTE'|translate}}</span>
    </div>
    <ng-template #noteTemplate let-anchor>
      <ul class="fotter-ul">
        <li>
          {{'DETAIL_PAGE.MESSAGE.ADDLINE_NOTE'|translate}}
        </li>
      </ul>

    </ng-template>
  </ng-container>
  <ng-container rightFooter>
    <button id="close_additem" class="procurant-link-btn"
      (click)="closeDialog()"><span>{{'BUTTON.CANCEL'|translate}}</span></button>
    <button id="submit_addItem" class="procurant-submit-btn" *ngIf="!auth?.user?.info?.internalAdmin"
      class="procurant-submit-btn"
      [disabled]="orderDetailsForm.invalid || !orderDetailsForm.dirty || disableSubmit || warehouseAssociation"
      (click)="submitOrder()"><span>{{'BUTTON.SUBMIT' |translate}}</span>
    </button>
  </ng-container>
</custome-overlay-design>


<!-- old code start -->
<!-- <form [formGroup]="orderDetailsForm">
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start top" class="mb-40">
        <div fxFlex="100" class="pageInfo withLightBg pdlr-20">

          <div fxLayout="row" fxLayoutAlign="start top" class="infoBlocks mb-10 mt-20">
            <div class="readOnlyField rBorder">
              <div class="fieldGroup horizontalGroup">
                <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.ORDER_NO' | translate}}:</span>
                <span id="poNumber_addItem" class="fieldValue">{{orderDetailsForm.controls['poNumber'].value}}</span>
              </div>
            </div>
            <div class="readOnlyField rBorder">
              <div class="fieldGroup horizontalGroup">
                <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.SUPPLIER' | translate}}:</span>
                <span id="sellerCompanyName_addItem"
                  class="fieldValue">{{orderDetailsForm.controls['sellerCompanyName'].value}}</span>
              </div>
            </div>
            <div class="readOnlyField rBorder">
              <div class="fieldGroup horizontalGroup">
                <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.ROUTING' | translate}}:</span>
                <span id="freightTerm_addItem"
                  class="fieldValue">{{orderDetailsForm.controls['freightTerm'].value}}</span>
              </div>
            </div>
            <div class="readOnlyField">
              <div class="fieldGroup horizontalGroup">
                <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.DELIVER_TO'|translate}}:</span>
                <span id="deliverToLocName_addItem"
                  class="fieldValue">{{orderDetailsForm.controls['deliverToLocName'].value}}</span>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start top" class="mb-10 mt-20">
        <div class="responsiveTable" fxFlex="100">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table" formArrayName="poLines">


            <ng-container matColumnDef="Sr No">
              <th mat-header-cell *matHeaderCellDef> {{'TABLE_DISPLAY_COLUMNS.SR'|translate}} </th>
              <td mat-cell *matCellDef="let row; let i=index"><span id="srno_addItem{{i}}"> {{i+1}} </span></td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="Product Code">
              <th mat-header-cell *matHeaderCellDef width="20%"> {{'DETAIL_PAGE.LABEL.PRODUCT_CODE'|translate}} </th>
              <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index"> -->
<!-- <input matInput placeholder="Code" maxlength="64" formControlName="sku" (change)="getProductsDetails(index,row.controls['sku'].value)"> -->
<!-- <input id="sku_addItem{{index}}" matInput placeholder="{{'DETAIL_PAGE.LABEL.PRODUCT_CODE'|translate}}"
                  maxlength="64" formControlName='sku' [matAutocomplete]="auto"
                  (focusout)="getProductsDetailsFocusout($event,index,row.controls['sku'].value)"
                  (input)="suggestionProducCode(index,row.controls['sku'].value)">

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                  (optionSelected)="getProductsDetailsSelection(index,row.controls['sku'].value)">
                  <ng-container *ngIf="suggestioinProductCodeList && suggestioinProductCodeList.length > 0">
                    <mat-option *ngFor="let code of suggestioinProductCodeList" [value]="code.code">
                      <span>{{ code.code }}</span>
                      <small> | {{code.description}} {{code.additionalAttributes}}</small>
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
                <mat-error
                  *ngIf="row.controls.sku.touched && row.controls.sku.invalid && !row.controls['sku'].hasError('pattern') && !row.controls['sku'].hasError('invalidProductNumber')  && !row.controls['sku'].hasError('isDuplicate')">
                  {{ 'DETAIL_PAGE.MESSAGE.SKU_ERROR' | translate }}
                </mat-error>

                <mat-error
                  *ngIf="row.controls['sku'].hasError('pattern') && row.controls.sku.touched && !row.controls['sku'].hasError('invalidProductNumber')  && !row.controls['sku'].hasError('isDuplicate')">
                  {{ 'DETAIL_PAGE.MESSAGE.SKU_ERROR_VALID' | translate }}
                </mat-error>
                <mat-error
                  *ngIf="row.controls['sku'].hasError('invalidProductNumber') && !row.controls['sku'].hasError('isDuplicate')">
                  {{ 'DETAIL_PAGE.MESSAGE.SKU_ERROR_VALID' | translate }}
                </mat-error>
                <mat-error *ngIf="row.controls['sku'].hasError('isDuplicate')">
                  {{ 'MESSAGE.PRODUCT_REPEATED' | translate }}
                </mat-error>
                <mat-error id="WarehouseAssociationError{{index}}" *ngIf="row.controls['warehouseAssociation'].value">
                  {{ 'MESSAGE.PRODUCT_ASSOCIATED_ERROR' | translate }}
                </mat-error>
              </td>
              <td mat-footer-cell *matFooterCellDef>
                <span class="mr-30"> {{ 'DETAIL_PAGE.LABEL.TOTAL' | translate }} </span>

              </td>
            </ng-container>

            <ng-container matColumnDef="Description">
              <th mat-header-cell *matHeaderCellDef width="20%"> {{'DETAIL_PAGE.LABEL.DESCRIPTION'|translate}} </th>
              <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
                <input id="productDesc_addItem{{index}}" matInput matTooltip="{{row.controls.productDesc.value}}"
                  readonly formControlName="productDesc">
              </td>
              <td mat-footer-cell *matFooterCellDef>
                <span>{{'DETAIL_PAGE.LABEL.PALLETS' | translate}} : {{pallets | number:'1.0-4'}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="TieHigh">
              <th mat-header-cell *matHeaderCellDef width="10%">Tie * High</th>
              <td mat-cell class="disabled-Tr" *matCellDef="let row; let index = index" [formGroupName]="index">
                <ng-container
                  *ngIf="row.controls.ti.value && row.controls.ti.value != 0 && row.controls.hi.value && row.controls.hi.value != 0">
                  {{row.controls.ti.value*row.controls.hi.value}}({{row.controls.ti.value}}*{{row.controls.hi.value}})
                </ng-container>
              </td>
              <td id="totalLines_additem" mat-footer-cell *matFooterCellDef class="textLeft">
                {{ 'LABEL.LINES' | translate }} :
                {{ totalLines}}
              </td>
            </ng-container>

            <ng-container matColumnDef="Quantity">
              <th mat-header-cell *matHeaderCellDef class="textRight"> {{'DETAIL_PAGE.LABEL.QUANTITY'|translate}} </th>
              <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="textRight">
                <input id="qty_addItem{{index}}" matInput placeholder="{{'DETAIL_PAGE.LABEL.QUANTITY'|translate}}"
                  maxlength="6" formControlName="qty" (keydown)="moveHeaderCell($event,'qty_addItem',index)"
                  (keydown.Tab)="addPOLine(index,$event)" autocomplete="off" digitOnly>
                <mat-error
                  *ngIf="row.controls.qty.touched && row.controls.qty.invalid && !row.controls['qty'].hasError('pattern') && !row.controls['qty'].hasError('isZero')">
                  {{ 'DETAIL_PAGE.MESSAGE.QUANTITY_ERROR' | translate }}
                </mat-error>
                <mat-error *ngIf="row.controls.qty.touched && row.controls['qty'].hasError('pattern')">
                  {{ 'DETAIL_PAGE.MESSAGE.QUANTITY_ERROR_VALID' | translate }}
                </mat-error>
                <mat-error *ngIf="row.controls.qty.touched && row.controls['qty'].hasError('isZero')">
                  {{ 'DETAIL_PAGE.MESSAGE.QUANTITY_ZERO_ERROR' | translate }}
                </mat-error>
              </td>
              <td mat-footer-cell *matFooterCellDef class="textRight"> <span id="totalQuantity_additem">{{
                  totalQuantity}}
                </span></td>
            </ng-container>

            <ng-container matColumnDef="Price">
              <th mat-header-cell *matHeaderCellDef class="textRight" width="12%">
                {{'DETAIL_PAGE.LABEL.COST'|translate}}
              </th>
              <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="textRight fixedData">
                <span matPrefix>{{ 'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}</span>
                <span matPrefix class="warning-icon"> <mat-icon class="cost_warning_msg_icon"
                    *ngIf="row.controls.priceWarning.value"
                    matTooltip="{{'DETAIL_PAGE.MESSAGE.LINE_COST_WARNING' | translate}}">warning</mat-icon></span>
                <mat-form-field>
                  <input id="unitPrice_addItem{{index}}" matInput maxlength="10" class="textRight"
                    (input)="markManualEdit(row)" (keydown)="moveHeaderCell($event,'unitPrice_addItem',index)"
                    formControlName="unitPrice" autocomplete="off" step="2" twodecimalPlace> -->
<!-- <mat-error *ngIf="row.controls['unitPrice'].hasError('pattern') ">
                    {{ 'ORDER.MESSAGE.PRICE_ERROR' | translate }}
                  </mat-error> -->
<!-- <mat-error class="table-mat-error"
                    *ngIf="row.controls.unitPrice.touched && row.controls.unitPrice.invalid && !row.controls['unitPrice'].hasError('pattern') && !row.controls['unitPrice'].hasError('min') && !row.controls['unitPrice'].hasError('max')">
                    {{ 'DETAIL_PAGE.MESSAGE.PRICE_ERROR' | translate }}
                  </mat-error>
                  <mat-error class="table-mat-error"
                    *ngIf="row.controls.unitPrice.touched && row.controls['unitPrice'].hasError('pattern') ">
                    {{ 'DETAIL_PAGE.MESSAGE.PRICE_TWO_DECIMAL' | translate }}
                  </mat-error>
                  <mat-error class="table-mat-error"
                    *ngIf="row.controls.unitPrice.touched && row.controls['unitPrice'].hasError('min')">
                    {{ 'DETAIL_PAGE.MESSAGE.PRICE_ZERO_ERROR' | translate }}
                  </mat-error>
                  <mat-error class="table-mat-error"
                    *ngIf="row.controls.unitPrice.touched && row.controls['unitPrice'].hasError('max')">
                    {{ 'DETAIL_PAGE.MESSAGE.PRICE_BETWEEN_ERROR' | translate }}
                  </mat-error>
                </mat-form-field>
              </td>
              <td mat-footer-cell *matFooterCellDef class="textRight"> <span id="totalPrice_additem"><span>{{
                    'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}</span>{{ totalColumnPrice |
                  pipes:'twoDecimal'}}</span>
              </td>
            </ng-container>

            <ng-container *ngIf="isFinalCost" matColumnDef="Final Cost">
              <th mat-header-cell *matHeaderCellDef class="textRight" width="12%"> Final Cost </th>
              <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="textRight fixedData">
                <span>{{ 'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}</span>
                <input id="finalUnitCost_addItem{{index}}" matInput placeholder="Final Cost" maxlength="7"
                  class="textRight" formControlName="finalUnitCost"
                  (keydown)="moveHeaderCell($event,'finalUnitCost_addItem',index)" twodecimalPlace>
                <mat-error
                  *ngIf="row.controls['finalUnitCost'].touched && row.controls['finalUnitCost'].hasError('pattern')">
                  {{ 'MESSAGE.FINAL_COST_TWO_DECLIMAL_ERROR' | translate }}
                </mat-error>
                <mat-error
                  *ngIf="row.controls['finalUnitCost'].touched && row.controls['finalUnitCost'].hasError('max')">
                  {{ 'MESSAGE.FINAL_COST_MAX_ERROR' | translate }}
                </mat-error>
              </td>
              <td mat-footer-cell *matFooterCellDef class="textRight"> <span id="totalFinalUnitCost_additem"><span>{{
                    'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}</span>{{ totalColumnFinalPrice |
                  pipes:'twoDecimal'}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="Total">
              <th mat-header-cell *matHeaderCellDef class="textRight">{{'DETAIL_PAGE.LABEL.TOTAL_COST'|translate}} </th>
              <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="textRight">
                <input id="tFormated_additem{{index}}" matInput readonly formControlName="tFormated" twodecimalPlace>
              </td>
              <td mat-footer-cell *matFooterCellDef class="textRight"><span id="columnTotal_additem">{{
                  columnTotalDisplay
                  }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="Actions" stickyEnd>
              <th mat-header-cell [hidden]="orderDetailsForm.controls.poLines.controls.length <=1" *matHeaderCellDef>
                {{'DETAIL_PAGE.LABEL.ACTION'|translate}} </th>
              <td mat-cell [hidden]="orderDetailsForm.controls.poLines.controls.length <= 1"
                *matCellDef="let row; let index = index" [formGroupName]="index">
                <button type="button" id="removePo_addItem{{index}}" mat-button class="roundBtn"
                  (click)="removePOLine(index)" *ngIf="orderDetailsForm.controls.poLines.controls.length > 1">
                  <mat-icon class="vAlignTop"><span class="icon-delete fs-18"></span></mat-icon>
                </button>
              </td>
              <td mat-footer-cell [hidden]="orderDetailsForm.controls.poLines.controls.length <=1" *matFooterCellDef>
              </td>
            </ng-container> -->

<!-- <ng-container matColumnDef="blanks">
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container> -->

<!-- <ng-container matColumnDef="otherCharges">
              <td mat-footer-cell *matFooterCellDef colspan="8">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.NOTE'|translate}}:</span>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <span class="fieldLabel">{{'DETAIL_PAGE.MESSAGE.ADDLINE_NOTE'|translate}}</span>
                </div>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayColumns" class="first-footer-row"></tr>
            <tr mat-footer-row *matFooterRowDef="['otherCharges']" class="second-footer-row"></tr>

          </table>
        </div>
      </div> -->
<!-- <div fxLayout="row" class="pdt-20" fxLayoutAlign="end center">
          <button id="close_additem" mat-button type="button" class="btn btnBasic" (click)="closeDialog()">{{ 'BUTTON.CANCEL'
            | translate }}</button>
          <button id="submit_addItem" mat-button type="button" *ngIf="!auth?.user?.info?.internalAdmin"
            [disabled]="orderDetailsForm.invalid || !orderDetailsForm.dirty || disableSubmit || warehouseAssociation"
            class="btn btnPrimary" (click)="submitOrder()">{{'BUTTON.ADD_ITEMS' |translate}}
          </button>
        </div> -->
<!-- </form> -->
<!-- old code end -->