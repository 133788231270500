<!-- <router-outlet></router-outlet><ng4-loading-spinner > </ng4-loading-spinner> -->
<div class="app-container" >
  <header-toolbar class="top-toolbar" *ngIf="!isLogin || isAppSelector" id="nav-bar-id"></header-toolbar>
  <mat-sidenav-container fullscreen [ngClass]="{'noheader': isLogin, 'disabled-bg': appService?.makeBgDisabled}">
  <!-- [ngClass]="(isLogin)?'noheader':''" > -->
    <mat-sidenav #sidenav mode="side" opened *ngIf="!isLogin && !appService.hideSideNav" id="side-nav-id" [disableClose]="true">
      <side-toolbar class="sidenav-toolbar"></side-toolbar>
    </mat-sidenav>
    
    <mat-sidenav-content role="main" id="sidenav-content-id">
      <div class="content-container">
        <router-outlet></router-outlet>
      </div>
      
    </mat-sidenav-content>
  </mat-sidenav-container>
  
</div>

<ng-template #LoginPage>
  <div class="login-container">
    <router-outlet></router-outlet>
  </div>
</ng-template>