<div class="header">
    <div class="orderheader">
        <div class="title">
            <ng-content select="[title]">
            </ng-content>
        </div>
        <div class="field-section">
            <ng-content select="[field]">
            </ng-content>
        </div>
        <div class="error">
            <ng-content select="[error]">
            </ng-content>
        </div>
    </div>
    <span class="header-border"></span>
</div>