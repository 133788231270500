import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { OneAuthService } from 'one-auth';


@Injectable()
export class AuthGuard implements CanActivate {

	constructor(public auth: OneAuthService, private router: Router) { }

	canActivate(): boolean | Promise<boolean> {
		let token = this.auth.getToken();

		if (!token) {
			console.error("User is not authenticated.");
			this.redirectToLoginPage();
			return false;
		}
		// else if (this.authentication.isAuthenticated()) {
		// 	return true;
		// }
		// else {
		// 	this.authentication.refreshToken();
		// 	return true;
		// }
	}

	redirectToLoginPage() {
		//window.location.href = Global.COMMON_APP_URL;
	}

}
