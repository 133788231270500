export const IMAGES = Object();

IMAGES.PROCURANT_LOGO = "assets/images/procurant_logo.png";
IMAGES.DEFAULTPROFILE = "assets/images/profile.png";

// Use for Demand Planning show/hide attribute Date: 15/04/2024 (BT)
IMAGES.EXPAND_UP = "assets/images/expand_up_new.svg";
IMAGES.EXPAND_DOWN = "assets/images/expand_down_new.svg";

IMAGES.PRODUCT_LIST_OPEN = "assets/images/task_expand.svg";
IMAGES.PRODUCT_LIST_CLOSE = "assets/images/list-btn.svg";
IMAGES.VIEW_COSTOMIZATION_OPEN = "assets/images/view-customization.svg";
IMAGES.VIEW_COSTOMIZATION_CLOSE = "assets/images/view-customization-close.svg";
IMAGES.PRINT_LIGHT = "assets/images/print_light.svg";
IMAGES.ATTACHMENT = "assets/images/Attachment.svg";
IMAGES.ATTACHMENT_EXIST = "assets/images/old-attachment.svg";
IMAGES.NEW_ATTACHMENT = "assets/images/new-attachment.svg";
IMAGES.BLANK_CHARGE = "assets/images/coins-stacked.svg";
IMAGES.CHARGE_EXIST = "assets/images/coins-stacked-01.svg";
IMAGES.NEW_CHARGES = "assets/images/new-charges.svg";
IMAGES.HISTORY_ICON = "assets/images/history-icon.svg";
IMAGES.DOTS_HORIZONTAL = "assets/images/dots-horizontal.svg";
IMAGES.DOTS_HORIZONTAL_EXPAND = "assets/images/dots-horizontal_expand.svg";
IMAGES.BLANK_COMMENT = "assets/images/blank_comment.svg";
IMAGES.READ_COMMENT = "assets/images/read_comment.svg";
IMAGES.UNREAD_COMMNET = "assets/images/unread_commnet.svg";
IMAGES.ACKNOWLEDGE_ICON = "assets/images/acknowledge-icon.svg";
IMAGES.TRUCK = "assets/images/truck.svg";
IMAGES.ADD_LINE = "assets/images/add-line.svg";
IMAGES.MOVE_LINE = "assets/images/move-line.svg";
IMAGES.CHANGE_SUPPLIER = "assets/images/change-supplier.svg";
IMAGES.BL_FLAG = "assets/images/BL-flag.svg";
IMAGES.COPY_ORDER = "assets/images/copy-order.svg";
IMAGES.ORDER_CANCEL = "assets/images/order-cancel.svg";
IMAGES.CANCEL = "assets/images/Cancel.svg";
IMAGES.DISABLED_CIRCLE = "assets/images/disabled-circle.svg";
IMAGES.PO_LINE_CANCEL = "assets/images/po-line-cancel.svg";
IMAGES.TIME_LIGHT = "assets/images/Time_light.svg";
IMAGES.HISTORY_ROUNDED = "assets/images/history-rounded.svg";
IMAGES.EXPAND_DOWN = "assets/images/expand_down.svg";
IMAGES.EXPAND_UP = "assets/images/expand_up.svg";
IMAGES.FILE_ORDER_LIST = "assets/images/file_order_list.svg";
IMAGES.FILE_DOCK_LIGHT = "assets/images/file_dock_light.svg";
IMAGES.VECTOR = "assets/images/Vector.svg";
IMAGES.CHEVRON_RIGHT = "assets/images/chevron-right.svg";
IMAGES.CHEVRON_LEFT = "assets/images/chevron-left.svg";
IMAGES.SEARCH_MD = "assets/images/search-md.svg";
IMAGES.FLAG_WATCH = "assets/images/flag_watch.svg";
IMAGES.UNFLAG_WATCH = "assets/images/unflag_watch.svg";
IMAGES.REMOVE_LINE_ICON = "assets/images/remove-line-icon.svg"
IMAGES.CANCEL_NOT_VIEWED = "assets/images/cancel_not_viewed.svg"
IMAGES.CANCEL_VIEWED = "assets/images/cancel_viewed.svg"
IMAGES.SUPPLIER_CODE = "assets/images/supplier-code.svg";
IMAGES.CLOCK = "assets/images/clock.svg";
IMAGES.REPEAT_INTERVAL = "assets/images/repeat-interval.svg";
IMAGES.CHECKLIST = "assets/images/checklist.svg";


