import { PlatformLocation } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { constant, PO_ACTION, PO_MODFLAG, PO_STATUS, SharedUtilService, TranslateService, DialogBoxService, ALERT_TYPE, ALERT_MSG_PARAM } from 'common-lib';
import * as moment_ from 'moment';
import { OneNotifyService, OneAuthService } from 'one-auth';
import { ComponentService } from '../../../shared-components/services/component.service';
import { OrderService } from '../../services/order.service';
const moment = moment_["default"];

@Component({
  selector: 'move-line-overlay',
  templateUrl: './move-line-overlay.component.html',
  styleUrls: ['./move-line-overlay.component.scss']
})
export class MoveLineOverlayComponent implements OnInit, OnDestroy {

  moveLineForm: FormGroup;
  modalData: any;
  po_Actions = PO_ACTION;
  po_status = PO_STATUS;
  po_modFlag = PO_MODFLAG;
  isNewOrder = true;
  minDate = moment(moment().tz(this.auth.user.info.timeZone).format('YYYY-MM-DD'));
  maxDate = moment(new Date()).add(12, 'months').toDate();
  minDelDate = moment(moment().tz(this.auth.user.info.timeZone).format('YYYY-MM-DD'));
  exitingPO: any[];
  previousFreightTerm: string;
  previousDeliveryDate: Date;
  previousShipDate: Date;
  existingId: number;
  isNoExistingOrderPresent = false;
  successMessage: string;
  successfullyMove: any;
  submitFlag: boolean = false;
  isNewRequest: boolean = false;
  supplierCodes = [];

  constructor(private fb: FormBuilder, private componentService: ComponentService, private orderService: OrderService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: any, private transalate: TranslateService, private sharedUtilService: SharedUtilService,
    public notify: OneNotifyService, public auth: OneAuthService, private platformLocation: PlatformLocation, private dialogBoxService: DialogBoxService) {
    this.platformLocation.onPopState(() => this.componentService.closeDialog());
    this.createForm()
    this.loadOrderDetail();
  }

  createForm() {
    return this.moveLineForm = this.fb.group({
      id: [''],
      sourceId: [''],
      poNumber: ['', Validators.required],
      rangeStatus: ['USER_DEFINED'],
      oldPoNumber: [{ value: '', disabled: true }],
      existingPoNumber: [''],
      newPoNumber: [''],
      buyerCompanyId: [],
      buyerCompanyName: ['Safewa'],
      sellerCompanyId: [],
      sellerCompanyName: ['', Validators.required],
      offline: [],
      supplierCode: [''],
      deliverToLocId: [],
      deliverToLocName: ['', Validators.required],
      rangeDeliverToLocId: [''],
      shipFromLocId: [],
      shipFromLocName: [''],
      deliveryDate: [],
      newdeliveryDate: [''],
      shipDate: [],
      newshipDate: [],
      freightTerm: [''],
      existingfreightTerm: [''],
      routing: [{ value: '', disabled: false }],
      totalQty: [],
      uom: ['Cases7A'],
      totalAmount: [0],
      totalLines: [],
      currency: ['USD'],
      action: [''],
      poLines: this.fb.array([]),
      poNumberRangeId: [],
      buyerOwner: [],
      buyerOwnerUser: this.fb.group({ id: [''], organizationId: [''], loginName: [''], fname: [], lname: [], phone: [], email: [], userCode: [], active: [] })
    });
  }

  ngOnInit() {
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  ngAfterViewChecked() {
    if (document.querySelector(".mat-select-panel-wrap")) {
      document.querySelector(".mat-select-panel-wrap").classList.add("ord-custom-select-panel-wrap");
    }
  }

  ngAfterViewInit() {
    this.radioChange("1")
    this.moveLineForm.get('newdeliveryDate').updateValueAndValidity();
    this.moveLineForm.get('newshipDate').updateValueAndValidity();
    this.cdr.detectChanges();
  }

  getSupplierCodes(supplierName, id, offline) {
    if (supplierName) {
      this.orderService.getSupplierCodesOfSupplier(id, offline).subscribe(resp => {
        if (resp instanceof Array && resp.length > 0) {
          this.supplierCodes = resp;
          if (resp.filter(item => item == this.data.dataDetails[0].controls['supplierCode'].value).length == 0) {
            if (this.supplierCodes.length == 1)
              this.moveLineForm.controls['supplierCode'].patchValue(this.supplierCodes[0]);
            else
              this.moveLineForm.controls['supplierCode'].patchValue("");
            this.moveLineForm.controls['supplierCode'].clearValidators();
            this.moveLineForm.controls['supplierCode'].updateValueAndValidity();
          }
        } else {
          this.supplierCodes = []
          this.moveLineForm.controls['supplierCode'].reset();
          this.moveLineForm.controls['supplierCode'].clearValidators();
          this.moveLineForm.controls['supplierCode'].updateValueAndValidity();
        }
      }, error => {
        this.supplierCodes = []
        this.moveLineForm.controls['supplierCode'].reset();
        this.moveLineForm.controls['supplierCode'].clearValidators();
        this.moveLineForm.controls['supplierCode'].updateValueAndValidity();
      })
    } else {
      this.moveLineForm.controls['supplierCode'].reset();
      this.moveLineForm.controls['supplierCode'].clearValidators();
      this.moveLineForm.controls['supplierCode'].updateValueAndValidity();
    }
  }

  loadOrderDetail() {
    if (this.data.dataDetails) {
      this.isNewRequest = this.data.dataDetails[2];
      Object.keys(this.data.dataDetails[0].controls).forEach(name => {
        if (this.moveLineForm.controls[name]) {
          this.moveLineForm.controls[name].patchValue(this.data.dataDetails[0].controls[name].value, '');
        }
      });
      this.getSupplierCodes(this.data.dataDetails[0].controls['sellerCompanyName'].value, this.data.dataDetails[0].controls['sellerCompanyId'].value,
        this.data.dataDetails[0].controls['offline'].value)
      this.moveLineForm.get('oldPoNumber').setValue(this.moveLineForm.get('poNumber').value);
      this.moveLineForm.get('routing').setValue(this.moveLineForm.get('freightTerm').value);
      this.moveLineForm.get('totalLines').setValue(this.data.dataDetails[1].length);


      this.previousDeliveryDate = this.moveLineForm.get('deliveryDate').value;
      this.previousShipDate = this.moveLineForm.get('shipDate').value;
      this.previousFreightTerm = this.moveLineForm.get('freightTerm').value;

      //Remove all Existing values
      this.moveLineForm.get('freightTerm').setValue('');
      this.moveLineForm.get('deliveryDate').setValue('');
      this.moveLineForm.get('shipDate').setValue('');


      const control = <FormArray>this.moveLineForm.controls['poLines'];
      if (control.controls) {
        control.controls = [];
      }
      for (let index = 0; index < this.data.dataDetails[1].length; index++) {
        const element = this.data.dataDetails[1][index];
        control.push(this.setPoline(element.value))
      }
      this.fetchOIDAndAssign()
    }
    //this.radioChange("1");
    this.getExistingPO(this.moveLineForm.get('id').value);
  }

  private setPoline(val?) {
    //Add poLines
    const numberPatern = '^[0-9,]+$';
    const decimalPatern = '^[0-9]+(\.[0-9]{1,4})?$';
    //const specialcharPatern = '^[A-Za-z0-9\u4e00-\u9eff- ]+$';
    return this.fb.group({
      poId: [val.poId],
      id: [val.id],
      buyerCompanyId: [val.buyerCompanyId],
      lineNumber: [val.lineNumber],
      sequence: [val.sequence],
      productId: [val.productId],
      qty: [val.qty, [Validators.pattern(numberPatern)]],
      previousQty: [val.previousQty],
      quantityModFlag: [val.quantityModFlag],
      purchaseByUom: [val.purchaseByUom],
      sellByUom: [val.sellByUom],
      unitPrice: [val.unitPrice, Validators.compose([Validators.pattern(decimalPatern), Validators.max(9999.9999)])],
      previousUnitPrice: [val.previousUnitPrice],
      priceModFlag: [val.priceModFlag],
      finalUnitCost: [val.finalUnitCost],
      priceByUom: [val.priceByUom],
      totalAmount: [{ value: val.totalAmount, disabled: false }],
      tFormated: [val.tFormated],
      currency: [val.currency],
      status: [val.status],
      soNumber: [val.soNumber],
      shipDate: [val.shipDate],
      shipFromLocId: [val.shipFromLocId],
      action: ['']
    });
  }

  getExistingPO(id?) {
    this.orderService.getExistingPOList(id).subscribe(resp => {
      this.exitingPO = resp;
    }, err => {
      this.notify.show(err, 'error');
    });
  }

  setExistingPOValue(exitingPO: any): void {
    if (exitingPO) {
      let po = this.exitingPO.find(po => po.orderNumber == exitingPO);
      this.existingId = po.id;
      this.moveLineForm.get('id').setValue(po.id);
      this.moveLineForm.get('existingPoNumber').setValue(po.orderNumber);
    }
    else {
      this.moveLineForm.get('id').setValue(null);
      this.moveLineForm.get('existingPoNumber').setValue('');
    }
  }

  checkIsOrderExists() {
    if (this.exitingPO == null) {
      this.isNoExistingOrderPresent = true;
      this.moveLineForm.get('existingPoNumber').markAsTouched();
      this.moveLineForm.get('existingPoNumber').updateValueAndValidity();
    }
    else if (this.exitingPO.length <= 0) {
      this.isNoExistingOrderPresent = true;
      this.moveLineForm.get('existingPoNumber').markAsTouched();
      this.moveLineForm.get('existingPoNumber').updateValueAndValidity();
    }
    else {
      this.moveLineForm.get('existingPoNumber').markAsUntouched();
      this.moveLineForm.get('existingPoNumber').updateValueAndValidity();
    }
  }

  closeDialog() {
    this.componentService.closeDialog();
  }

  fromDateChanged(dateValue) {
    if (this.moveLineForm.controls['newdeliveryDate'].value != '') {
      return this.dateValidation('newdeliveryDate', 'newshipDate');
    } else {
      this.minDelDate = dateValue;
    }
    return null;
  }

  toDateChanged(dateValue) {
    if (this.moveLineForm.controls['newshipDate'].value != '') {
      if (dateValue == 'Invalid date') {
        this.moveLineForm.controls['newdeliveryDate'].setValue('');
        return null;
      } else {
        return this.dateValidation('newdeliveryDate', 'newshipDate');
      }
    }
    return null;
  }

  dateValidation(fromField: string, toField: string) {
    setTimeout(() => {
      let shipDate = moment(this.moveLineForm.get('newshipDate').value);
      let deliveryDate = moment(this.moveLineForm.get('newdeliveryDate').value);
      this.moveLineForm.controls[toField].setErrors(null);
      this.moveLineForm.controls[fromField].setErrors(shipDate > deliveryDate ? { 'notValid': true } : null);
    }, 0);
    return null;
  }

  checkOrderNumber(poNumber) {
    if (poNumber.trim() == "") {
      this.moveLineForm.get('newPoNumber').setErrors({ 'notValid': true });
      this.moveLineForm.get('newPoNumber').setValue("");
    } else {
      this.orderService.isPONumberExists(poNumber).subscribe(resp => {
        if (resp) {
          this.moveLineForm.get('newPoNumber').setErrors({ 'poExists': true });
          //this.moveLineForm.get('newPoNumber').setValue("");
        }
      }, err => {
        this.notify.show(err, 'error');
      });
    }
  }

  newOrderSetValidator() {
    this.isNewOrder = true;
    this.isNoExistingOrderPresent = false;
    let removevalidation: string[] = ['existingPoNumber', 'existingfreightTerm', 'newdeliveryDate', 'newPoNumber'];
    let addvalidtion: string[] = ['existingfreightTerm', 'newdeliveryDate', 'newPoNumber'];
    //remove all validation
    //remove all order validation
    removevalidation.forEach(control => {
      this.moveLineForm.get(control).setValidators(null);
      this.moveLineForm.get(control).markAsUntouched();
      this.moveLineForm.get(control).updateValueAndValidity();
    });

    //add new order validation
    addvalidtion.forEach(control => {
      this.moveLineForm.get(control).setValidators(Validators.required);
      this.moveLineForm.get(control).markAsUntouched();
      this.moveLineForm.get(control).updateValueAndValidity();
    });
    //this.dateValidation('newdeliveryDate', 'newshipDate');
  }

  fetchOIDAndAssign() {
    let buyerUser = this.moveLineForm.controls['buyerOwner'].value;
    let userCode = null;
    if (buyerUser) {
      userCode = this.moveLineForm.controls['buyerOwnerUser'].value.userCode;
    }
    let obj = {
      "genPONumRequestList":
        [
          {
            "poNumberCount": 1,
            "locationId": this.moveLineForm.controls['deliverToLocId'].value,
            "deliverToLocName": this.moveLineForm.controls['deliverToLocName'].value,
            "userCode": userCode ? userCode : null,
            "userCodeSelected": buyerUser ? true : false,
          }

        ]
    }
    this.moveLineForm.controls['rangeStatus'].setValue('INPROCESS')
    if (!this.auth.user.info.internalAdmin) {
      this.orderService.getPORange(obj).subscribe(res => {
        if (res.body['anyRangesExist']) {
          if (res.body['genPONumResponseList'][0].rangeExists) {
            if (res.body['genPONumResponseList'][0].rangeExhausted) {
              this.moveLineForm.controls['newPoNumber'].setValue('');
              this.moveLineForm.controls['rangeStatus'].setValue('RANGE_EXHAUSTED');
            } else {
              this.moveLineForm.controls['rangeStatus'].setValue('SYSTEM_GENERATED');
              this.moveLineForm.controls['rangeDeliverToLocId'].setValue(res.body['genPONumResponseList'][0].locationId);
              this.moveLineForm.controls['newPoNumber'].setValue(res.body['genPONumResponseList'][0].poNumber);
              this.moveLineForm.controls['poNumberRangeId'].setValue(res.body['genPONumResponseList'][0].poNumberRangeId);
            }
          } else {
            this.moveLineForm.controls['rangeStatus'].setValue('USER_DEFINED');
            this.moveLineForm.controls['newPoNumber'].setValue('');
          }
        } else {
          this.moveLineForm.controls['rangeStatus'].setValue('USER_DEFINED');
          this.moveLineForm.controls['newPoNumber'].setValue('');
        }
      }, error => {
        if (error.status == 400 && error.headers.get('Error').includes("Reset the Order range")) {
          if (error.headers.get('Error').includes("Reset the Order range")) {
            this.notify.show(constant.replaceErrorMsgValue(this.transalate.instant('MESSAGE.RANGE_EXHAUSTED_ERROR'), this.moveLineForm.controls['deliverToLocName'].value), 'error');
          } else if (error.status == 400 && error.headers.get('Error').includes("No Order Range exists.")) {
            this.moveLineForm.controls['rangeStatus'].setValue('NO_RANGE_SYSTEM_ERROR');
            this.moveLineForm.controls['poNumber'].setValue('');
            this.moveLineForm.get('poNumberRangeId').setValue(null);
          } else {
            this.notify.show(error.headers.get('Error'), 'error');
          }
          this.componentService.closeDialog();
          this.moveLineForm.controls['rangeStatus'].setValue('RANGE_EXHAUSTED')
          this.moveLineForm.controls['newPoNumber'].setValue('')
        } else if (error.status == 400) {
          this.notify.show(error.headers.get('Error'), 'error');
          this.moveLineForm.controls['rangeStatus'].setValue('SYSTEM_ERROR')
          this.moveLineForm.controls['poNumber'].setValue('')
          this.moveLineForm.get('poNumberRangeId').setValue(null);
        } else {
          this.moveLineForm.controls['rangeStatus'].setValue('SYSTEM_ERROR')
          this.moveLineForm.controls['newPoNumber'].setValue('')
        }
      })
    } else {
      this.moveLineForm.controls['rangeStatus'].setValue('SYSTEM_GENERATED')
      this.moveLineForm.controls['newPoNumber'].setValue('DemoPO1')
      this.moveLineForm.controls['poNumberRangeId'].setValue(null);
    }
  }

  existingOrderSetValidator() {
    /*     if ((this.moveLineForm.controls['rangeDeliverToLocId'].value).toString() && this.moveLineForm.controls['deliverToLocId'].value && this.moveLineForm.controls['poNumber'].value&&this.moveLineForm.controls['rangeStatus'].value=='SYSTEM_GENERATED') {
        let obj = {
          "locationId": this.moveLineForm.controls['rangeDeliverToLocId'].value,
          "poNumber": this.moveLineForm.controls['newPoNumber'].value
        }
        this.orderService.PONumberRelease(obj).subscribe(res => {
          this.moveLineForm.controls['rangeStatus'].setValue('USER_DEFINED');
          this.moveLineForm.controls['newPoNumber'].setValue('')
        }, error => {
        })
      } */
    this.isNewOrder = false;
    let removevalidation: string[] = ['existingPoNumber', 'existingfreightTerm', 'newdeliveryDate', 'newPoNumber'];
    let addvalidtion: string[] = ['existingPoNumber'];

    //remove all order validation
    removevalidation.forEach(control => {
      this.moveLineForm.get(control).setValidators(null);
      this.moveLineForm.get(control).setErrors(null);
      this.moveLineForm.get(control).markAsUntouched();
      this.moveLineForm.get(control).updateValueAndValidity();
    });

    //add validation
    addvalidtion.forEach(control => {
      this.moveLineForm.get(control).setValidators(Validators.required);
      this.moveLineForm.get(control).updateValueAndValidity();
    });
    this.checkIsOrderExists();
  }
  radioChange(event) {
    if (event == 1) {
      this.newOrderSetValidator();
    } else {
      this.existingOrderSetValidator();
    }
  }


  submitOrder() {
    //confirmation dialog code ..
    let selectedLinse = this.data.dataDetails[1].map(line => " " + line.value.sku);
    let ordNo = this.isNewOrder ? this.moveLineForm.controls['newPoNumber'].value : this.moveLineForm.controls['existingPoNumber'].value;
    let dialogContent: ALERT_MSG_PARAM = {
      message: constant.replaceErrorMsgValue(this.transalate.instant("DETAIL_PAGE.MESSAGE.MOVE_LINE_ITEMS_CODES"), selectedLinse, this.moveLineForm.controls['oldPoNumber'].value, ordNo),
      btnOKText: this.transalate.instant("BUTTON.CONTINUE"),
      btnCancelText: this.transalate.instant("BUTTON.CANCEL"),
      hideCancelBtn: false,
      additionalMessage: this.transalate.instant("DETAIL_PAGE.MESSAGE.ARE_YOU_SURE_WANT_TO_MOVE_THESE_ITEMS"),
      width: 550,
      height: 'auto',
      icon: ALERT_TYPE.WARNING
    };
    this.dialogBoxService.ProcurantConfirmDialog(dialogContent).then(res => {
      this.submitFlag = true;
      this.setControlValue();
      this.postOrder();
    }).catch(e => { });


  }

  postOrder() {
    const poLines = this.moveLineForm.controls["poLines"].value;
    const control = this.moveLineForm.controls["poLines"] as FormArray;
    if (this.moveLineForm.get('supplierCode').value == null) {
      this.moveLineForm.get('supplierCode').setValue('');
    }
    if (poLines.length >= 1) {
      for (let index = 0; index < control.controls.length; index++) {
        poLines[index].shipDate = this.moveLineForm.get('shipDate').value;
      }
    }
    if (!this.isNewRequest) {
      const control = <FormArray>this.moveLineForm.controls["poLines"];
      this.moveLineForm.get('sourceId').setValue(control.value[0].poId);
      this.orderService.postMoveLine(this.moveLineForm.value).subscribe(res => {
        this.successfullyMove = true;
        this.submitFlag = false;
        this.moveLineForm.get('action').setValue('')
        this.componentService.isSubmited = true;
        this.componentService.data = this.successMessage;
        this.closeDialog();
      }, err => {
        this.submitFlag = false;
        if (err.error) {
          if (err.error.errorCode = "DUPLICATE_PRODUCT_NOT_ALLOWED") {
            this.notify.show(err, 'error');
          } else {
            this.notify.show(err, 'error');
          }
        } else
          this.notify.show(this.sharedUtilService.handleError(err), 'error');
        this.componentService.isSubmited = false;
        this.moveLineForm.get('action').setValue('');
      });
    } else {
      if (this.isNewOrder) {
        const control = <FormArray>this.moveLineForm.controls["poLines"];
        this.moveLineForm.get('sourceId').setValue(control.value[0].poId);
        this.orderService.postMoveLineNewAPI(this.moveLineForm.value).subscribe(res => {
          this.successfullyMove = true;
          this.submitFlag = false;
          this.moveLineForm.get('action').setValue('')
          this.componentService.isSubmited = true;
          this.componentService.data = this.successMessage;
          this.closeDialog();
        }, err => {
          this.submitFlag = false;
          if (err.error) {
            if (err.error.errorCode = "DUPLICATE_PRODUCT_NOT_ALLOWED") {
              this.notify.show(err, 'error');
            } else {
              this.notify.show(err, 'error');
            }
          } else
            this.notify.show(this.sharedUtilService.handleError(err), 'error');
          this.componentService.isSubmited = false;
          this.moveLineForm.get('action').setValue('');
        });
      } else {
        this.createRequest().then(r => {
          if (r) {
            this.orderService.postMoveLineNewAPI(this.requestData).subscribe(res => {
              this.successfullyMove = true;
              this.submitFlag = false;
              this.moveLineForm.get('action').setValue('')
              this.componentService.isSubmited = true;
              this.componentService.data = this.successMessage;
              this.closeDialog();
            }, err => {
              this.submitFlag = false;
              if (err.error) {
                if (err.error.errorCode = "DUPLICATE_PRODUCT_NOT_ALLOWED") {
                  this.notify.show(err, 'error');
                } else {
                  this.notify.show(err, 'error');
                }
              } else
                this.notify.show(this.sharedUtilService.handleError(err), 'error');
              this.componentService.isSubmited = false;
              this.moveLineForm.get('action').setValue('');
            });
          }
        })
      }
    }
  }

  setControlValue() {
    this.moveLineForm.get('action').setValue(PO_ACTION.SUBMITORDER)
    if (this.isNewOrder) {
      this.successMessage = constant.replaceErrorMsgValue(this.transalate.instant("DETAIL_PAGE.MESSAGE.MOVELINE_SUCCESS"), this.moveLineForm.get('poNumber').value, "New Order", this.moveLineForm.get('newPoNumber').value);
      this.moveLineForm.get('poNumber').setValue(this.moveLineForm.get('newPoNumber').value);
      this.moveLineForm.get('deliveryDate').setValue(this.moveLineForm.get('newdeliveryDate').value);
      this.moveLineForm.get('shipDate').setValue(this.moveLineForm.get('newshipDate').value);
      this.moveLineForm.get('freightTerm').setValue(this.moveLineForm.get('existingfreightTerm').value);
      this.moveLineForm.get('id').setValue(null);
    } else if (!this.isNewOrder) {
      this.successMessage = constant.replaceErrorMsgValue(this.transalate.instant("DETAIL_PAGE.MESSAGE.MOVELINE_SUCCESS"), this.moveLineForm.get('poNumber').value, "Existing Order", this.moveLineForm.get('existingPoNumber').value);
      //this.moveLineForm.get('poNumber').setValue(this.moveLineForm.get('existingPoNumber').value);
      this.componentService.data = this.moveLineForm.get('existingPoNumber').value;
      this.moveLineForm.get('id').setValue(this.existingId);
      this.moveLineForm.get('id').updateValueAndValidity();
      this.moveLineForm.get('deliveryDate').setValue(this.previousDeliveryDate);
      this.moveLineForm.get('shipDate').setValue(this.previousShipDate);
      this.moveLineForm.get('freightTerm').setValue(this.previousFreightTerm);
      this.moveLineForm.get('poNumberRangeId').setValue(null);
    }
  }

  openSnackBarMessage(message?: string) {
    this.notify.show(this.transalate.instant(message));
  }

  ngOnDestroy() {
    if ((((!this.successfullyMove) || (this.successfullyMove && !this.isNewOrder)) && this.moveLineForm.controls['rangeStatus'].value == 'SYSTEM_GENERATED')) {
      let obj = {
        "releasePONumRequestList":
          [
            {
              "locationId": this.moveLineForm.controls['rangeDeliverToLocId'].value,
              "poNumber": this.moveLineForm.controls['newPoNumber'].value,
              "poNumberRangeId": this.moveLineForm.controls['poNumberRangeId'].value,
            },
          ]
      }
      this.orderService.PONumberRelease(obj).subscribe(res => {
      })
    }
  }

  requestData: any;
  createRequest(): Promise<any> {
    this.requestData = undefined;
    let poLines: any = [];
    const control = <FormArray>this.moveLineForm.controls["poLines"];
    let lineFieldsArr = ['id'];
    let p = new Promise((resolve, reject) => {
      this.requestData = {
        "sourceId": control.value[0].poId,
        "targetId": this.moveLineForm.get('id').value,
        "deliverToLocId": this.moveLineForm.get('deliverToLocId').value
      }
      let linereq: any
      for (let index = 0; index < control.length; index++) {
        linereq = {
          "id": this.moveLineForm.get('id').value,
          "productId": control.value[index].productId
        }
        lineFieldsArr.forEach(name => {
          const element = control.controls[index];
          linereq[name] = element.get(name).value
        });
        poLines.push(linereq);
      }
      if (poLines.length > 0)
        this.requestData['poLines'] = poLines
      resolve(true);
    })
    return p;
  }
  public removeSelectedLine(poLineId: any) {
    const indexToRemove = this.data.dataDetails[1].findIndex(item => item.value.id === poLineId);
    if (indexToRemove !== -1) {
      (<FormArray>this.moveLineForm.controls['poLines']).removeAt(indexToRemove);
      this.data.dataDetails[1].splice(indexToRemove, 1);
    }
  }
}

