import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'procurant-footer',
  templateUrl: './procurant-footer.component.html',
  styleUrls: ['./procurant-footer.component.scss']
})
export class ProcurantFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
