<side-nav id="side-nav-custome">
  <div class="navBarHeight" sidenavitem *ngIf="!auth?.user?.info?.internalAdmin">
    <div class="nav-new-bar">
      <div *ngIf="navigationScrollButton=='up' || navigationScrollButton=='both'" class="up-btn"
        (click)="handelScrollClick('up')">
        <img src="../../../../../assets/images/up_double.png" alt="">
      </div>
      <div class="nav-menu-list" id="sidebar" (scroll)="scrollFunction()">
        <a mat-list-item routerLink="/dashboard" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.DASHBOARD' | translate}}">
          <!-- <span class="sidebar-icon icon-Dashbaord"></span> -->
          <!-- <img src="../../../../../assets/images/Dashboard-icon.jpg"> -->
          <!-- <img id="dashborad"  width="1" height="1"> -->
          <div class="sprite-container dashboard">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.DASHBOARD' | translate }}</span>
        </a>
        <a mat-list-item routerLink="/task-center/task-center-list" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.TASK_CENTER' | translate}}">
          <!-- <span class="icon-task_centre sidebar-icon"></span> -->
          <div class="sprite-container taskcenter">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.TASK_CENTER' | translate }}</span>
        </a>
        <a mat-list-item *ngIf="permissions?.BUYER?.MODULES?.PRICE_ALERTS?.actions?.view" routerLink="/price-watch" routerLinkActive="active" 
        title="{{'NAVBAR.LABEL.PRICE_ALERTS' | translate}}">
        <!-- <span class="icon-task_centre sidebar-icon"></span> -->
        <div class="sprite-container price-alerts">
          <div class="sprite-image"></div>
        </div>
        <span class="nav-name">{{ 'NAVBAR.LABEL.PRICE_ALERTS' | translate }}</span>
      </a>
        <a mat-list-item *ngIf="permissions?.BUYER?.MODULES?.DEMAND?.actions?.view" routerLink="/demands"
          routerLinkActive="active" title="{{'NAVBAR.LABEL.DEMANDS' | translate}}">
          <!-- <span class="sidebar-icon icon-demand"></span> -->
          <div class="sprite-container demand">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.DEMANDS' | translate }}</span>
        </a>
        <a mat-list-item *ngIf="permissions?.BUYER?.MODULES?.PRICING?.actions?.view" routerLink="/product-pricing"
          routerLinkActive="active" title="{{'NAVBAR.LABEL.PRICING' | translate}}">
          <!-- <span class="sidebar-icon icon-pricing"></span> -->
          <div class="sprite-container pricing">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.PRICING' | translate }}</span>
        </a>
        <a mat-list-item *ngIf="permissions?.BUYER?.MODULES?.ORDER_MANAGEMENT?.actions?.view"
          routerLink="/order/order-list" routerLinkActive="active" title="{{'NAVBAR.LABEL.ORDERS' | translate}}">
          <!-- <span class="sidebar-icon icon-orders_list"></span> -->
          <div class="sprite-container order">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.ORDERS' | translate }}</span>
        </a>
        <a mat-list-item *ngIf="permissions?.BUYER?.MODULES?.INVOICES?.actions?.view" routerLink="/order-invoice"
          routerLinkActive="active" title="{{'NAVBAR.LABEL.INVOICE' | translate}}">
          <!-- <span class="sidebar-icon icon2-invoice"></span> -->
          <div class="sprite-container invoice">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.INVOICE' | translate }}</span>
        </a>
        <a mat-list-item *ngIf="permissions?.BUYER?.MODULES?.CLAIMS?.actions?.view" routerLink="/claims"
          routerLinkActive="active" title="{{'NAVBAR.LABEL.CLAIMS' | translate}}">
          <!-- <span class="sidebar-icon icon-claim"></span> -->
          <div class="sprite-container claim">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.CLAIMS' | translate }}</span>
        </a>
        <a mat-list-item *ngIf="permissions?.BUYER?.MODULES?.LOGISTICS?.actions?.view" routerLink="/logistics/load-list"
          routerLinkActive="active" title="{{'NAVBAR.LABEL.LOGISTICS' | translate}}">
          <!-- <span class="sidebar-icon icon-logistics"></span> -->
          <div class="sprite-container logistics">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.LOGISTICS' | translate }}</span>
        </a>
        <!--     <a mat-list-item *ngIf="permissions?.BUYER?.MODULES?.LOGISTICS?.actions?.view" routerLink="/freight-invoice/freight-invoice-list" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.FREIGHT_INVOICE' | translate}}">
          <span class="sidebar-icon icon2-invoice"></span>
          {{ 'NAVBAR.LABEL.FREIGHT_INVOICE' | translate }}
        </a> -->
        <a mat-list-item *ngIf="(permissions?.BUYER?.MODULES?.DEMAND_PLANNING?.actions?.view || permissions?.BUYER?.MODULES?.INVENTORY?.actions?.view)" routerLink="/order-inventory"
          routerLinkActive="active" title="{{'NAVBAR.LABEL.DEMAND_PLANNING' | translate}}">
          <!-- <span class="sidebar-icon icon-inventory"></span> -->
          <div class="sprite-container inventory">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.DEMAND_PLANNING' | translate }}</span>
        </a>
        <a mat-list-item routerLink="/reports-analytics" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.REPORTS' | translate}}">
          <!-- <span class="sidebar-icon icon-analytics"></span> -->
          <div class="sprite-container report">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.REPORTS' | translate }}</span>
        </a>
        <a mat-list-item routerLink="/my-preferences" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.MY_PREFERENCES' | translate}}">
          <!-- <span class="sidebar-icon icon2-settings"></span> -->
          <div class="sprite-container preferences">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.MY_PREFERENCES' | translate }}</span>
        </a>
        <!--(HA)PMB-7682 Trace Screen UI Improvement - added click event-->
        <a mat-list-item routerLink="/track-trace" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.TRACK_TRACE' | translate}}" (click)="onClickOnTrace()">
          <!-- <span class="sidebar-icon icon-track-trace"></span> -->
          <div class="sprite-container trace">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{'NAVBAR.LABEL.TRACK_TRACE' | translate}}</span>
        </a>
      </div>
      <div *ngIf="navigationScrollButton=='down' || navigationScrollButton=='both'" class="down-btn"
        (click)="handelScrollClick('down')">
        <img src="../../../../../assets/images/down_double.png" alt="">
      </div>
    </div>
    <!-- (RD)PMB-6554 New header and navigation menu bar for Procurant Commerce -->

    <div class="connect-link">
      <a mat-list-item routerLink="/connect" target=”_blank” routerLinkActive="active" title="{{'NAVBAR.LABEL.CONNECT' | translate}}">
        <span class="sidebar-icon icon-Group"></span>
        <span class="nav-name">{{'NAVBAR.LABEL.CONNECT' | translate}}</span>
      </a>
    </div>

  </div>
  <div class="navBarHeight" sidenavitem *ngIf="auth?.user?.info?.internalAdmin">
    <div class="nav-new-bar">
      <div *ngIf="navigationScrollButton=='up' || navigationScrollButton=='both'" class="up-btn"
        (click)="handelScrollClick('up')">
        <img src="../../../../../assets/images/up_double.png" alt="">
      </div>
      <div class="nav-menu-list" id="sidebarInternalAdmin" (scroll)="scrollFunction()">
        <a mat-list-item routerLink="/dashboard" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.DASHBOARD' | translate}}">
          <!-- <span class="sidebar-icon icon-dashboard"></span> -->
          <div class="sprite-container dashboard">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.DASHBOARD' | translate }}</span>
        </a>
        <a mat-list-item routerLink="/task-center/task-center-list" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.TASK_CENTER' | translate}}">
          <!-- <span class="sidebar-icon icon-task_centre"></span> -->
          <div class="sprite-container taskcenter">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.TASK_CENTER' | translate }}</span>
        </a>
        <a mat-list-item *ngIf="permissions?.BUYER?.MODULES?.PRICE_ALERTS?.actions?.view" routerLink="/price-watch"
          routerLinkActive="active" title="{{'NAVBAR.LABEL.PRICE_ALERTS' | translate}}">
          <!-- <span class="icon-task_centre sidebar-icon"></span> -->
          <div class="sprite-container price-alerts">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.PRICE_ALERTS' | translate }}</span>
        </a>
        <a mat-list-item *ngIf="permissions?.BUYER?.MODULES?.PRICING?.actions?.view" routerLink="/product-pricing"
          routerLinkActive="active" title="{{'NAVBAR.LABEL.PRICING' | translate}}">
          <!-- <span class="sidebar-icon icon-pricing"></span> -->
          <div class="sprite-container pricing">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.PRICING' | translate }}</span>
        </a>
        <a mat-list-item *ngIf="permissions?.BUYER?.MODULES?.ORDER_MANAGEMENT?.actions?.view"
          routerLink="/order/order-list" routerLinkActive="active" title="{{'NAVBAR.LABEL.ORDERS' | translate}}">
          <!-- <span class="sidebar-icon icon-orders_list"></span> -->
          <div class="sprite-container order">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.ORDERS' | translate }}</span>
        </a>
        <a mat-list-item *ngIf="permissions?.BUYER?.MODULES?.INVOICES?.actions?.view" routerLink="/order-invoice"
          routerLinkActive="active" title="{{'NAVBAR.LABEL.INVOICE' | translate}}">
          <!-- <span class="sidebar-icon icon2-invoice"></span> -->
          <div class="sprite-container invoice">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.INVOICE' | translate }}</span>
        </a>
        <a mat-list-item *ngIf="permissions?.BUYER?.MODULES?.LOGISTICS?.actions?.view" routerLink="/logistics/load-list"
          routerLinkActive="active" title="{{'NAVBAR.LABEL.LOGISTICS' | translate}}">
          <!-- <span class="sidebar-icon icon-logistics"></span> -->
          <div class="sprite-container logistics">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.LOGISTICS' | translate }}</span>
        </a>
        <!--    <a mat-list-item *ngIf="permissions?.BUYER?.MODULES?.LOGISTICS?.actions?.view" routerLink="/freight-invoice/freight-invoice-list" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.FREIGHT_INVOICE' | translate}}">
          <span class="sidebar-icon icon2-invoice"></span>
          {{ 'NAVBAR.LABEL.FREIGHT_INVOICE' | translate }}
        </a> -->
        <a mat-list-item routerLink="/reports-analytics" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.REPORTS' | translate}}">
          <!-- <span class="sidebar-icon icon-analytics"></span> -->
          <div class="sprite-container report">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.REPORTS' | translate }}</span>
        </a>
        <a mat-list-item routerLink="/my-preferences" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.MY_PREFERENCES' | translate}}">
          <!-- <span class="sidebar-icon icon2-settings"></span> -->
          <div class="sprite-container preferences">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.MY_PREFERENCES' | translate }}</span>
        </a>
      </div>
      <div *ngIf="navigationScrollButton=='down' || navigationScrollButton=='both'" class="down-btn"
        (click)="handelScrollClick('down')">
        <img src="../../../../../assets/images/down_double.png" alt="">
      </div>
    </div>

  </div>
</side-nav>