<div>
    <ng-template #simpleText>
        {{text}}
    </ng-template>
    <div *ngIf="text!=''">
        <div class="tooltip-conatiner {{placement}}">
            <ng-container *ngTemplateOutlet="simpleText">
            </ng-container>
        </div>
    </div>
    <div *ngIf="text=='' && contentTemplate!=null">
        <div class="tooltip-conatiner {{placement}}">
            <ng-container *ngTemplateOutlet="contentTemplate;context:{contentValue: dataContent}"> 
            </ng-container>
        </div>
    </div>
<!-- <div>
    <div class="" [ngClass]="(text==''?'':'tooltip-conatiner below')">
        <ng-template #simpleText>
            {{text}}
        </ng-template>
        <ng-container *ngTemplateOutlet="contentTemplate || simpleText">
        </ng-container>
    </div>
</div> -->