<!-- input search field section -->
<div class="global-main-section" kendoPopoverAnchor [popover]="myPopover" showOn="none" #anchor="kendoPopoverAnchor">
    <div class="global-search-input-section"
        [ngClass]="{'global-main-section-border': globalSearchFormControl?.value?.length > 3}">
        <div class="label-section">{{ 'LABEL.QUICK_ORDER_SEARCH' | translate }}:</div>
        <div class="input-section">
            <mat-form-field appearance="outline">
                <mat-icon id="btnSearch" matPrefix>search</mat-icon>
                <input type="text" matInput [formControl]="globalSearchFormControl"
                    placeholder="{{ 'LABEL.SEARCH_FOR_ORDERS' | translate }}">
                <div *ngIf="globalSearchFormControl?.value?.trim()?.length < 4" class="hint-text">{{
                    'LABEL.ENTER_MIN_FOUR_CHARACTERS' | translate }}</div>
                <mat-spinner *ngIf=" globalSearchFormControl?.value?.trim()?.length > 3 && isLoader " diameter="20"
                    strokeWidth="4"></mat-spinner>
                <!-- <img *ngIf=" globalSearchFormControl?.value?.length > 3 && isLoader " class="loader"
                    src="../../../../../assets/images/main-loader-option-1.gif" /> -->
                <button matSuffix mat-icon-button aria-label="Clear" (click)="clearInputValue()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

        </div>

        <!-- <div class="input-warning-section">Please enter at least 3 characters.</div> -->
    </div>

    <div class="beta-section">{{ 'LABEL.BETA' | translate }} </div>
</div>
<!-- overlay part -->
<kendo-popover #myPopover position="bottom" [callout]="false" [height]="calculatedHeight" [width]="calculatedWidth" (shown)="onShown()" (hidden)="onHidden()">
    <ng-template kendoPopoverBodyTemplate>
        <!-- main container -->
        <div class="global-search-container" *ngIf="responseData?.content; else serchingTemplate ">
            <!-- header section  -->
            <div class="header-container">
                <span (click)="onClosePopUp();">
                    <mat-icon class="close-icon">close</mat-icon>
                </span>
            </div>

            <!-- data section -->
            <div class="body-container">
                <!-- list section -->
                <div class="order-card-section">

                    <!-- result found message  -->
                    <div class="message-section">
                        <div class="msg"> {{ 'LABEL.SHOWING' | translate }} <span
                                class="highlighter">{{responseData?.totalRecords}}</span>
                           
                            {{ 'LABEL.RECORDS_FOR' | translate }}
                            <span class="highlighter">{{globalSearchFormControl.value}}</span> 
                            {{ 'LABEL.FOR_THE_APPLIED_SEARCH_FILTER' | translate }}
                        </div>
                        <!-- Did you find what you are looking for? -->
                        <!-- <div class="button-section">
                            <span>Yes</span>
                            <span>No</span>
                        </div> -->
                    </div>

                    <!-- order list section -->
                    <div class="list-section" #listSectionDiv [style.height.px]="calculatedHeight-110"
                        (scrolled)="loadMoreOrders()" [scrollWindow]="false" infiniteScroll
                        [infiniteScrollDistance]="0.5" [infiniteScrollThrottle]="5"
                        *ngIf="orderList.length > 0; else NoResultFound">
                        <!-- <ng-container *ngFor="let key of orderKeys"> -->
                        <order-card [crossDockFacility]="crossDockFacility" *ngFor="let order of orderList"
                            [order]="order" [searchText]="globalSearchFormControl?.value"
                            (openNewTabEvent)="openNewTab($event)"></order-card>
                        <!-- </ng-container> -->
                    </div>
                </div>

                <!-- filter section -->
                <div class="filter-card-section">
                    <span class="filter-lable">  {{ 'LABEL.FILTERS' | translate }}</span>
                    <!-- <global-search-filters [facets]="responseData?.facets"></global-search-filters> -->

                    <div class="global-search-filter-section" [style.height.px]="calculatedHeight-150">

                        <!-- <filter-card *ngFor="let key of filterKeys" 
                            [label]="getLabel(key)" [filterData]="getFacetData(key)"
                                (selectedValues)="onSelectedValues($event)"></filter-card> -->

                        <filter-card [label]="'Order Status'" [filterData]="statusList"
                            [selectedList]="statusSelectedList"
                            (selectedValues)="onSelectedValues($event)"></filter-card>

                        <filter-card [label]="'Suppliers'" [filterData]="supplierList"
                            [selectedList]="supplierSelectedList"
                            (selectedValues)="onSelectedValues($event)"></filter-card>

                        <filter-card [label]="'Freight Term'" [filterData]="freightTermList"
                            [selectedList]="freightTermSelectedList"
                            (selectedValues)="onSelectedValues($event)"></filter-card>

                        <filter-card [label]="'Buyer User'" [filterData]="buyerUSerList"
                            [selectedList]="buyerUSerSelectedList"
                            (selectedValues)="onSelectedValues($event)"></filter-card>

                    </div>
                </div>
            </div>
        </div>

        <!-- added additional template which is not mentioned in ticket -->
        <ng-template #serchingTemplate>
            <div class="serching-container-empty">
                <div class="head-section">
                    <div>
                        <span (click)="onClosePopUp();">
                            <mat-icon class="close-icon">close</mat-icon>
                        </span>
                    </div>
                </div>
                <div [style.height.px]="calculatedHeight-70" class="no-result-found-section">
                    <span class="trace-no-results"></span>
                    <span class="big-msg">  {{ 'LABEL.NO_RESULT_FOUND' | translate }}</span>
                    <span class="small-msg">  {{ 'LABEL.WE_COULDNT_FIND_ANY_ITEM_MATCHING_YOUR_SEARCH' | translate }}</span>
                    <span class="go-back-msg" (click)="onClosePopUp()">Close</span>
                </div>
            </div>
        </ng-template>

        <ng-template #NoResultFound>
            <div class="no-data-section">
                <div class="no-data-container">
                    <span class="trace-no-results"></span>
                    <span class="big-msg">{{ 'LABEL.NO_RESULT_FOUND' | translate }}</span>
                    <span class="small-msg">{{ 'LABEL.WE_COULDNT_FIND_ANY_ITEM_MATCHING_YOUR_SEARCH' | translate }}</span>
                </div>
            </div>
        </ng-template>
    </ng-template>
</kendo-popover>
<!-- </div> -->